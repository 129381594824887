import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import englishResourceBundle from '~/i18n/en/resource.json'
import franceResourceBundle from '~/i18n/fr/resource.json'
import germanResourceBundle from '~/i18n/de/resource.json'
import netherlandResourceBundle from '~/i18n/nl/resource.json'
import franceBelgiumResourceBundle from '~/i18n/fr_BE/resource.json'
import {initReactI18next} from 'react-i18next'

export const translationsJson = {
  en: {
    translation: englishResourceBundle
  },
  fr: {
    translation: franceResourceBundle
  },
  de: {
    translation: germanResourceBundle
  },
  nl: {
    translation: netherlandResourceBundle
  },
  fr_BE: {
    translation: franceBelgiumResourceBundle
  }
}

// function missingCron(counter) {
//   setTimeout(() => {
//     missingCron(++counter);
//     console.log('I shall make a request with:', missingTranslations);
//   }, counter*1000);
// }
// missingCron(1);

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
export default i18n
