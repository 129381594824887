import React, { useEffect, useState } from 'react'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useHistory } from 'react-router-dom'
import notify from '~/components/notify'
import { useForm } from 'react-hook-form'
import { MilkMoneyApi } from '~/services'
import { Form } from 'semantic-ui-react'
import { checkboxFields, inputFields } from './constants'
import { FormFields } from '~/components/FormFields'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { RouteTypes } from '~/Constants'

const EditMgaFactor: React.FC = () => {
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const { id, idMga } = useParams()
  const history = useHistory()

  const { handleSubmit, control, reset, formState: { dirtyFields }, watch, getValues } = useForm({
    mode: 'onSubmit'
  })

  const watchMinAmount = watch('minAmount')
  const watchMinPrice = watch('minPrice')

  useEffect(() => {
    fetchMgaData()
  }, [])

  async function fetchMgaData() {
    setIsBusy(true)
    try {
      const response = await MilkMoneyApi.MgaFactorEdit(id, idMga)
      reset({ ...response.data })
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function getMinValue(key) {
    switch (key) {
      case 'minPrice':
        return watchMinPrice
      case 'minAmount':
        return watchMinAmount
      default:
        return 0
    }
  }

  function renderForm() {
    return <>
      <Form.Group widths={'equal'}>
        {checkboxFields.map(checkboxField => {
          return <FormFields.Checkbox
            key={checkboxField.key}
            control={control}
            //@ts-ignore
            name={checkboxField.key}
            label={checkboxField.label}
            checkboxValue={1}
            defaultValue={0}
          />
        })}
      </Form.Group>
      {inputFields.map((inputRow, index) => {
        return <Form.Group key={index} widths={'equal'} style={{ marginTop: '2em' }}>
          {inputRow.map(inputField => {
            //@ts-ignore
            const required = !dirtyFields[inputField.key] && getValues(inputField.key)
            return <FormFields.Input
              control={control}
              //@ts-ignore
              name={inputField.key}
              typeInput={'controlled'}
              label={inputField.label}
              allowDecimals={inputField.allowDecimals}
              decimalScale={inputField.allowDecimals ? 2 : 0}
              placeholder={inputField.label}
              rules={{
                required: required ? inputField?.requiredMessage : null,
                min: typeof (inputField.minValue) === 'number' ? inputField.minValue : getMinValue(inputField.minValue)
              }}
            />
          })}
        </Form.Group>
      })}
    </>
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      inputFields.map(inputRow => {
        inputRow.map(inputField => {
          if (data && data[inputField?.key]) {
            data[inputField.key] = data[inputField.key]?.toString()?.replace(',', '.')
          }
        })
      })
      await MilkMoneyApi.UpdateMgaFactor(id, idMga, data)
      notify(t('Succesfully updated mga factor'), 'success')
      history.push(RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTORS.replace(':id', id))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderActions() {
    return <>
      <PageHeaderActionButton
        as={Link}
        style={{ lineHeight: '1em' }}
        to={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTORS.replace(':id', id)}
        type='button'
      >{t('Cancel')}</PageHeaderActionButton>
      <PageHeaderActionButton>{t('Update')}</PageHeaderActionButton>
    </>
  }

  return <DairyViewTabsLayout
    segmented
    header={t('Edit mga factor')}
    isBusy={isBusy}
    onSubmit={handleSubmit(onSubmit)}
    actions={renderActions()}
  >
    {renderForm()}
  </DairyViewTabsLayout>
}
export default EditMgaFactor