import React, { useEffect, useState } from 'react'
import { Controller, useController, UseControllerProps } from 'react-hook-form'
import { Dimmer, Form, Icon, InputProps, Label, Loader } from 'semantic-ui-react'
import { getLabel, getError } from './util'
import { useDebounce } from 'use-lodash-debounce';
import styled, { CSSProperties } from 'styled-components';
import { emailRegex } from '~/Constants';
import { useParams } from 'react-router-dom'
import notify from '../notify';
import { useTranslation } from 'react-i18next';
import { CheckUserEmail } from '~/services/dairies';
import CurrencyInput from 'react-currency-input-field';

const EmailInputWrapper = styled.div`
display: flex; 
align-items: center;
justify-content: center;
width: 100%;
 .loaderWrapper {
  position: relative; 
  margin: auto;
  margin-left: 10px; 
  margin-top: 23px; 
  margin-right: 20px;
 }
 .field {
  width: inherit !important;
 }
 .icon {
  margin-top: 5px !important;
 }
 .loader {
  top: 70% !important;
 }
`
interface InputFormFiled extends UseControllerProps {
  label?: string | JSX.Element,
  value?: string,
  control: any,
  parent?: string,
  hideError?: boolean
  handleEmailCheck?: (correctEmail: boolean) => void
  typeInput?: string
  allowDecimals?: boolean
  decimalScale?: number
  withoutAsterisk?: boolean
  numberOfDecimals?: number
  disableScroolIntoView?: boolean
  styleLabel?: CSSProperties
  max?: number
  suffix?: string
  formatNumberForDb?: boolean
}

type InputFormFiledProps = InputFormFiled & InputProps

const Input: React.FC<InputFormFiledProps> = props => {
  const { t } = useTranslation()
  const { id, idUser } = useParams()
  const { field, fieldState } = useController(props)
  const [correctEmail, setCorrectEmail] = useState(false)
  const debouncedEmailValue = useDebounce(field.value, 300);
  const [loadingEmailCheck, setLoadingEmailCheck] = useState(false)
  const fieldTemp = { ...field, ref: null }
  const {
    control,
    label,
    rules,
    defaultValue,
    hideError,
    parent,
    handleEmailCheck,
    ...inputProps
  } = props

  function notInViewport(element, offset = 0) {
    if (!element) return false
    const top = element.getBoundingClientRect().top
    return (top + offset) >= 0 && (top - offset) >= window.innerHeight
  }

  useEffect(() => {
    if (fieldState.error) {
      const element = document.getElementById(fieldTemp.name)
      if (notInViewport(element)) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [fieldState])

  useEffect(() => {
    if (debouncedEmailValue && props.typeInput === 'emailField' && fieldState.isDirty) {
      checkEmail(debouncedEmailValue)
    }
  }, [debouncedEmailValue])

  async function checkEmail(value) {
    setLoadingEmailCheck(true)
    try {
      const response = await CheckUserEmail(id, { email: value, id: idUser })
      if (response.data === 1) {
        handleEmailCheck(emailRegex.test(value))
        setCorrectEmail(emailRegex.test(value))
      } else {
        handleEmailCheck(false)
        setCorrectEmail(false)
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error');
      } else {
        notify(t('General error'), 'error');
      }
    }
    setLoadingEmailCheck(false)
  }

  function renderEmailInput() {
    const inputValue = props.value ? props.value : fieldTemp.value || ''
    return <EmailInputWrapper>
      <Form.Input
        id={`${fieldTemp.name}`}
        {...inputProps}
        {...fieldTemp}
        onChange={(e, data) => {
          if (props.onChange) {
            props.onChange(e, data)
          }
          fieldTemp.onChange(data.value)
        }}
        onWheel={(e) => e.target.blur()}
        value={props.value ? props.value : fieldTemp.value || ''}
        error={!hideError && fieldState.error ? getError(fieldState, props.noErrorMessage) : Boolean(props.error)}
        label={props.label ? getLabel(label, rules) : null}
      />
      <div className='loaderWrapper'>
        {loadingEmailCheck && <Dimmer inverted active={loadingEmailCheck}>
          <Loader size='small' active={loadingEmailCheck} />
        </Dimmer>}
      </div>
      {!loadingEmailCheck && inputValue !== '' && fieldState.isDirty && <Icon
        style={{ marginLeft: 10 }}
        name={correctEmail ? 'checkmark' : 'close'}
        color={correctEmail ? 'green' : 'red'}
      />}
    </EmailInputWrapper>
  }

  function renderControledInput() {
    return <Form.Field
      error={fieldState.error}
      required={!props.withoutAsterisk ? props.rules?.required : false}
      {...inputProps}
    >
      <label>{props.label}</label>
      <Controller
        rules={props.rules}
        control={control}
        name={props.name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <CurrencyInput
            intlConfig={{ locale: 'de-DE' }}
            suffix={props.suffix}
            allowDecimals={Boolean(props.allowDecimals)}
            decimalScale={props.allowDecimals ? props.decimalScale || 2 : 0}
            disabled={props.disabled}
            decimalsLimit={props.allowDecimals ? props.decimalScale || 2 : 0}
            id={field.name}
            onValueChange={(value) => {
              if (!value) {
                // setClassName('error');
                onChange('');
                return;
              }
              if (props.formatNumberForDb) {
                onChange(value.replace(',', '.'))
                return
              }
              onChange(value)
            }}
            max={props.max}
            placeholder={props.placeholder || t('Please enter a number')}
            step={1}
            value={value}
          />
        )}
      />
      {fieldState?.error && !props.noErrorMessage && <Label prompt pointing={'above'}>{fieldState.error?.message}</Label>}
    </Form.Field>
  }

  function renderDefaultInput() {
    return <Form.Input
      id={`${fieldTemp.name}${parent}`}
      {...inputProps}
      {...fieldTemp}
      value={fieldTemp.value || ''}
      error={!hideError && fieldState.error ? getError(fieldState) : false}
      label={props.label ? getLabel(label, rules) : null}
      autoComplete={'nofill'}
    />
  }

  switch (props.typeInput) {
    case 'emailField':
      return renderEmailInput()
    case 'controlled':
      return renderControledInput()
    default:
      return renderDefaultInput()
  }

}
export default Input
