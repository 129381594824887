import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, List, Message } from 'semantic-ui-react'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import notify from '~/components/notify'
import { StyledButton } from '../../../style'
import { useParams } from 'react-router-dom'
import { DairyApi } from '~/services'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { useFieldArray, useForm } from 'react-hook-form'
import { ListItemWrapper } from './style'
import { FormFields } from '~/components/FormFields'
import SettingsUsers from './SettingsUsers'

const IfeSettings: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false)
  const { id } = useParams()
  const { t } = useTranslation()

  const { handleSubmit, reset, control } = useForm({
    mode: 'onSubmit'
  })

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'reminders',
    keyName: 'key'
  })

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await DairyApi.GetIfeSettings(id)
      reset({
        users: response.data.ifeNotificationUsers,
        reminders: response.data.ifeSettings[0]?.reminders?.map(reminder => ({ reminder }))
      })
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      data.reminders = data.reminders?.map(reminderItem => reminderItem.reminder)
      await DairyApi.UpdateIfeSettings(id, data)
      notify(t('Successfully updated ife settings'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderReminders() {
    return fields.map((field, index) => {
      return <List.Item key={field.key}>
        <ListItemWrapper>
          <FormFields.Input
            control={control}
            autofill='off'
            rules={{
              required: {
                value: true,
                message: t('Please enter reminder')
              },
              min: {
                value: 1,
                message: t('Please enter value bigger then 0')
              }
            }}
            type='number'
            //@ts-ignore
            name={`reminders[${index}].reminder`}
            label={t('Reminder')}
            placeholder={t('Reminder')}
          />
          <Icon
            onClick={() => remove(index)}
            inverted
            color='red'
            circular
            name='trash'
          />
        </ListItemWrapper>
      </List.Item>
    })
  }

  function renderActions() {
    return <PageHeaderActionButton content={t('Save')} />
  }

  function renderMessage() {
    return <Message style={{ width: 'fit-content' }}>
      <Message.Content>
        {t('Users who will receive e-mails after ife updated the fix prices of trading slots')}
      </Message.Content>
    </Message>
  }

  return <DairyViewTabsLayout
    isBusy={isBusy}
    header={t('Ife settings')}
    segmented
    actions={renderActions()}
    onSubmit={handleSubmit(onSubmit)}
  >
    <div>
      {renderMessage()}
      <SettingsUsers control={control} />
      <StyledButton
        type='button'
        onClick={() => append({ reminder: '' })}
      >
        {t('Add new reminder')}
      </StyledButton>
      <List>
        {renderReminders()}
      </List>
    </div>
  </DairyViewTabsLayout>
}
export default IfeSettings
