import { RouteTypes } from '~/Constants'
import i18n from '../i18n'

export default [
  {
    id: 'dashboard',
    title: i18n.t('Dashboard'),
    link: RouteTypes.HOME,
    icon: 'area chart'
  },
  {
    id: 'dairy',
    title: i18n.t('Dairy Factories'),
    link: RouteTypes.DAIRY_FACTORY,
    icon: 'factory'
  },
  {
    id: 'users',
    title: i18n.t('Users'),
    link: RouteTypes.USERS,
    icon: 'users'
  },
  {
    id: 'sftp-accounts',
    title: i18n.t('SFTP accounts'),
    link: RouteTypes.SFTP_ACCOUNTS,
    icon: 'user secret'
  },
  {
    id: 'update-logs',
    title: i18n.t('Update Logs'),
    link: RouteTypes.UPDATE_LOGS,
    icon: 'address book outline'
  }
]
