import { Form, MenuItem, Tab } from "semantic-ui-react"
import styled from "styled-components"

const StyledTabs = styled(Tab)`
 .menu {
  flex-wrap: wrap !important;
 }
`
const DrawerFooter = styled(Form.Field)`
  margin-top: 3rem !important;
`;

export const StyledMenuItem = styled(MenuItem)`
 .error {
  color: red;
 }
`

export const StateEl = {
  DrawerFooter,
  StyledTabs
};