import React, { useEffect, useState } from 'react'
import i18n from '~/components/i18n'
import { useTranslation } from 'react-i18next'
import { Input } from 'semantic-ui-react'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useParams } from 'react-router-dom'
import notify from '~/components/notify'
import { DairyApi } from '~/services'
import { Link } from 'react-router-dom'
import { RouteTypes } from '~/Constants'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-lodash-debounce'
import { PageHeaderActionButton } from '~/components/Layout/style'
import TableComponent from '~/components/TableComponent'
import Dialog from '~/components/Dialog'

const tableColumns = [
  {
    name: i18n.t('Name'),
    key: 'name'
  },
  {
    name: i18n.t('Headquarter'),
    key: 'headquarter',
    type: 'verified'
  },
  {
    name: i18n.t('Type'),
    key: 'typeLabel'
  },
  {
    name: i18n.t('Address'),
    key: 'streetName'
  },
  {
    name: i18n.t('Action')
  }
]

const Location: React.FC = () => {
  const [officesRows, setOfficesRows] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const { id } = useParams()
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [deleteLocation, setDeleteLocation] = useState(null)
  const [dataFiltered, setDataFiltered] = useState({
    page: 0,
    rows: 20,
    search: ''
  })
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 300)
  const actions = [
    {
      popupMessage: i18n.t('Edit'),
      type: 'link',
      name: 'pencil',
      size: 'large',
      link: RouteTypes.DAIRY_FACTORY_VIEW_LOCATION_EDIT.replace(':idDairy', id)
    },
    {
      popupMessage: i18n.t('Delete'),
      type: 'icon',
      color: 'red',
      name: 'trash',
      size: 'large',
      actionType: 'delete'
    }
  ]

  useEffect(() => {
    fetchOfficeData()
  }, [])

  useEffect(() => {
    if (dataFiltered && total !== 0) {
      fetchFilteredData()
    }
  }, [dataFiltered])

  useEffect(() => {
    setDataFiltered({ ...dataFiltered, search: debouncedValue })
  }, [debouncedValue])

  async function fetchFilteredData() {
    setIsBusy(true)
    try {
      const response = await DairyApi.Offices(id, { ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })
      setOfficesRows(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function fetchOfficeData() {
    setIsBusy(true)
    try {
      const responseViewOffice = await DairyApi.Offices(id)
      setOfficesRows(responseViewOffice.data.rows)
      setTotal(responseViewOffice.data.total)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function handleDeleteOffice(idOffice: string) {
    setIsBusy(true)
    try {
      await DairyApi.OfficesDelete(id, idOffice)
      setOfficesRows(officesRows.filter(office => office.id !== idOffice))
      setTotal(total - 1)
      setDeleteLocation(null)
      notify(t('Successfully deleted location'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderDeleteModal() {
    const name = deleteLocation?.name || ''
    return <Dialog
      open={Boolean(deleteLocation)}
      textHeader={t('Delete location: {{name}}', { name })}
      handleClose={() => setDeleteLocation(null)}
      handleSubmit={() => handleDeleteOffice(deleteLocation?.id)}
      textContent={<>{t('Do you want to delete location {{name}}?', { name })}</>}
      textSubmit={t('Delete')}
      textClose={t('Close')}
      type={'abort'}
    />
  }

  function handleClickAction(row, type) {
    switch (type) {
      case 'delete':
        return setDeleteLocation(row)
      default:
        return <></>
    }
  }

  function renderContent() {
    return <TableComponent
      tableColumnHeaders={tableColumns}
      dataFiltered={dataFiltered}
      actions={actions}
      rows={officesRows}
      total={total}
      handleClickAction={(row, type) => handleClickAction(row, type)}
    />
  }

  function renderActions() {
    return <PageHeaderActionButton
      as={Link}
      to={RouteTypes.DAIRY_FACTORY_VIEW_LOCATION_CREATE.replace(':idDairy', id)}
    >{t('Create')}</PageHeaderActionButton>
  }

  function renderSearch() {
    return <Input
      icon={{ name: 'search', circular: true, link: true }}
      placeholder={t('Search...')}
      value={search}
      onChange={(e, data) => setSearch(data.value)}
    />
  }

  return <DairyViewTabsLayout
    header={t('Location')}
    isBusy={isBusy}
    segmented
    search={renderSearch()}
    actions={renderActions()}
  >
    {deleteLocation && renderDeleteModal()}
    {renderContent()}
  </DairyViewTabsLayout>
}
export default Location
