import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { Form, Icon } from 'semantic-ui-react';
import FileUpload from '~/components/FileUpload';
import { StyledImageContainer, StyledImageWrapper } from '~/pages/Profile/style';

const defaultImage = '/assets/default.png'

const Image: React.FC = () => {

  const { control, setValue } = useFormContext()
  const photoFieldUrl = useWatch({ control, name: 'logo' })

  return <Form.Field width={3}>
    {photoFieldUrl && <StyledImageWrapper>
      <img src={photoFieldUrl ? photoFieldUrl : defaultImage}></img>
      <StyledImageContainer />
      {photoFieldUrl && <Icon
        circular
        onClick={(e) => { e.preventDefault(); setValue('logo', null) }}
        name='trash'
        className='trashCan'
      />
      }
    </StyledImageWrapper>
    }
    {!photoFieldUrl && <FileUpload
      encodeFiles
      onChange={(value) => setValue('logo', value)}
      mimeTypes={['bmp', 'jpg', 'jpeg', 'png']}
      sizeLimitInMB={5}
    />
    }
  </Form.Field>
}
export default Image