import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import Dialog from '~/components/Dialog';
import { DairiesUpdateVersionType, DairyListType } from '~/services/dairies';
import { DairyApi } from '~/services';
import notify from '~/components/notify';
import { useForm } from 'react-hook-form';
import { FormFields } from '~/components/FormFields';

type UpdateVersionDialogProps = {
  updateModal: boolean,
  handleClose: () => void,
  handleSubmit: (data: DairiesUpdateVersionType) => void
}

const UpdateVersionDialog: React.FC<UpdateVersionDialogProps> = (props) => {

  const { t } = useTranslation()
  const [loadingDairiesList, setLoadingDairiesList] = useState(false)
  const [dairiesList, setDairiesList] = useState<DairyListType[]>([])
  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'onSubmit'
  })

  const selectedDairies = watch('dairies');

  useEffect(() => {
    fetchDairiesList()
  }, [])

  async function fetchDairiesList() {
    setLoadingDairiesList(true)
    try {
      const dairiesListResponse = await DairyApi.getDairiesList()
      setDairiesList(dairiesListResponse.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setLoadingDairiesList(false)
  }

  function selectAllDairies() {
    const dairyIds = dairiesList.map(dairy => dairy.id)

    setValue('dairies', dairyIds)
  }

  function unselectAllDairies() {
    setValue('dairies', [])
  }

  return <Dialog
    isDialogOpen={props.updateModal}
    textClose={t('Close')}
    handleClose={() => { props.handleClose(); setValue('dairies', []); setValue('isNew', false) }}
    textSubmit={t('Update')}
    handleSubmit={handleSubmit(props.handleSubmit)}
    noScrollingContent={true}
    disableSubmit={(selectedDairies?.length || 0) === 0}
    textContent={<>
      <div style={{ marginBottom: 15 }}>
        <Button
          onClick={selectAllDairies}
          disabled={(selectedDairies?.length || 0) === dairiesList.length}
          primary>
          {t('Select all')}
        </Button>
        <Button
          onClick={unselectAllDairies}
          disabled={(selectedDairies?.length || 0) === 0}
          primary>
          {t('Unselect all')}
        </Button>
      </div>
      <Form>
        <Form.Group>
          <FormFields.Select
            control={control}
            label={t('Dairies')}
            search
            multiple
            placeholder={t('Select dairies')}
            name='dairies'
            options={dairiesList.map(dairy => ({ value: dairy.id, text: `${dairy.companyName} (${dairy.subdomain})` }))}
            width={10}
            defaultValue={[]}
            rules={{
              required: {
                value: true,
                message: t('Please select at least one dairy')
              }
            }}
          />
        </Form.Group>
        <Form.Group>
          <FormFields.Checkbox label={t('Create new update log')}
            width={10}
            control={control}
            name='isNew'
            defaultValue={0}
            checkboxValue={1}
          />
        </Form.Group>
      </Form>
    </>
    }
    textHeader={t('Update version')}
    type={'dialog'}
    isLoading={loadingDairiesList}
  />
}

export default UpdateVersionDialog