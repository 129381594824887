import i18n from "./components/i18n"

export const RouteTypes = {
  LOGIN: '/login',
  HOME: '/dashboard',
  DAIRY_FACTORY: '/dairy-factories',
  DAIRY_FACTORY_CREATE: '/dairy-factories/create',
  DAIRY_FACTORY_VIEW: '/dairy-factories/view/:id',
  DAIRY_FACTORY_VIEW_LOCATION: '/dairy-factories/view/:id/location',
  DAIRY_FACTORY_VIEW_LOCATION_CREATE: '/dairy-factories/view/:idDairy/location/create',
  DAIRY_FACTORY_VIEW_LOCATION_EDIT: '/dairy-factories/view/:idDairy/location/edit/:id',
  DAIRY_FACTORY_VIEW_USERS: '/dairy-factories/view/:id/dairy-users',
  DAIRY_FACTORY_VIEW_USERS_CREATE: '/dairy-factories/view/:id/dairy-users/create',
  DAIRY_FACTORY_VIEW_USERS_EDIT: '/dairy-factories/view/:id/dairy-users/edit/:idUser',
  DAIRY_FACTORY_VIEW_FARMERS_GROUP: '/dairy-factories/view/:id/farmer-groups',
  DAIRY_FACTORY_VIEW_FARMERS_GROUP_CREATE: '/dairy-factories/view/:idDairy/farmer-groups/create',
  DAIRY_FACTORY_VIEW_FARMERS_GROUP_SETTINGS: '/dairy-factories/view/:idDairy/farmer-groups/settings/:id',
  DAIRY_FACTORY_VIEW_FARMERS_GROUP_EDIT: '/dairy-factories/view/:idDairy/farmer-groups/edit/:id',
  DAIRY_FACTORY_VIEW_FARMERS: '/dairy-factories/view/:id/farmers',
  DAIRY_FACTORY_VIEW_FORMS_OF_HUSBANDRY: '/dairy-factories/view/:id/forms-of-husbandry',
  DAIRY_FACTORY_VIEW_MILK_MONEY_TYPES: '/dairy-factories/view/:id/milk-types',
  DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTORS: '/dairy-factories/view/:id/mga-factors',
  DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS: '/dairy-factories/view/:id/milk-quality-classes',
  DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTOR_EDIT: '/dairy-factories/view/:id/mga-factors/:idMga/edit',
  DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS_EDIT: '/dairy-factories/view/:id/milk-quality-classes/:idMilkQ/edit',
  DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTOR_CREATE: '/dairy-factories/view/:id/mga-factors/create',
  DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_CLASSES: '/dairy-factories/view/:id/milk-classes',
  DAIRY_FACTORY_VIEW_MILK_MONEY_FTPS: '/dairy-factories/view/:id/ftps',
  DAIRY_FACTORY_VIEW_STONEX: '/dairy-factories/view/:id/stonex-accounts',
  DAIRY_FACTORY_VIEW_STONEX_CREATE: '/dairy-factories/view/:id/stonex-accounts/create',
  DAIRY_FACTORY_VIEW_STONEX_EDIT: '/dairy-factories/view/:id/stonex-accounts/:idAccount/edit',
  DAIRY_FACTORY_VIEW_STONEX_SHOW: '/dairy-factories/view/:id/stonex-accounts/:idAccount/show',
  DAIRY_FACTORY_VIEW_FARMERS_CREATE: '/dairy-factories/view/:id/farmers/create',
  DAIRY_FACTORY_VIEW_FARMERS_EDIT: '/dairy-factories/view/:id/farmers/edit/:idFarmer',
  DAIRY_FACTORY_VIEW_IFE_SETTINGS: '/dairy-factories/view/:id/ife-settings',
  DAIRY_FACTORY_VIEW_IFE_USERS: '/dairy-factories/view/:id/ife-users',
  DAIRY_FACTORY_EDIT: '/dairy-factories/edit/:id',
  USERS: '/users',
  SFTP_ACCOUNTS: '/sftp-accounts',
  SFTP_ACCOUNTS_CREATE: '/sftp-accounts/create',
  SFTP_ACCOUNTS_EDIT: '/sftp-accounts/edit/:id',
  USERS_CREATE: '/users/create',
  USERS_EDIT: '/users/edit/:id',
  UPDATE_LOGS: '/update-logs',
  UPDATE_LOGS_CREATE: '/update-logs/create',
  UPDATE_LOGS_EDIT: '/update-logs/edit/:id',
  INTERFACE_LOGS: '/interface-logs',
  IFE_SETTINGS: '/ife-settings',
  IFE_USERS: '/ife-users',
  PROFILE: '/profile',
  EMAILS: '/dairy-factories/view/:id/emails',
}

export const ADMIN_TOKEN = 'admin-token'

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i

export const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/i

export const dateRegex = /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/i

export const ActionTypes = Object.freeze({
  USER_DATA_RECEIVED: 'USER_DATA_RECEIVED',
  USER_DATA_REMOVED: 'USER_DATA_REMOVED',
  SYSTEM_DATA_RECEIVED: 'SYSTEM_DATA_RECEIVED'
})

export const FarmerAmountTypeOptions = [
  {
    value: 'month',
    text: 'Month'
  },
  {
    value: 'year',
    text: 'Year'
  }
]

export const FarmerAmountUnitOptions = [
  {
    value: 'kg',
    text: 'Kilograms'
  },
  {
    value: 'l',
    text: 'Liters'
  }
]

export const FarmerAmountVolumeOptions = [
  {
    value: '1',
    text: '1'
  },
  {
    value: '100',
    text: '100'
  },
  {
    value: '1000',
    text: '1000'
  }
]

export const FarmerAmountPriceOptions = [
  {
    value: 'e',
    text: 'Euro'
  },
  {
    value: 'ec',
    text: 'Some other value'
  }
]

export const languages = [
  {
    id: 2,
    code: 'de',
    label: 'German'
  },
  {
    id: 1,
    code: 'gb',
    label: 'English'
  },
  {
    id: 3,
    code: 'fr',
    label: 'French'
  }
]

export const optionsLimit = [
  {
    value: 10,
    text: '10'
  },
  {
    value: 20,
    text: '20'
  },
  {
    value: 30,
    text: '30'
  },
  {
    value: 40,
    text: '40'
  },
  {
    value: 60,
    text: '60'
  },
  {
    value: 80,
    text: '80'
  }
]

export const dairyManagingStatuses = {
  creating: 0,
  ready: 1,
  editing: 2,
  deleting: 3,
  createError: 4,
  editError: 5,
  deleteError: 6
}

export const dairyUpdateStatuses = {
  updating: 0,
  ready: 1,
  error: 2
}

export const MILK_MONEY_ACC_SLUG = 'milk_money_accounting'

export const testPlatformText = 'test_platform_text';

export const disableKeyPress = (event) => {
  event.preventDefault();
}

export const IFE_ROLE_SLUG = 'ife'

export const FARMER_MANAGER_ROLE_SLUG = 'farmer_manager'

export const farmerFilters = [
  {
    placeholder: i18n.t('Farmer groups'),
    key: 'farmerGroupId',
    optionsKey: 'farmerGroups',
    type: 'select'
  },
  {
    placeholder: i18n.t('Max of trading month amount from'),
    key: 'yearlyAmountFrom',
    type: 'input'
  },
  {
    placeholder: i18n.t('Max of trading month amount to'),
    key: 'yearlyAmountTo',
    type: 'input'
  },
  {
    placeholder: i18n.t('Fixed price amount from'),
    key: 'confirmedAmountFrom',
    type: 'input'
  },
  {
    placeholder: i18n.t('Fixed price amount to'),
    key: 'confirmedAmountTo',
    type: 'input'
  },
  {
    label: i18n.t('Cancellation date set?'),
    key: 'terminationDate',
    type: 'checkbox'
  },
  {
    label: i18n.t('Last delivery date set?'),
    key: 'lastDeliveryDate',
    type: 'checkbox'
  },
  {
    label: i18n.t('Has cancelled amount?'),
    key: 'hasCancelledAmount',
    type: 'checkbox'
  }
]

export const ESCAPE_KEY_CODE_VALUE = 27
