import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RouteTypes } from '~/Constants'
import FarmerGroups from './Tabs/FarmerGroups'
import Users from './Tabs/Users'
import Location from './Tabs/Location'
import Farmers from './Tabs/Farmers'
import IfeSettings from './Tabs/Ife/IfeSettings'
import CreateEditFarmerGroup from './Tabs/FarmerGroups/CreateEditFarmerGroup'
import CreateEditUser from './Tabs/Users/CreateEditUser'
import CreateEditLocation from './Tabs/Location/CreateEditLocation'
import CreateEditFarmers from './Tabs/Farmers/CreateEditFarmers'
import Settings from './Tabs/FarmerGroups/Settings'
import MilkMoney from './Tabs/MilkMoney'
import StoneX from './Tabs/StoneX'
import CreateEditStoneX from './Tabs/StoneX/CreateEditStoneX'
import ShowStoneX from './Tabs/StoneX/ShowStoneX'
import MilkTypes from './Tabs/MilkMoney/MilkTypes'
import MgaFactors from './Tabs/MilkMoney/MgaFactors'
import EditMgaFactor from './Tabs/MilkMoney/MgaFactors/EditMgaFactor'
import MilkQualityClasess from './Tabs/MilkMoney/MilkQualityClasses'
import EditMilkQualityClass from './Tabs/MilkMoney/MilkQualityClasses/EditMilkQualClass'
import FormsOfHusbandry from './Tabs/FormsOfHusbandry'
import Emails from './Tabs/Emails'

const DairyViewRouter: React.FC = () => {
  return <Switch>
    {/* Location */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW} render={() => <Location />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_LOCATION} render={() => <Location />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_LOCATION_CREATE} render={() => <CreateEditLocation />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_LOCATION_EDIT} render={() => <CreateEditLocation />} />

    {/* Farmers */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS} render={() => <Farmers />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_CREATE} render={() => <CreateEditFarmers />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_EDIT} render={() => <CreateEditFarmers />} />

    {/* Forms of Husbandry */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FORMS_OF_HUSBANDRY} render={() => <FormsOfHusbandry />} />

    {/* Users */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_USERS} render={() => <Users />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_USERS_CREATE} render={() => <CreateEditUser />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_USERS_EDIT} render={() => <CreateEditUser />} />

    {/* Farmer Groups */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP} render={() => <FarmerGroups />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP_CREATE} render={() => <CreateEditFarmerGroup />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP_EDIT} render={() => <CreateEditFarmerGroup />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP_SETTINGS} render={() => <Settings />} />

    {/* Milk money */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_FTPS} render={() => <MilkMoney />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_TYPES} render={() => <MilkTypes />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS} render={() => <MilkQualityClasess />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS_EDIT} render={() => <EditMilkQualityClass />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTORS} render={() => <MgaFactors />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTOR_EDIT} render={() => <EditMgaFactor />} />

    {/* Ife */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_IFE_SETTINGS} render={() => <IfeSettings />} />

    {/* StoneX */}

    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_STONEX} render={() => <StoneX />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_STONEX_CREATE} render={() => <CreateEditStoneX />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_STONEX_EDIT} render={() => <CreateEditStoneX />} />
    <Route exact path={RouteTypes.DAIRY_FACTORY_VIEW_STONEX_SHOW} render={() => <ShowStoneX />} />

    {/* Emails */}
    <Route exact path={RouteTypes.EMAILS} render={() => <Emails />} />

  </Switch>
}
export default DairyViewRouter
