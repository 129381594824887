import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFields } from '~/components/FormFields'
import Modules from '~/pages/DairyFactory/Tabs/Modules'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { DairyApi } from '~/services'
import IsCooperatingForm from './IscooperatingForm'
import { useHistory, useParams } from 'react-router-dom'
import notify from '~/components/notify'
import { RouteTypes } from '~/Constants'
import { PageHeaderActionButton } from '~/components/Layout/style'

const pictureFields = ['settings.logo', 'settings.cover', 'settings.headIcon32']
const CreateEditFarmerGroup: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [farmerGroupOptions, setFarmerGroupOptions] = useState(null)
  const { t } = useTranslation()
  const history = useHistory()
  const { idDairy, id } = useParams()
  const wholeNumberErrorMessage = t('Please enter whole number')

  const { handleSubmit, control, reset, watch, setValue, getValues, formState, formState: { isSubmitted } } = useForm({
    mode: 'onSubmit',
    shouldUnregister: false
  })

  const watchIsOrganisationField = watch('isOrganization')
  const watchPhoneCodeField = watch(`office.phonePrefix`)
  const phoneNumber = watch(`office.phoneNumber`)

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const responseOptions = await DairyApi.OptionsFarmerGroup(idDairy)
      setFarmerGroupOptions(responseOptions.data)
      if (id) {
        const responseItem = await DairyApi.EditFarmerGroup(idDairy, id)
        responseItem.data.modules = responseItem.data?.modules?.map(module => ({ id: module })) || []
        if (responseItem.data?.isOrganization) {
          const headIcon32Res = await DairyApi.getFarmerGroupFile(idDairy, id, { fileName: responseItem.data.settings?.headIcon32 })
          const logoRes = await DairyApi.getFarmerGroupFile(idDairy, id, { fileName: responseItem.data.settings?.logo })
          responseItem.data.settings.logo = logoRes.data
          responseItem.data.settings.headIcon32 = headIcon32Res.data
        }
        reset(responseItem.data)
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }


  function renderActions() {
    return <div>
      <PageHeaderActionButton>{id ? t('Update') : t('Save')}</PageHeaderActionButton>
      <PageHeaderActionButton type='button' onClick={() => history.goBack()}>{t('Cancel')}</PageHeaderActionButton>
    </div>
  }

  function errorPhone() {
    if (isSubmitted) {
      return (!phoneNumber || phoneNumber === '') || (!watchPhoneCodeField || watchPhoneCodeField === '')
    }
    return false
  }

  async function onSubmit(data) {
    if (Boolean(watchIsOrganisationField) && errorPhone()) {
      notify(t('Please enter phone'), 'error')
      return
    }
    data.modules = getValues('modules').map(module => module.id ? module.id : module)
    if (id) {
      //do not send image fields to the api if they are not updated
      pictureFields.map(pictureField => {
        if (!Object.keys(formState.dirtyFields).find(key => key === pictureField)) {
          delete data[pictureField]
        }
      })
    }
    data.amountPercent = Math.floor(data.amountPercent)
    setIsBusy(true)
    try {
      if (!id) {
        await DairyApi.StoreFarmerGroup(idDairy, data)
        notify(t('Successfully created farmer group'), 'success')
      } else {
        await DairyApi.UpdateFarmerGroup(idDairy, id, data)
        notify(t('Successfully updated farmer group'), 'success')
      }
      history.push(RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP.replace(':id', idDairy))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  return <DairyViewTabsLayout
    isBusy={isBusy}
    segmented
    actions={renderActions()}
    header={id ? t('Edit farmer group') : t('Create farmer group')}
    onSubmit={handleSubmit(onSubmit)}
  >
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '45%', marginRight: '30px' }}>
          <FormFields.Input
            control={control}
            label={t('ID')}
            name='uniqueId'
            placeholder={t('ID')}
          />
          <FormFields.Input
            control={control}
            label={t('Name')}
            rules={{
              required: {
                value: true,
                message: t('Please enter name')
              }
            }}
            name='name'
            placeholder={t('Name')}
          />
          <FormFields.TextArea
            control={control}
            label={t('Description')}
            placeholder={t('Description')}
            name={'description'}
          />
          <div style={{ margin: '0 0 1em' }}>
            {farmerGroupOptions && <Modules
              control={control}
              setValue={setValue}
              notRequired={true}
              usePropertyName={'id'}
              modules={farmerGroupOptions?.modules || []}
            />
            }
          </div>
          <FormFields.Checkbox
            name='isOrganization'
            control={control}
            defaultValue={0}
            checkboxValue={1}
            label={t('Is cooperative')}
            toggle
          />
        </div>
        <div style={{ width: '45%' }}>
          <FormFields.Input
            control={control}
            rules={{
              required: {
                value: true,
                message: t('Please enter trading amount')
              },
              min: {
                value: 1,
                message: t('Minimum value enter must be 1')
              },
              validate: v => (v - Math.floor(v) === 0) || wholeNumberErrorMessage
            }}
            placeholder={t('Trading amount')}
            label={t('Trading amount (% / year)')}
            type='number'
            name='amountPercent'
          />
          <FormFields.Input
            control={control}
            label={t('Minimum contingent')}
            rules={{
              required: {
                value: true,
                message: t('Please enter minimum contingent')
              },
              min: {
                value: 1,
                message: t('Minimum value enter must be 1')
              },
              validate: v => (v - Math.floor(v) === 0) || wholeNumberErrorMessage
            }}
            placeholder={t('Minimum contingent')}
            type='number'
            name='minimumContingent'
          />
        </div>
      </div>
      {Boolean(watchIsOrganisationField) && <IsCooperatingForm
        watch={watch}
        setValue={setValue}
        getValues={getValues}
        control={control}
        isSubmitted={isSubmitted}
        farmerGroupOptions={farmerGroupOptions}
      />}
    </>
  </DairyViewTabsLayout>
}
export default CreateEditFarmerGroup
