import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { Input } from 'semantic-ui-react'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { Link, useParams } from 'react-router-dom'
import { useDebounce } from 'use-lodash-debounce'
import i18n from '~/components/i18n'
import { RouteTypes } from '~/Constants'
import notify from '~/components/notify'
import { DairyApi } from '~/services'
import TableComponent from '~/components/TableComponent'
import Dialog from '~/components/Dialog'

const tableColumns = [
  {
    name: i18n.t('Account Nr.'),
    key: 'accountNumber'
  },
  {
    name: i18n.t('Name'),
    key: 'name'
  },
  {
    name: i18n.t('Company name'),
    key: 'companyName'
  },
  {
    name: i18n.t('Status'),
    key: 'status',
    type: 'verified'
  },
  {
    name: i18n.t('User name'),
    key: 'username'
  },
  {
    name: i18n.t('Action')
  }
]
const StoneX: React.FC = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 300)
  const [stoneXData, setStoneXData] = useState(null)
  const [deleteStoneXAcc, setDeleteStoneXAcc] = useState(null)
  const [dataFiltered, setDataFiltered] = useState({
    page: 0,
    rows: 20,
    search: ''
  })

  const actions = [
    {
      popupMessage: i18n.t('Show'),
      type: 'link',
      name: 'eye',
      size: 'large',
      link: RouteTypes.DAIRY_FACTORY_VIEW_STONEX_SHOW.replace(':id', id),
      replaceKey: ':idAccount'
    },
    {
      popupMessage: i18n.t('Edit'),
      type: 'link',
      name: 'pencil',
      size: 'large',
      link: RouteTypes.DAIRY_FACTORY_VIEW_STONEX_EDIT.replace(':id', id),
      replaceKey: ':idAccount'
    },
    {
      popupMessage: i18n.t('Delete'),
      type: 'icon',
      color: 'red',
      name: 'trash',
      size: 'large',
      actionType: 'delete'
    }
  ]

  useEffect(() => {
    if (dataFiltered) {
      fetchFilteredData()
    }
  }, [dataFiltered])

  useEffect(() => {
    setDataFiltered({ ...dataFiltered, search: debouncedValue })
  }, [debouncedValue])

  async function fetchFilteredData() {
    setIsBusy(true)
    try {
      const response = await DairyApi.GetStoneXAccounts(
        id,
        { ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 }
      )
      setStoneXData(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderSearch() {
    return <Input
      icon={{ name: 'search', circular: true, link: true }}
      placeholder={t('Search...')}
      value={search}
      onChange={(e, data) => setSearch(data.value)}
    />
  }

  function renderActions() {
    return <PageHeaderActionButton
      as={Link}
      to={RouteTypes.DAIRY_FACTORY_VIEW_STONEX_CREATE.replace(':id', id)}
    >
      {t('Create')}
    </PageHeaderActionButton>
  }

  function handleClickAction(row, type) {
    switch (type) {
      case 'delete':
        return setDeleteStoneXAcc(row)
      default:
        return <></>
    }
  }

  function renderTable() {
    return <TableComponent
      tableColumnHeaders={tableColumns}
      actions={actions}
      handleClickAction={(row, type) => handleClickAction(row, type)}
      dataFiltered={dataFiltered}
      rows={stoneXData?.rows || []}
      total={stoneXData?.filtered || 0}
    />
  }

  async function handleDeleteUser() {
    setIsBusy(true)
    try {
      await DairyApi.DeleteStoneXAccount(id, deleteStoneXAcc?.id)
      const response = await DairyApi.GetStoneXAccounts(
        id,
        { ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 }
      )
      notify(t('Successfully deleted StoneX account'), 'success')
      setStoneXData(response.data)
      setDeleteStoneXAcc(null)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderDeleteModal() {
    const name = deleteStoneXAcc?.name

    return <Dialog
      isDialogOpen={Boolean(deleteStoneXAcc)}
      textClose={t('No')}
      handleClose={() => setDeleteStoneXAcc(null)}
      textSubmit={t('Yes')}
      handleSubmit={() => handleDeleteUser()}
      textContent={t('Do you want to delete {{name}} ?', { name })}
      textHeader={t('Delete {{name}} ?', { name })}
      type={'abort'}
    />
  }

  return <DairyViewTabsLayout
    header={t('StoneX accounts')}
    isBusy={isBusy}
    segmented
    search={renderSearch()}
    actions={renderActions()}
  >
    {renderDeleteModal()}
    {renderTable()}
  </DairyViewTabsLayout>
}
export default StoneX