import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { FarmerGroupSettings, UpdateFarmerGroupSettings } from '~/services/dairies'
import notify from '~/components/notify'
import { PageHeaderActionButton } from '~/components/Layout/style'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import { useForm } from 'react-hook-form'
import SettingsCard from './SettingsCard'

const Settings: React.FC = () => {
  const { t } = useTranslation()
  const { idDairy, id } = useParams()
  const history = useHistory()
  const [isBusy, setIsBusy] = useState(false)
  const [activeFtp, setActiveFtp] = useState(-1)

  const { handleSubmit, control, reset, getValues, setValue, watch } = useForm({
    mode: 'onSubmit'
  })

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await FarmerGroupSettings(idDairy, id)
      reset(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderActions() {
    return <div>
      <PageHeaderActionButton>{t('Update')}</PageHeaderActionButton>
      <PageHeaderActionButton type='button' onClick={() => history.goBack()}>{t('Cancel')}</PageHeaderActionButton>
    </div>
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      data.ftps?.forEach(ftp => {
        ftp.notificationEmails = ftp.notificationEmails.map((notificationEmail) => notificationEmail.email)
      })
      await UpdateFarmerGroupSettings(idDairy, id, data)
      notify(t('Successfully updated settings'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function handleRecipientsHeaderClick(e, titleProps) {
    const { index } = titleProps;
    setActiveFtp(activeFtp === index ? -1 : index);
  }

  function renderFtps() {
    const ftps = getValues('ftps') || []
    return <>
      {ftps.map((ftp, index) => {
        const active = activeFtp === index
        //@ts-ignore
        const watchScheduledActiveField = watch(`ftps[${index}].schedulerActive`)
        return <SettingsCard
          key={ftp.label}
          //@ts-ignore
          watchScheduledActiveField={watchScheduledActiveField}
          ftp={ftp}
          index={index}
          active={active}
          handleRecipientsHeaderClick={handleRecipientsHeaderClick}
          control={control}
          options={{ protocols: getValues('protocols'), days: getValues('days'), formats: getValues('formats') }}
          getValues={getValues}
          setValue={setValue}
        />
      })}
    </>
  }

  return <DairyViewTabsLayout
    isBusy={isBusy}
    segmented
    actions={renderActions()}
    header={t('Settings')}
    onSubmit={handleSubmit(onSubmit)}
  >
    {getValues('protocols') && renderFtps()}
  </DairyViewTabsLayout>
}
export default Settings