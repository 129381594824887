import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormFields } from '~/components/FormFields'
import ColorPicker from '~/components/ColorPicker'
import FileUpload from '~/components/FileUpload'
import { Form, Icon } from 'semantic-ui-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { StyledImageWrapper, StyledImageContainer } from '~/pages/Profile/style'

const defaultImage = '/assets/default.png'

const ImagesColors: React.FC = () => {
  const { control, setValue, watch } = useFormContext()
  const { t } = useTranslation()
  const [cover, logo, headIcon32] = watch(['cover', 'logo', 'headIcon32'])
  const watchFirstColor = useWatch({ control, name: 'firstColor' })

  function renderPreviewImage(value: string, field: string) {
    return value && <StyledImageWrapper>
      <img src={value ? value : defaultImage}></img>
      <StyledImageContainer />
      {value && <Icon
        circular
        //@ts-ignore
        onClick={(e) => { e.preventDefault(); setValue(field.toString(), null) }}
        name='trash'
        className='trashCan'
      />
      }
    </StyledImageWrapper>
  }

  return <>
    <Form.Group widths='equal'>
      <FormFields.External
        fluid
        name={'firstColor'}
        label={t('First color')}
        control={control}
        defaultValue={watchFirstColor}
        rules={{
          required: {
            value: true,
            message: t('Please enter first color')
          }
        }}
      >
        <ColorPicker value={watchFirstColor}>
          <FormFields.Input
            control={control}
            hideError
            onChange={(e, data) => setValue('firstColor', data.value)}
            placeholder={t('First color')}
            name={'firstColor'}
          />
        </ColorPicker>
      </FormFields.External>
      <FormFields.External
        fluid
        name={'secondColor'}
        label={t('Second color')}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('Please enter second color')
          }
        }}
      >
        <ColorPicker>
          <FormFields.Input
            control={control}
            hideError
            placeholder={t('Second color')}
            name={'secondColor'}
          />
        </ColorPicker>
      </FormFields.External>
      <FormFields.External
        name={'chartBarColor'}
        label={t('Bar chart color')}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('Please enter bar chart color')
          }
        }}
      >
        <ColorPicker>
          <FormFields.Input
            control={control}
            hideError
            placeholder={t('Bar chart color')}
            name={'chartBarColor'}
          />
        </ColorPicker>
      </FormFields.External>
      <FormFields.External
        name={'chartLineColor'}
        label={t('Line chart color')}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('Please enter line chart color')
          }
        }}
      >
        <ColorPicker>
          <FormFields.Input
            control={control}
            hideError
            placeholder={t('Line chart color')}
            name={'chartLineColor'}
          />
        </ColorPicker>
      </FormFields.External>
      <FormFields.External
        name={'chartBarColor2'}
        label={t('Second bar chart color')}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('Please enter line chart color2')
          }
        }}
      >
        <ColorPicker>
          <FormFields.Input
            control={control}
            hideError
            placeholder={t('Second bar chart color')}
            name={'chartBarColor2'}
          />
        </ColorPicker>
      </FormFields.External>
    </Form.Group>
    <Form.Group widths='equal'>
      <FormFields.External
        name='logo'
        label={t('Logo')}
        rules={{
          required: {
            value: true,
            message: t('Please upload a logo')
          }
        }}
        control={control}>
        <FileUpload
          description={t('Drag and drop Logo')}
          multiple={false}
          encodeFiles
          mimeTypes={['bmp', 'jpg', 'jpeg', 'png']}
          sizeLimitInMB={5}
        >
          {renderPreviewImage(logo, 'logo')}
        </FileUpload>

      </FormFields.External>
      <FormFields.External
        name='cover'
        label={t('Cover')}
        rules={{
          required: {
            value: true,
            message: t('Please upload a cover')
          }
        }}
        control={control}
      >
        <FileUpload
          description={t('Drag and drop Cover')}
          multiple={false}
          encodeFiles
          mimeTypes={['bmp', 'jpg', 'jpeg', 'png']}
          sizeLimitInMB={5}
        >
          {renderPreviewImage(cover, 'cover')}
        </FileUpload>
      </FormFields.External>
      <FormFields.External
        name='headIcon32'
        label={t('Head icon')}
        rules={{
          required: {
            value: true,
            message: t('Please upload head icon')
          }
        }}
        control={control}
      >
        <FileUpload
          description={t('Drag and drop Favicon')}
          multiple={false}
          file={headIcon32}
          encodeFiles
          mimeTypes={['bmp', 'jpg', 'jpeg', 'png', 'ico', 'x-icon']}
          sizeLimitInMB={2}
        >
          {renderPreviewImage(headIcon32, 'headIcon32')}
        </FileUpload>
      </FormFields.External>
    </Form.Group>
  </>
}
export default ImagesColors
