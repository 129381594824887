import React, { useEffect, useState } from 'react'
import { Route, Router as BaseRouter, Switch, Redirect, LocationShape } from 'react-router-dom'
import { ADMIN_TOKEN, RouteTypes } from '~/Constants'
import { useSelector, useDispatch } from 'react-redux'
import { SystemDataState } from '~/reducers'
//actions
import { createBrowserHistory } from 'history'
import { LoginApi } from '~/services'
import { systemData as actionSystemData } from '~/actions/systemData'
// components
import Login from './Login'
import Dashboard from './Dashboard'
import Error from './Error'
import DairyFactory from '~/pages/DairyFactory'
import DairyFactoryCreate from '~/pages/DairyFactory/CreateDairyFactory'
import DairyFactoryView from '~/pages/DairyFactory/View'
import DashBoard from '~/pages/Dashboard'
import Profile from '~/pages/Profile'
import Users from '~/pages/Users'
import CreateEditUser from '~/pages/Users/CreateEditUser'
import UpdateLogs from '~/pages/UpdateLogs'
import CreateEditUpdateLogs from '~/pages/UpdateLogs/CreateEditUpdateLogs'
import { SystemDataType } from '~/services/login'
import notify from '~/components/notify'
import i18n from '~/components/i18n'
import { useTranslation } from 'react-i18next'
import SftpAccounts from './SftpAccounts'
import LoaderMilkCan from '~/components/Loader'
import CreateEditSftpAccount from './SftpAccounts/CreateEditSftpAccount'

type PrivateRouteProps = {
  component: any,
  authed: boolean,
  location?: LocationShape,
  path: string,
  exact?: boolean
}

const history = createBrowserHistory()
const Router: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const [isFetchingSystemData, setIsFetchingSystemData] = useState(true)

  useEffect(() => {
    if (!systemData && localStorage.getItem(ADMIN_TOKEN)) {
      fetchUserData()
    } else {
      setIsFetchingSystemData(false)
    }
  }, [])

  async function fetchUserData() {
    try {
      const responseSystemData = await LoginApi.getSystemData()
      const languages = responseSystemData.data.languages
      const { data: { logged: { languageId } } } = responseSystemData
      const userLoggedLanguage = languages.find(language => language.id === languageId)
      i18n.changeLanguage(userLoggedLanguage.code)
      dispatch(actionSystemData.setSystemData(responseSystemData.data))
    } catch (error) {
      localStorage.removeItem(ADMIN_TOKEN)
      if (error.response.data) {
        debugger;
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsFetchingSystemData(false)
  }

  function PrivateRoute({ component: Component, authed, ...rest }: PrivateRouteProps) {
    return (
      <Route
        {...rest}
        render={(props) => authed ? <Component {...props} /> : <Redirect to={{
          pathname: RouteTypes.LOGIN,
          state: { from: props.location }
        }} />}
      />
    )
  }

  const hasUser = Boolean(systemData) && Boolean(localStorage.getItem(ADMIN_TOKEN))

  if (isFetchingSystemData) {
    return <div>
      <LoaderMilkCan isLoading />
    </div>
  }

  return <BaseRouter history={history}>
    <Switch>
      {/* Login */}
      <Route path={RouteTypes.LOGIN} component={!hasUser ? Login : () => <Redirect to={RouteTypes.DAIRY_FACTORY} />} />
      <PrivateRoute key={'/'} authed={hasUser} exact path='/' component={Dashboard} />

      {/* Dashboard */}
      <PrivateRoute key={RouteTypes.HOME} authed={hasUser} exact path={RouteTypes.HOME} component={DashBoard} />

      {/* DairyFactory */}
      <PrivateRoute key={RouteTypes.DAIRY_FACTORY} authed={hasUser} exact path={RouteTypes.DAIRY_FACTORY} component={DairyFactory} />
      <PrivateRoute key={RouteTypes.DAIRY_FACTORY_CREATE} authed={hasUser} exact path={RouteTypes.DAIRY_FACTORY_CREATE} component={DairyFactoryCreate} />
      <PrivateRoute key={RouteTypes.DAIRY_FACTORY_EDIT} authed={hasUser} exact path={RouteTypes.DAIRY_FACTORY_EDIT} component={DairyFactoryCreate} />
      <PrivateRoute key={RouteTypes.DAIRY_FACTORY_VIEW} authed={hasUser} path={RouteTypes.DAIRY_FACTORY_VIEW} component={DairyFactoryView} />

      {/* Profile */}
      <PrivateRoute key={RouteTypes.PROFILE} authed={hasUser} exact path={RouteTypes.PROFILE} component={Profile} />

      {/* Users */}
      <PrivateRoute key={RouteTypes.USERS} authed={hasUser} exact path={RouteTypes.USERS} component={Users} />
      <PrivateRoute key={RouteTypes.USERS_CREATE} authed={hasUser} exact path={RouteTypes.USERS_CREATE} component={CreateEditUser} />
      <PrivateRoute key={RouteTypes.USERS_EDIT} authed={hasUser} exact path={RouteTypes.USERS_EDIT} component={CreateEditUser} />

      {/* SFTP accounts */}
      <PrivateRoute key={RouteTypes.SFTP_ACCOUNTS} authed={hasUser} exact path={RouteTypes.SFTP_ACCOUNTS} component={SftpAccounts} />
      <PrivateRoute key={RouteTypes.SFTP_ACCOUNTS_CREATE} authed={hasUser} exact path={RouteTypes.SFTP_ACCOUNTS_CREATE} component={CreateEditSftpAccount} />
      <PrivateRoute key={RouteTypes.SFTP_ACCOUNTS_EDIT} authed={hasUser} exact path={RouteTypes.SFTP_ACCOUNTS_EDIT} component={CreateEditSftpAccount} />

      {/* Update logs */}
      <PrivateRoute key={RouteTypes.UPDATE_LOGS} authed={hasUser} exact path={RouteTypes.UPDATE_LOGS} component={UpdateLogs} />
      <PrivateRoute key={RouteTypes.UPDATE_LOGS_CREATE} authed={hasUser} exact path={RouteTypes.UPDATE_LOGS_CREATE} component={CreateEditUpdateLogs} />
      <PrivateRoute key={RouteTypes.UPDATE_LOGS_EDIT} authed={hasUser} exact path={RouteTypes.UPDATE_LOGS_EDIT} component={CreateEditUpdateLogs} />

      {/* Error */}
      <Route path='*' component={Error} />
    </Switch>
  </BaseRouter>
}
export default Router
