import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeaderActionButton } from '~/components/Layout/style'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import notify from '~/components/notify'
import { GetMilkMoneyFtps, GetMilkMoneyFtpsOptions, UpdateMilkMoneyFtps } from '~/services/dairies'
import { useParams } from 'react-router-dom'
import MilkMoneyFtp from './MilkMoneyFtp'
import i18n from '~/components/i18n'
import TestRingFtps from './TestRingFtps'
import cloneDeep from 'lodash.clonedeep'

const milkSamplesFtps = [
  {
    name: 'milkTestRingFtp',
    title: i18n.t('Milk Test Ring export'),
    removeScheduleTime: false
  },
  {
    name: 'milkDailySamplesFtp',
    title: i18n.t('Milk daily samples'),
    removeScheduleTime: false
  },
  {
    name: 'milkSamplesIndividualFtp',
    title: i18n.t('Milk samples - individual values'),
    removeScheduleTime: true
  },
  {
    name: 'milkSamplesFarmerAveragesFtp',
    title: i18n.t('Milk samples - farmers average values'),
    removeScheduleTime: true
  },
  {
    name: 'milkSamplesDairyAveragesFtp',
    title: i18n.t('Milk Samples - dairy average values'),
    removeScheduleTime: true
  },
  {
    name: 'milkPseudomonadsSamplesFtp',
    title: i18n.t('Milk Samples - pseudom.'),
    removeScheduleTime: true
  }
] as MilkSampleType[]

export interface FtpType {
  id: number;
  name: string | null;
  type: string;
  slug: string;
  ftpIp: string | null;
  ftpPort: number | null;
  ftpUser: string | null;
  ftpPassword: string | null;
  protocol: number;
  folder: string | null;
  schedulerActive: number;
  schedulerDays: string[];
  connection?: boolean;
  schedulerTime: string | null;
  notificationStatus: number;
  notificationEmails: string[] | { email: string }[];
  lastRun: string | null;
  startAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export type FtpKeys = 'milkTestRingFtp' | 'milkSamplesIndividualFtp' | 'milkSamplesFarmerAveragesFtp' |
  'milkSamplesDairyAveragesFtp' | 'milkDailySamplesFtp' | 'milkPseudomonadsSamplesFtp'
export interface MilkSampleType {
  name: FtpKeys
  title: string
  removeScheduleTime: boolean
}
export interface FormDataType {
  ftps: FtpType[];
  milkTestRingFtp: FtpType;
  milkSamplesIndividualFtp: FtpType;
  milkSamplesFarmerAveragesFtp: FtpType;
  milkSamplesDairyAveragesFtp: FtpType;
  milkDailySamplesFtp: FtpType;
  milkPseudomonadsSamplesFtp: FtpType;
}

const MilkMoney: React.FC = () => {
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const { id } = useParams()
  const [options, setOptions] = useState(null)
  const [activeFtp, setActiveFtp] = useState(-1)

  const methods = useForm<FormDataType>({
    mode: 'onSubmit',
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'ftps'
  })

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const responseOptions = await GetMilkMoneyFtpsOptions(id)
      const responseEdit = await GetMilkMoneyFtps(id)
      setOptions(responseOptions.data)
      milkSamplesFtps.map(milkSamplesFtp => {
        const { name } = milkSamplesFtp
        if (responseEdit.data[name]) {
          responseEdit.data[name].notificationEmails = responseEdit.data[name].notificationEmails.map((notificationEmail) => ({ email: notificationEmail }))
          responseEdit.data[name].schedulerActive = 0
        }
      })
      responseEdit.data.ftps?.forEach(ftp => {
        ftp.notificationEmails = ftp.notificationEmails.map((notificationEmail) => ({ email: notificationEmail }))
      })
      methods.reset({ ...responseEdit.data })
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderActions() {
    return <PageHeaderActionButton
    >{t('Update')}</PageHeaderActionButton>
  }

  function handleRecipientsHeaderClick(e, titleProps) {
    const { index } = titleProps;
    setActiveFtp(activeFtp === index ? -1 : index);
  }

  function renderFTPS() {

    return fields.map((field, index) => {
      return <MilkMoneyFtp
        ftp={field}
        index={index}
        options={options}
        activeFtp={activeFtp}
        handleRecipientsHeaderClick={handleRecipientsHeaderClick}
        removeFtp={(index) => remove(index)}
      />
    })
  }

  function parseEmails(emails) {
    return emails.map((notificationEmail) => notificationEmail.email)
  }

  async function onSubmit(data: FormDataType) {
    const payload = cloneDeep(data)
    setIsBusy(true)
    try {
      payload.ftps?.forEach(ftp => {
        ftp.notificationEmails = parseEmails(ftp.notificationEmails)
      })
      milkSamplesFtps.map(milkSample => {
        if (payload[milkSample.name]) {
          payload[milkSample.name].notificationEmails = parseEmails(payload?.milkTestRingFtp?.notificationEmails)
        }
      })
      await UpdateMilkMoneyFtps(id, payload)
      notify(t('Successfully updated settings'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function addNewFtp() {
    append({
      schedulerDays: [],
      name: '',
      ftpPort: 22,
      protocol: 0,
      schedulerActive: 0
    })
  }

  return <DairyViewTabsLayout
    header={t('Milk money FTPS')}
    isBusy={isBusy}
    segmented
    actions={renderActions()}
    onSubmit={methods.handleSubmit(onSubmit)}
  >
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <PageHeaderActionButton
        type={'button'}
        onClick={() => addNewFtp()}
      >
        {t('Add')}
      </PageHeaderActionButton>
    </div>
    <FormProvider {...methods}>
      {options && renderFTPS()}
      {options && milkSamplesFtps.map((item, index) => {
        return <TestRingFtps
          name={item.name as MilkSampleType['name']}
          options={options}
          title={item.title}
          removeScheduleTime={item?.removeScheduleTime}
          index={index + fields.length}
          handleRecipientsHeaderClick={handleRecipientsHeaderClick}
          activeFtp={activeFtp}
        />
      })}
    </FormProvider>
  </DairyViewTabsLayout>

}
export default MilkMoney