import api from './api'

export const getSftpAccounts = (data?: SftpAccountDataType) =>
  api.post<SftpType>('/sftp-accounts/index', data)

export const getSftpOptions = () =>
  api.post('/sftp-accounts/get-options-data')

export const createSftpAccount = (data?) =>
  api.post('/sftp-accounts/store', data)

export const editSftpAccount = (id: string) =>
  api.post(`/sftp-accounts/${id}/edit`)

export const updateSftpAccount = (id: string, data) =>
  api.post(`/sftp-accounts/${id}/update`, data)

export const deleteSftpAccount = (id: string) =>
  api.post(`/sftp-accounts/${id}/delete`)

interface SftpAccountDataType {
  search?: string
  column?: string
  sort?: string
  page?: number
  rows?: number
}

interface SftpAccount {
  id: number;
  name: string;
  username: string;
  password: string;
  createdAt: string;
  folders: Folder[];
}

interface Folder {
  id: number;
  awsSftpAccountId: number;
  name: string;
}

interface SftpType {
  rows: SftpAccount[];
  total: number;
  filtered: number;
}