import { MenuItem, Tab } from "semantic-ui-react";
import styled from "styled-components";

export const StyledTabPane = styled(Tab.Pane)`
  padding: 0 !important;
  background: none !important;
  border: none !important;
  padding-bottom: 1rem !important;
`

export const StyledMenuItem = styled(MenuItem)`
 .error {
  color: red;
 }
`