import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import i18n from '~/components/i18n'
import { Popup, Table } from 'semantic-ui-react'
import { ReactSortable } from 'react-sortablejs'
import { MilkMoneyApi } from '~/services'
import notify from '~/components/notify'
import { formatNumber } from '~/util'
import CustomIcon from '~/components/CustomIcon'
import { RouteTypes } from '~/Constants'
import styled from 'styled-components'

const tableColumns = [
  {
    name: i18n.t('ID'),
    key: 'id'
  },
  {
    name: i18n.t('Name'),
    key: 'name'
  },
  {
    name: i18n.t('Price'),
    key: 'price',
    type: 'percent'
  },
  {
    name: i18n.t('Inhibitor Status'),
    key: 'inhibitorStatus'
  },
  {
    name: i18n.t('Status'),
    key: 'status'
  },
  {
    name: i18n.t('Cell Count Last Month Amount'),
    key: 'cellCountGmLastMonthAmount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Cell Count Last Month Status'),
    key: 'cellCountGmLastMonthStatus'
  },
  {
    name: i18n.t('Cell Count Last Few Months Amount'),
    key: 'cellCountGmLastFewMonthsAmount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Cell Count Last Few Months Status'),
    key: 'cellCountGmLastFewMonthsStatus'
  },
  {
    name: i18n.t('Germ Count Last Month Amount'),
    key: 'germCountGmLastMonthAmount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Germ Count Last Month Status'),
    key: 'germCountGmLastMonthStatus'
  },
  {
    name: i18n.t('Germ Count Last Few Months Amount'),
    key: 'germCountGmLastFewMonthsAmount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Germ Count Last Few Months Status'),
    key: 'germCountGmLastFewMonthsStatus'
  },
  {
    name: i18n.t('Actions'),
    type: 'action'
  }
];

const StyledTable = styled(Table)`
 th {
  white-space: break-spaces !important;
 }
`

const MilkQualityClasses: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [milkQualityClasses, setMilkQualityClasses] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await MilkMoneyApi.GetMilkQualityClasses(id)
      setMilkQualityClasses(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderTableCell(tableColumn, milkQualityClass) {
    return <Table.Cell key={tableColumn.key + milkQualityClass.id}>{milkQualityClass[tableColumn.key]}</Table.Cell>
  }

  function renderCellBasedOnType(tableColumn, milkQualityClass) {
    const value = milkQualityClass[tableColumn?.key]
    switch (tableColumn.type) {
      case 'no-unit-int-number':
        return value ? `${formatNumber(parseFloat(value), 0)}` : '0'
      case 'percent':
        return formatNumber(value)
      case 'action':
        return <Popup
          content={t('Edit')}
          trigger={<Link
            to={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS_EDIT.replace(':id', id).replace(':idMilkQ', milkQualityClass?.id)}
          >
            <CustomIcon
              size='large'
              name='pencil' />
          </Link>}
        />
      default:
        return value
    }
  }

  function renderSortItems() {
    return milkQualityClasses.map(milkQualityClass => {
      return <Table.Row style={{ cursor: 'grab' }} key={milkQualityClass.id}>
        {tableColumns.map(tableColumn => {
          return tableColumn.type ? <Table.Cell key={tableColumn.key + milkQualityClass.id}>{renderCellBasedOnType(tableColumn, milkQualityClass)}
          </Table.Cell> : renderTableCell(tableColumn, milkQualityClass)
        })}
      </Table.Row>
    })
  }

  async function handleReorder() {
    try {
      await MilkMoneyApi.ReorderMilkQualityClasses(id,
        { milkQualityClasses: milkQualityClasses.map(milkQualityClass => milkQualityClass.id) })
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
  }

  function renderTable() {
    return <StyledTable celled fixed singleLine>
      <Table.Header>
        <Table.Row>
          {tableColumns.map(tableColumn => {
            return <Table.HeaderCell key={tableColumn.key}>{tableColumn.name}</Table.HeaderCell>
          })}
        </Table.Row>
      </Table.Header>
      {/*@ts-ignore*/}
      <ReactSortable
        list={milkQualityClasses}
        tag={'tbody'}
        animation={150}
        onSort={() => handleReorder()}
        delayOnTouchOnly={true}
        delay={2}
        setList={setMilkQualityClasses}
      >
        {renderSortItems()}
      </ReactSortable>
    </StyledTable>
  }

  return <DairyViewTabsLayout
    segmented
    header={t('Milk quality classes')}
    isBusy={isBusy}
  >
    {renderTable()}
  </DairyViewTabsLayout>
}
export default MilkQualityClasses