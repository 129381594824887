import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'semantic-ui-css/semantic.min.css'
import { GlobalStyle, theme } from './Theme'
import { ThemeProvider } from 'styled-components'
import '@fontsource/nunito-sans';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     staleTime: 5 * 60 * 1000
  //   }
  // },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    }
  })
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle></GlobalStyle>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
