import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import { useHistory, useParams } from 'react-router-dom'
import { Form } from 'semantic-ui-react'
import notify from '~/components/notify'
import { DairyApi } from '~/services'
import { RouteTypes } from '~/Constants'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { StyledGroup } from '../style'
import localeData from 'dayjs/plugin/localeData'

// Forms 
import Image from './Forms/Image'
import GeneralInfo from './Forms/GeneralInfo'
import Location from './Forms/Location'
import PhoneFax from './Forms/PhoneFax'
import AmountFarmerGroup from './Forms/AmountFarmerGroup'
import dayjs from 'dayjs'
import { formatNumber, handleErrors } from '~/util'

const CreateEditFarmers: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false)
  const { id, idFarmer } = useParams()
  const [farmersOptions, setFarmersOptions] = useState()
  const [farmerGroupsList, setFarmerGroupsList] = useState([])
  const [farmersList, setFarmersList] = useState([])
  const history = useHistory()
  const { t } = useTranslation()
  dayjs.extend(localeData)
  dayjs().localeData()

  const methods = useForm({
    mode: 'onSubmit'
  })

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const responseOptions = await DairyApi.FarmersOptions(id)
      const responseList = await DairyApi.ListFarmerGroup(id)
      const farmersResponse = await DairyApi.FarmersList(id)
      if (idFarmer) {
        const response = await DairyApi.FarmerEdit(id, idFarmer)
        const fileResponse = await DairyApi.FarmerFile(id, idFarmer)
        response.data.amounts = addMonthsAndAmounts(response.data.amounts)
        methods.reset({
          ...response.data,
          terminationDate: response.data?.terminationDate ? dayjs(response.data?.terminationDate).format('DD.MM.YYYY') : response.data?.terminationDate,
          lastDeliveryDate: response.data?.lastDeliveryDate ? dayjs(response.data?.lastDeliveryDate).format('DD.MM.YYYY') : response.data?.lastDeliveryDate
        })
        methods.setValue('logo', fileResponse.data)
      } else {
        methods.setValue('amounts', addMonthsAndAmounts([]))
      }
      setFarmersList(farmersResponse.data)
      setFarmerGroupsList(responseList.data.map(farmerGroup => ({
        value: farmerGroup.id,
        text: `${farmerGroup.name} (${formatNumber(farmerGroup.amountPercent)}%)`
      })))
      setFarmersOptions(responseOptions.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function addMonthsAndAmounts(data) {
    const lastMonthIndex = dayjs().month() - 1
    const monthsAndAmounts = []
    for (let i = lastMonthIndex, j = 0; i >= 0; i--, j++) {
      const date = dayjs(new Date(`${dayjs().year()}-${i + 1 >= 10 ? i + 1 : `0${i + 1}`}-01`)).format('YYYY-MM-DD')
      const findIndexAmount = id ? data.findIndex(amount => amount.month === date) : -1
      monthsAndAmounts.push({
        month: date,
        amount: findIndexAmount >= 0 ? data[findIndexAmount].amount : ''
      })
    }
    for (let i = 11, j = lastMonthIndex + 1; i > lastMonthIndex; i--, j++) {
      const date = dayjs(new Date(`${dayjs().year() - 1}-${i + 1 >= 10 ? i + 1 : `0${i + 1}`}-01`)).format('YYYY-MM-DD')
      const findIndexAmount = id ? data.findIndex(amount => amount.month === date) : -1
      monthsAndAmounts.push({
        month: date,
        amount: findIndexAmount >= 0 ? data[findIndexAmount].amount : ''
      })
    }
    return monthsAndAmounts
  }

  function renderActions() {
    return <div>
      <PageHeaderActionButton disabled={isBusy}>{idFarmer ? t('Update') : t('Save')}</PageHeaderActionButton>
      <PageHeaderActionButton
        disabled={isBusy}
        type='button'
        onClick={() => history.goBack()}
      >{t('Cancel')}</PageHeaderActionButton>
    </div>
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      if (!id) {
        await DairyApi.FarmerCreate(id, data)
        notify(t('Successfully created farmer'), 'success')
      } else {
        await DairyApi.FarmerUpdate(id, idFarmer, data)
        notify(t('Successfully updated farmer'), 'success')
      }
    } catch (error) {
      handleErrors(error)
    }
    setIsBusy(false)
  }

  function renderFarmerForm() {
    return <StyledGroup>
      <Image />
      <Form.Field width={6}>
        <GeneralInfo
          farmersOptions={farmersOptions}
          farmersList={farmersList}
        />
        <Location farmersOptions={farmersOptions} />
      </Form.Field>
      <Form.Field width={6}>
        <PhoneFax farmersOptions={farmersOptions} />
        <AmountFarmerGroup
          farmerGroupList={farmerGroupsList}
          farmersOptions={farmersOptions}
        />
      </Form.Field>
    </StyledGroup>
  }

  return <DairyViewTabsLayout
    isBusy={isBusy}
    header={idFarmer ? t('Edit farmer') : t('Create Farmer')}
    segmented
    actions={renderActions()}
    onSubmit={methods.handleSubmit(onSubmit)}
  >
    <FormProvider {...methods}>
      {farmersOptions && renderFarmerForm()}
    </FormProvider>
  </DairyViewTabsLayout>

}
export default CreateEditFarmers
