import {Card} from 'semantic-ui-react'
import styled from 'styled-components'

export const BoxContainer = styled(Card)`
  // box-shadow: 0px 3px 20px rgb(0 0 0 / 4%) !important;
  box-shadow: none !important;
  border-radius: .375rem !important;
  border-color: transparent !important;

  .content {
    > :first-child:not(.equal)  {
     
    }

    .header {
      font-size: 1.20rem !important;
      line-height: 1.5rem !important;
      margin-right: auto !important;
      font-weight: 600 !important;
      color: rgb(30 41 59) !important;
    }
  }
`
