import { Card, Label, Tab } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledTabWrapper = styled.div`
  .wrapped {
    position: relative;
    padding-left: 5rem;
    padding-right: 5rem;
    flex-direction: row;
    justify-content: center;
    display: flex;
    border: none;
    box-shadow: none;

    &:before {
      content: '';
      display: block;
      width: 80%;
      height: 5px;
      margin-top: 1.6rem;
      bottom: 0px;
      top: 0px;
      position: absolute;
      background-color: #f0f4f9;
    }

    &:after {
      content: '';
      display: none;
    }

     .tab-item {
      display: block !important;
      text-align: center !important;
      flex: 1 1 0% !important;
      flex-grow: 0;
      position: relative !important;
      padding: 0;
      line-height: unset;
      font-weight: 500;
      cursor: pointer;
      align-items: unset !important;
    
      &:hover {
        background: transparent !important;
        .tab-number {
          border: 1px solid #66738B;
        }
      }

      &:before {
       content: '';
       display: none;
      }    

      &.active {
        background: transparent;

        .tab-name {
          font-weight: bold;
        }

        .tab-number {
          background-color: #1A3175;
          color: #FFFFFF;          
        }
      }  
    }

    .tab-number {
      border-radius: 9999px;
      width: 3.5rem;
      height: 3.5rem;
      display: inline-flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-width: 1px;
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: .75rem;
      padding-right: .75rem;
      background-color: #F0F4F9;
      color: #66738B;
      font-weight: bold;
      &.error {
        background-color: #f6d0d0 !important;
        color: #9f3a38;          
        border-color: #e0b4b4
      }
    }

    .tab-name {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: .75rem;
      font-weight: 500;
      margin-left: auto;
      margin-right: auto;
      width: 12rem;
    }
  }

  .default-language-group {
    margin-top: 1.2rem !important;
  }
`
export const DairyInfo = styled(Card.Description)`
  .dairy-info {
    .dairy-info_data {
      color: #65768b;
      margin-bottom: .5rem;
    }
  }

  .dairy-address {
    margin-bottom: 1rem;
  }

  .success-color {
    color: #21ba45 !important;
  }

  .error-color {
    color: #db2828 !important;
  }
`
export const CardWithBottomMargin = styled(Card)`
margin-bottom: 15px !important;
max-width: 300px;
width: 250px;
.img-shadow {
  filter: drop-shadow(1px 2px 2px black);
}
`
export const LoaderWrapper = styled.div`
    position: relative;
    float: right;
    margin-top: 18px;
    margin-right: 15px;
    .loader-text {
      line-height: 0px;
    }
  }
  `
export const UpdateVersionLoaderWrapper = styled.div`
    display: flex;
    .update-info-position {
      position: relative;
      margin-bottom: 8px;
      margin-left: 15px;
    }
  }
  `
export const ManagingStatusIconWrapper = styled.div`
  float: right;
  margin-top: 8px;
}
`

export const ErrorLabel = styled(Label)`
white-space: normal;
background: #fff!important;
border: 1px solid #e0b4b4!important;
color: #9f3a38!important;
`

export const StyledNoResultsMessage = styled.div`
display: flex;
justify-content: center;
.ui.placeholder.segment {
  width: 200px;
  min-height: 200px;
  border-radius: 50%;
  .header {
    margin-right: unset !important
   }
}
`

export const StyledTabPane = styled(Tab.Pane)`
  padding: 0 !important;
  background: none !important;
  border: none !important;
  padding-bottom: 1rem !important;
`

export const FilterWrapper = styled.div`
display: flex;
align-items: baseline;
flex-wrap: wrap;
gap: 10px;
`
