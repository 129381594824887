import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {FormFields} from '~/components/FormFields'
import {DairyOptionsType} from '~/services/dairies'

interface AmountUnitsProps {
  dairyOptions: DairyOptionsType
}

const AmountUnits = (props: AmountUnitsProps) => {
  const {dairyOptions} = props
  const {control} = useFormContext()
  const {t} = useTranslation()

  return <>
    <FormFields.Select
      control={control}
      options={dairyOptions.farmerAmountTypes.map(farmerTypeOption => ({value: farmerTypeOption.key, text: farmerTypeOption.label}))}
      name={'farmerAmountType'}
      placeholder={t('select amount type')}
      selectOnBlur={false}
      rules={{
        required: {
          value: true,
          message: t('Please select farmer amount type')
        }
      }}
      label={t('Farmer amount type')}
    />
    <FormFields.Select
      control={control}
      selectOnBlur={false}
      placeholder={t('select amount unit')}
      options={dairyOptions.amountUnits.map(amountUnit => ({value: amountUnit, text: amountUnit}))}
      name={'amountUnit'}
      rules={{
        required: {
          value: true,
          message: t('Please select amount unit')
        }
      }}
      label={t('Amount unit')}
    />
    <FormFields.Select
      control={control}
      selectOnBlur={false}
      placeholder={t('select amount volume')}
      options={dairyOptions.amountVolumes.map(amountVolume => ({value: amountVolume, text: amountVolume}))}
      name={'amountVolume'}
      rules={{
        required: {
          value: true,
          message: t('Please select amount volume')
        }
      }}
      label={t('Amount volume')}
    />
    <FormFields.Select
      control={control}
      selectOnBlur={false}
      options={dairyOptions.amountPrices.map(amountPrice => ({value: amountPrice.key, text: amountPrice.label}))}
      placeholder={t('select amount price')}
      name={'amountPrice'}
      rules={{
        required: {
          value: true,
          message: t('Please select amount price')
        }
      }}
      label={t('Amount price')}
    />
  </>
}
export default AmountUnits
