import {ActionTypes} from '~/Constants'

export const systemData = {
  setSystemData: (value) => (
    {
      type: ActionTypes.SYSTEM_DATA_RECEIVED,
      payload: value
    }
  )
}
