import React from 'react';
import styled from 'styled-components';
import { Icon, IconProps } from 'semantic-ui-react';

const CustomIcon: React.FC<IconProps> = (props) => {

  return (
    <StyledCustomIcon
      customcolor={props.color ? props.color : '#1a3175'}
      as={props.as}
      link={props.link}
      name={props.name}
      className={props.size === 'large' ? 'table-large-icons' : ''}
      size={props.size}
      {...props}
    />
  );
};
export default CustomIcon;

const StyledCustomIcon = styled(Icon)`
  color: ${(props) => (props.customcolor ? props.customcolor : '#1a3175')} !important;
  cursor:pointer;
  margin-right: 15px !important;
  opacity: 1 !important;
  vertical-align: middle !important;
`;
