import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'
import { RouteTypes, emailRegex, passwordRegex } from '~/Constants'
import { UsersApi } from '~/services'
import { UserOptionsType } from '~/services/users'
import { FormFields } from '~/components/FormFields'
import { useForm } from 'react-hook-form'
import FileUpload from '~/components/FileUpload'
import { StyledLabel } from './style'
import { StyledImageWrapper, StyledImageContainer } from '~/pages/Profile/style'
import { Form, Icon, Input, Dropdown } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router-dom'
import notify from '~/components/notify'
import { UserType } from '~/services/users'
import Box from '~/components/Box'

const defaultImage = '/assets/default.png'
const MINIMUM_LENGTH_PASSWORD = 8
const MAX_LENGTH_PASSWORD = 32

const CreateEditUser: React.FC = () => {
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const [userOptions, setUserOptions] = useState<UserOptionsType>()
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [verifiedUser, setVerifiedUser] = useState(false)
  const [user, setUser] = useState<UserType>(null)
  const history = useHistory()
  const { id } = useParams()

  const { handleSubmit, control, setValue, watch, reset, formState: { isSubmitted } } = useForm({
    mode: 'onSubmit'
  })

  const watchVerified = watch('verified')
  const watchPhotoField = watch('photo')
  const watchPhoneCodeField = watch('phonePrefix')
  const wathcMobileCodeField = watch('mobilePrefix')
  const mobileNumber = watch('mobileNumber')
  const phoneNumber = watch('phoneNumber')

  useEffect(() => {
    fetchUsersData()
  }, [])

  async function fetchUsersData() {
    setIsBusy(true)
    try {
      const response = await UsersApi.getOptionsForUsers()
      if (id) {
        const userResponse = await UsersApi.getUser(id)
        const fileResponse = await UsersApi.getUserProfile(id)
        setUserOptions(response.data)
        setUser(userResponse.data)
        setVerifiedUser(userResponse.data.verified === 1)
        reset(userResponse.data)
        setValue('photo', fileResponse.data)
      } else {
        setUserOptions(response.data)
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function handleUploadFiles(acceptedFiles: File[]) {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setValue('photo', reader.result)
    }
  }

  async function onSubmit(data) {
    if ((!mobileNumber || mobileNumber === '') || (!wathcMobileCodeField || wathcMobileCodeField === '')) {
      return
    }
    setIsBusy(true)
    try {
      if (!id) {
        await UsersApi.createUser(data)
      } else {
        await UsersApi.updateUser(id, data)
      }
      history.push(RouteTypes.USERS)
      notify(t('Successfully created/edited user'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function displayPasswordField() {
    if (id) {
      if (verifiedUser) {
        return false
      }
      return typeof (watchVerified) === 'number' ? watchVerified === 1 : watchVerified
    }
    else if (!id && watchVerified) {
      return true
    }
    return false
  }

  function displayErrorMobile() {
    if (isSubmitted) {
      return (!mobileNumber || mobileNumber === '') || (!wathcMobileCodeField || wathcMobileCodeField === '')
    }
    return false
  }

  function renderUserForm() {
    return <Form.Group>
      <Form.Field width={3}>
        {watchPhotoField && <StyledImageWrapper>
          <img src={watchPhotoField ? watchPhotoField : defaultImage}></img>
          <StyledImageContainer />
          {watchPhotoField && <Icon
            circular
            color='red'
            onClick={(e) => { e.preventDefault(); setValue('photo', null) }}
            name='trash'
            className='trashCan'
          />
          }
        </StyledImageWrapper>
        }
        {!watchPhotoField && <FileUpload
          onChange={handleUploadFiles}
        />
        }
      </Form.Field>
      <Form.Field width={6}>
        <FormFields.Input
          control={control}
          label={t('First name')}
          rules={{
            required: {
              value: true,
              message: t('Please enter first name')
            },
            maxLength: {
              value: 100,
              message: t('Max char is 100')
            }
          }}
          style={{ marginBottom: 10 }}
          name={'firstName'}
          placeholder={t('First name')}
        />
        <FormFields.Input
          control={control}
          label={t('Last name')}
          rules={{
            required: {
              value: true,
              message: t('Please enter last name')
            },
            maxLength: {
              value: 100,
              message: t('Max char is 100')
            }
          }}
          style={{ marginBottom: 10 }}
          name={'lastName'}
          placeholder={t('Last name')}
        />
        <FormFields.Input
          name='email'
          type='text'
          label={t('Email')}
          rules={{
            required: {
              value: true,
              message: t('Please enter email')
            },
            pattern: {
              value: emailRegex,
              message: t('Please enter valid email')
            }
          }}
          style={{ marginBottom: 10 }}
          autoComplete='off'
          placeholder={t('Email')}
          control={control}
        />
        {displayPasswordField() && <FormFields.Input
          control={control}
          name='password'
          label={t('Password')}
          type={visiblePassword ? 'text' : 'password'}
          icon={
            <Icon
              link
              name='eye'
              onMouseDown={() => setVisiblePassword(!visiblePassword)}
              onMouseUp={() => setVisiblePassword(!visiblePassword)}
            />
          }
          rules={{
            required: {
              value: true,
              message: t('Please enter password')
            },
            pattern: {
              value: passwordRegex,
              message: t('The password must contain at least one uppercase, lowercase letter, and at least one symbol')
            },
            minLength: {
              value: MINIMUM_LENGTH_PASSWORD,
              message: t('Password must be at least 8 characters long')
            },
            maxLength: {
              value: MAX_LENGTH_PASSWORD,
              message: t('Max character is 32')
            }
          }}
        />}
        {!verifiedUser && <FormFields.Checkbox
          name={'verified'}
          control={control}
          style={{ marginBottom: 20, marginTop: 20 }}
          label={t('Verified')}
        />
        }
      </Form.Field>
      <Form.Field width={6}>
        <>
          <Form.Field width={12} style={{ marginBottom: 10 }}>
            <label>{t('Phone number')}</label>
            <Input
              value={phoneNumber}
              label={<Dropdown
                options={userOptions?.countries.map(countryCode => ({ value: countryCode.id, text: countryCode.phoneCode }))}
                selectOnBlur={false}
                value={parseInt(watchPhoneCodeField)}
                scrolling
                basic
                placeholder={t('Code')}
                onChange={(e, data) => setValue('phonePrefix', data.value)}
                name={'phonePrefix'}
              />}
              onChange={(e, data) => {
                setValue('phoneNumber', data.value)
              }}
              labelPosition='left'
              placeholder={watchPhoneCodeField ? t('Phone number') : t('Please select phone code')}
            />
          </Form.Field>
          <Form.Field width={12} style={{ marginBottom: 10 }} required>
            <label>{t('Mobile number')}</label>
            <Input
              value={mobileNumber}
              error={isSubmitted && (!mobileNumber || mobileNumber === '')}
              label={<Dropdown
                scrolling
                basic
                error={isSubmitted && (!wathcMobileCodeField || wathcMobileCodeField === '')}
                options={userOptions?.countries.map(countryCode => ({ value: countryCode.id, text: countryCode.phoneCode }))}
                selectOnBlur={false}
                value={parseInt(wathcMobileCodeField)}
                onChange={(e, data) => setValue('mobilePrefix', data.value)}
                placeholder={t('Code')}
                name={'mobilePrefix'}
              />}
              onChange={(e, data) => setValue('mobileNumber', data.value)}
              labelPosition='left'
              placeholder={wathcMobileCodeField ? t('Mobile number') : t('Please select mobile code')}
              name={'mobileNumber'}
            />
            {displayErrorMobile() && <StyledLabel basic pointing={'above'}>{t('Please enter mobile number and mobile code')}</StyledLabel>}
          </Form.Field>
        </>
        <FormFields.Select
          label={t('Role')}
          placeholder={t('Select role')}
          control={control}
          selectOnBlur={false}
          rules={{
            required: {
              value: true,
              message: t('Please select role')
            }
          }}
          width={12}
          options={userOptions?.roles.map(role => ({ value: role.id, text: role.label }))}
          name={'roleId'}
        />
      </Form.Field>
    </Form.Group>
  }

  return <Layout
    segmented
    title={id && user ? t(`Edit user: ${user.firstName} ${user.lastName}`) : t('Create user')}
    close={RouteTypes.USERS}
    isBusy={isBusy}
    action={id && user ? t('Update') : t('Create')}
    onSubmit={handleSubmit(onSubmit)}
  >
    <Box header={t('')} fluid content={renderUserForm()}></Box>
  </Layout>
}
export default CreateEditUser
