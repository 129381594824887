import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation, TFunction } from 'react-i18next'
import { Header, Segment } from 'semantic-ui-react'

type ErrorProps = {
  code: number,
  headless: boolean,
  t: TFunction
}
class Error extends React.Component<ErrorProps> {

  getTitle(code) {
    const { t } = this.props
    if (code === 404) return t('Not Found')
    if (code === -1) return t('Application error')
    return t('Error')
  }

  getSubheader(code) {
    const { t } = this.props
    if (code === 404) return t('Page not found')
    if (code === -1) return t('General application error.')
    return t('An error happened')
  }

  render() {
    let { code, t } = this.props
    if (!code) { code = -1 }
    return <div style={{ display: 'flex' }}>
      <Segment attached padded style={{ margin: '4em', textAlign: 'center' }}>
        <Header as='h1' content={this.getTitle(code)} subheader={this.getSubheader(code)} />
        <Link to='/'>{t('Go back.')}</Link>
      </Segment>
    </div>
  }
}

export default withTranslation()(Error)
