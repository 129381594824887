import styled from 'styled-components'
import { Sidebar, Icon, Dimmer, Header, Button, Grid } from 'semantic-ui-react'

export const SidebarContainer = styled(Sidebar)`
  padding-top: 12px;
  transform: translatey(35px);
  position: relative;
  z-index: 50;
  margin-top: -3px !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  margin: 0 !important;
  margin-top: -3rem !important;

  > a.item {
    height: 47px !important;
    position: relative !important;
    margin-right: .25rem !important;
    display: flex !important;
    align-items: center !important;
    border-radius: .5rem !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    color: #FFFFFF !important;

    &.active {
      background-color: rgb(30, 58, 138) !important;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: .5rem;
        border-bottom-width: 3px;
        border-style: solid;
        border-color: rgb(0, 0, 0, .1);
        background: rgba(255, 255, 255, .08);
        height: unset;
        width: unset;
      }

      &:after {
        content: "";
        width: 20px;
        height: 80px;
        margin-bottom: -55px;
        background-image: url(../assets/menu-active-pointer.svg);
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        transform: translate(0, 0) rotate(90deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &:hover {
      background-color: #304993 !important;
      color: #FFFFFF !important;
    }
  }
`
export const SidebarIconMenu = styled(Icon)`
  z-index: 10;
  margin-top: -3px;
  z-index: 10;
`
export const SidebarTitleMenu = styled.div`
  margin-left: .75rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: -3px;
  font-size: 1.15rem;
`
export const Wrapper = styled.div`
  position: relative;

  &:before {
    content: "";
    width: 95%;
    z-index: -1;
    transform: translatey(35px);
    border-radius: 1.3rem;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: -1rem;
    background-color: rgb(255, 255, 255,  .1);
  }
`
export const WrapperBox = styled.div`
  padding-top: 80px;
  margin-top: -62px;
  transform: translatey(35px);
  display: flex;
  border-radius: 1.3rem;
  background-color: #1a3175;
`
export const LayoutStyled = styled.div`
  background-image: url(../assets/bg-main.svg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding-top: 1.25rem;
  padding-bottom: 4.25rem;
  padding-left: 2rem;
  padding-right: 2rem; 
`
export const Content = styled.div`
  padding: 0 22px;
  border-radius: 1.3rem;
  min-height: calc(100vh - 210px);
  min-width: 0;
  flex: 1 1 0%;
  background-color: rgb(241, 245, 249);
  padding-bottom: 2.5rem;
  box-shadow: 0 0 #000000, 0 0 #000000, 0 1px 2px 0 rgba(0, 0, 0, .05);
  position: relative;

  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
  .segment {
    border: none !important;
    border-radius: 10px !important;
  }
`
export const DimmerContainer = styled(Dimmer)`
  border-radius: 1.3rem;
`
export const PageHeaderContainer = styled.div`
  z-index: calc(50 - 1);
  display: flex;
  position: relative;
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
`
export const PageHeaderTitle = styled(Header)`
  line-height: 1.75rem;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 10px;
  margin-right: auto;
`
export const PageHeaderActions = styled.div`
  width: auto;
  display: flex;
  margin-top: 0px;
`
export const PageHeaderActionButton = styled(Button)`
  display: inline-flex;
  cursor: pointer;
  align-items: center !important;
  justify-content: center !important;
  border-radius: .375rem !important;
  border-width: 1px !important;
  font-size: 1rem !important;
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
  font-size: 1.15rem !important;
  font-weight: 500 !important;
  color: #FFFFFF !important;
  background-color: #1a3175 !important;
  box-shadow: 0 0 #000000, 0 0 #000000, 0 4px 6px -1px rgb(0, 0, 0, .1), 0 2px 4px -2px rgb(0, 0, 0, .1) !important;

  &:hover {
    color: #FFFFFF !important;
    background-color: rgb(26, 49, 117, .9) !important;
  }
`
export const MainGrid = styled(Grid)`
  margin-top: 1.25rem !important;
  min-height: 60vh;
`

export const PageHeaderActionBackButton = styled(Button)`
  background: transparent !important;
  margin-right: 1rem;

  :hover {
    color: #556080 !important;
  }
`

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const HeaderWrapper = styled.div`
margin-top: 20px !important;
align-items: baseline;
border: none !important;
border-radius: 10px !important;
padding: 8px !important;
`