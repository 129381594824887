import api from './api'

// Dairy

export const getDairies = (data?) =>
  api.post<DairiesType>('/dairies/index', data)

export const getDairiesList = () =>
  api.post<DairyListType[]>('/dairies/list')

export const updateDairiesVersion = (data) =>
  api.post('/dairies/mass-update-version', data)

export const getDairyFile = (id, name?: string) =>
  api.post<string>(`/dairies/${id}/get-file`, { fileName: name })

export const getDairyOptions = () =>
  api.post<DairyOptionsType>('/dairies/get-options-data')

export const createDairy = (data) =>
  api.post<DairyCreateType>('/dairies/store', data)

export const getDairy = (id: string) =>
  api.post<DairyEditType>(`/dairies/${id}/edit`, id)

export const updateDairy = (id: string, data: DairyEditType | DairyCreateType) =>
  api.post(`/dairies/${id}/update`, data)

export const getDairyLogo = (id: string) =>
  api.post<string>(`/dairies/${id}/get-logo`, id)

export const BlockDairy = (id: string) =>
  api.post(`/dairies/${id}/block`, id)

export const UnblockDairy = (id: string) =>
  api.post(`/dairies/${id}/unblock`, id)

export const DeleteDairy = (id: string) =>
  api.post(`/dairies/${id}/delete`, id)

//Dairy View

export const viewDairy = (id: string) =>
  api.post<DairyViewDataType>(`/dairies/${id}/show`, id)

// Dairy View offices

export const Offices = (id: string, data?) =>
  api.post(`/dairies/${id}/offices/index`, data)

export const OfficesOptions = (id: string) =>
  api.post(`/dairies/${id}/offices/get-options-data`)

export const OfficesStore = (id: string, data) =>
  api.post(`/dairies/${id}/offices/store`, data)

export const OfficesEdit = (idDiary: string, idOffice: string) =>
  api.post(`/dairies/${idDiary}/offices/${idOffice}/edit`)

export const OfficesUpdate = (idDiary: string, idOffice: string, data) =>
  api.post(`/dairies/${idDiary}/offices/${idOffice}/update`, data)

export const OfficesDelete = (idDiary: string, idOffice: string) =>
  api.post(`/dairies/${idDiary}/offices/${idOffice}/delete`)

// Dairy view users

export const Users = (id: string, data?) =>
  api.post(`/dairies/${id}/users/index`, data)

export const UsersOptions = (id: string) =>
  api.post(`/dairies/${id}/users/get-options-data`)

export const UsersStore = (id: string, data) =>
  api.post(`/dairies/${id}/users/store`, data)

export const UsersEdit = (idDiary: string, idUser: string) =>
  api.post(`/dairies/${idDiary}/users/${idUser}/edit`)

export const UserUpdate = (idDiary: string, idUser: string, data) =>
  api.post(`/dairies/${idDiary}/users/${idUser}/update`, data)

export const UserBlock = (idDiary: string, idUser: string) =>
  api.post(`/dairies/${idDiary}/users/${idUser}/block`)

export const UserUnblock = (idDiary: string, idUser: string) =>
  api.post(`/dairies/${idDiary}/users/${idUser}/unblock`)

export const DeleteUser = (idDiary: string, idUser: string) =>
  api.post(`/dairies/${idDiary}/users/${idUser}/delete`)

export const CheckUserEmail = (idDiary: string, data) =>
  api.post(`/dairies/${idDiary}/users/check-email`, data)

// Dairy view farmer-groups

export const FarmerGroups = (id: string, data?) =>
  api.post(`/dairies/${id}/farmer-groups/index`, data)

export const StoreFarmerGroup = (id: string, data) =>
  api.post(`/dairies/${id}/farmer-groups/store`, data)

export const OptionsFarmerGroup = (id: string) =>
  api.post(`/dairies/${id}/farmer-groups/get-options-data`)

export const ListFarmerGroup = (id: string, isOrganization?) =>
  api.post(`/dairies/${id}/farmer-groups/list`, { isOrganization: isOrganization || '' })

export const EditFarmerGroup = (idDiary: string, idFarmerGroup: string) =>
  api.post(`/dairies/${idDiary}/farmer-groups/${idFarmerGroup}/edit`)

export const getFarmerGroupFile = (idDiary: string, idFarmerGroup: string, data) =>
  api.post(`/dairies/${idDiary}/farmer-groups/${idFarmerGroup}/get-file`, data)

export const UpdateFarmerGroup = (idDiary: string, idFarmerGroup: string, data) =>
  api.post(`/dairies/${idDiary}/farmer-groups/${idFarmerGroup}/update`, data)

export const DeleteFarmerGroup = (idDiary: string, idFarmerGroup: string) =>
  api.post(`/dairies/${idDiary}/farmer-groups/${idFarmerGroup}/delete`)

export const FarmerGroupSettings = (idDiary: string, idFarmerGroup: string) =>
  api.post(`/dairies/${idDiary}/farmer-groups/${idFarmerGroup}/settings`)

export const UpdateFarmerGroupSettings = (idDiary: string, idFarmerGroup: string, data) =>
  api.post(`/dairies/${idDiary}/farmer-groups/${idFarmerGroup}/settings/store`, data)

export const CheckConnectionFarmerGroupSettings = (idDiary: string, data) =>
  api.post(`/dairies/${idDiary}/farmer-groups/settings/check-connection`, data)

// Dairy view farmers

export const FarmersList = (id: string, data?) =>
  api.post(`/dairies/${id}/farmers/list`, data)

export const FarmersIndexData = (id: string) =>
  api.post(`/dairies/${id}/farmers/get-index-data`)

export const FarmersIndex = (id: string, data: any) =>
  api.post(`/dairies/${id}/farmers/index`, data)

export const FarmersOptions = (id: string) =>
  api.post(`/dairies/${id}/farmers/get-options-data`)

export const FarmerCreate = (id: string, data) =>
  api.post(`/dairies/${id}/farmers/store`, data)

export const FarmerUpdate = (id: string, idFarmer: string, data) =>
  api.post(`/dairies/${id}/farmers/${idFarmer}/update`, data)

export const FarmerEdit = (id: string, idFarmer: string) =>
  api.post(`/dairies/${id}/farmers/${idFarmer}/edit`)

export const FarmerFile = (id: string, idFarmer: string) =>
  api.post(`/dairies/${id}/farmers/${idFarmer}/get-file`)

export const FarmerShow = (id: string, idFarmer: string) =>
  api.post(`/dairies/${id}/farmers/${idFarmer}/show`)


// Dairy view ife

export const GetIfeSettings = (id: string) =>
  api.post(`/dairies/${id}/ife-settings/edit`)

export const UpdateIfeSettings = (id: string, data) =>
  api.post(`/dairies/${id}/ife-settings/update`, data)

// Dairy view Milk money

export const GetMilkMoneyFtps = (id: string) =>
  api.post(`/dairies/${id}/settings/milk-money/ftp/edit`)

export const UpdateMilkMoneyFtps = (id: string, data) =>
  api.post(`/dairies/${id}/settings/milk-money/ftp/update`, data)

export const CheckFtpConnection = (id: string, data) =>
  api.post(`/dairies/${id}/settings/milk-money/ftp/check-connection`, data)

export const GetMilkMoneyFtpsOptions = (id: string) =>
  api.post(`/dairies/${id}/settings/milk-money/ftp/get-options-data`)

// Dairy view StoneX accounts

export const GetStoneXAccounts = (id: string, data) =>
  api.post(`/dairies/${id}/stonex-accounts/index`, data)

export const CheckConnectionStoneXAccount = (id: string, data) =>
  api.post(`/dairies/${id}/stonex-accounts/check-connection`, data)

export const StoreStoneXAccount = (id: string, data: any) =>
  api.post(`/dairies/${id}/stonex-accounts/store`, data)

export const EditStoneXAccount = (id: string, idAccount: any) =>
  api.post(`/dairies/${id}/stonex-accounts/${idAccount}/edit`)

export const DeleteStoneXAccount = (id: string, idAccount: any) =>
  api.post(`/dairies/${id}/stonex-accounts/${idAccount}/delete`)

export const ShowStoneXAccount = (id: string, idAccount: any) =>
  api.post(`/dairies/${id}/stonex-accounts/${idAccount}/show`)

export const UpdateStoneXAccount = (id: string, idAccount: any, data) =>
  api.post(`/dairies/${id}/stonex-accounts/${idAccount}/update`, data)

export const OptionsStoneXAccount = (id: string) =>
  api.post(`/dairies/${id}/stonex-accounts/get-options-data`)

// Dairy view Husbandry forms

export const getHusbandryForms = (id, data?) =>
  api.post(`/dairies/${id}/husbandry-forms/index`, data);

export const editHusbandryForm = (id: string, idHusbandry) =>
  api.post(`/dairies/${id}/husbandry-forms/${idHusbandry}/edit`);

export const createHusbandryForm = async (id, data) =>
  api.post(`/dairies/${id}/husbandry-forms/store`, data);

export const deleteHusbandryForm = async (id, idHusbandry) =>
  api.post(`/dairies/${id}/husbandry-forms/${idHusbandry}/delete`);

export const updateHusbandryForm = async (id, idHusbandry, data) =>
  api.post(`/dairies/${id}/husbandry-forms/${idHusbandry}/update`, data);

// Dairy view Emails
export const getEmailsOptions = (id: string) =>
  api.post(`/dairies/${id}/emails/get-index-data`);

export const getEmails = (id: string, data?) =>
  api.post(`/dairies/${id}/emails/index`, data);

export interface DairyViewDataType {
  companyName: string
  cover: string
  createdAt: string
  logo: string
  modules: ModuleDairyType[]
  status: number
  subdomain: string
  stonexEnabled: boolean | number
}

export type DairyRows = {
  id: string
  companyName: string
  subdomain: string
  streetName?: string
  streetNumber?: string
  city?: string
  zip?: number
  countryLabel?: number
  phonePrefixCountryPhoneCode?: string
  phoneNumber?: string
  officeEmail?: string
  status: 0 | 1 | boolean
  url: string
  managingStatus: number
  managingStatusLabel: string,
  updateStatus: number,
  updateStatusLabel: string,
  updateLogVersion?: string | null,
  updateLogDate?: Date | null,
  updateLogId?: number | null
}

interface DairiesType {
  rows: DairyRows[],
  total: number,
  filtered: number
}

export type DairyListType = {
  id: string,
  companyName: string,
  subdomain: string
}

export type DairiesUpdateVersionType = {
  dairies: Array<string>,
  isNew: 0 | 1
}

export type DairyLanguageType = {
  id: number,
  code: string,
  label: string
}

type ModuleDairyChildrenType = {
  id: number,
  slug: string,
  parent_id: string,
  label: string
}

export type ModuleDairyType = {
  id: number,
  slug: string,
  children?: ModuleDairyChildrenType[],
  label: string
}

type CountryDairyType = {
  id: number,
  iso: string,
  phoneCode: string,
  label: string
}

export type FtpsDairyType = {
  key: string,
  label: string
}

type AmountPrices = {
  key: string,
  label: string
}

type FarmerAmountType = {
  key: string,
  label: string
}

type OfficeTypeDairyOptions = {
  key: string,
  label: string
}
export interface DairyOptionsType {
  amountPrices: AmountPrices[]
  amountUnits: string[]
  amountVolumes: string[]
  countries: CountryDairyType[]
  farmerAmountTypes: FarmerAmountType[]
  ftps: FtpsDairyType[]
  languages: DairyLanguageType[]
  modules: ModuleDairyType[]
  officeTypes: OfficeTypeDairyOptions[]
  timezones: string[]
  instanceTypes: string[]
}

export type OfficeType = {
  name: string,
  type: string,
  officeEmail: string,
  streetName: string,
  streetNumber: string,
  city: string,
  zip: number,
  countryId: string,
  phonePrefix: string,
  phoneNumber: string,
  telefaxPrefix: string,
  telefaxNumber: string,
  ftps: string[]
}

type emailSuffixObjectType = {
  suffix: string
}

export interface DairyEditType {
  companyName: string,
  languageId: string,
  languages: string[],
  subdomain: string,
  headerTitle: string,
  emailHeader: string,
  emailSuffix: string[] | emailSuffixObjectType[],
  email: string,
  emailFromName: string,
  newsletterEmail: string,
  newsletterEmailFromName: string,
  modules: string[],
  firstColor: string,
  secondColor: string,
  chartBarColor: string,
  chartBarColor2: string,
  chartLineColor: string,
  logo: string,
  cover: string,
  headIcon32: string,
  isTest: number | boolean,
  farmerAmountType: string,
  amountUnit: string,
  amountVolume: number,
  amountPrice: string,
  smsActive: number | boolean,
  texts: TextType[]
}

type TextType = {
  slug: string,
  label: string,
  languages: TextLanguageType[]
}

type TextLanguageType = {
  id: number,
  translation: string
}

export type DairyCreateType = DairyEditType & OfficeType
