import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Accordion, Button, Form, Icon, Popup, Transition } from 'semantic-ui-react'
import { emailRegex } from '~/Constants'
import { FormFields } from '~/components/FormFields'
import { TimeInput } from 'semantic-ui-calendar-react';
import { StyledAccordion } from '../FarmerGroups/Settings/style'
import { CheckFtpConnection } from '~/services/dairies'
import { useParams } from 'react-router-dom'
import notify from '~/components/notify'
import { AccordionTittleWrapper, StyledLoader, StyledTitle } from './style'
import CustomIcon from '~/components/CustomIcon'
import { FormDataType, FtpKeys } from '.'

interface TestRingFtpsProps {
  name: FtpKeys
  options: any
  index: number
  handleRecipientsHeaderClick(e: any, titleProps: any): void
  activeFtp: number
  title: string
  removeScheduleTime?: boolean
}

const TestRingFtps: React.FC<TestRingFtpsProps> = (props) => {
  const {
    name,
    options,
    index,
    handleRecipientsHeaderClick,
    activeFtp,
    removeScheduleTime,
  } = props

  const { control, setValue, getValues } = useFormContext<FormDataType>()
  const { t } = useTranslation()
  const { id } = useParams()

  const [loadingConnection, setLoadingConnection] = useState(false)
  const [connection, setConnection] = useState(false)

  const notificationEmails = useFieldArray({
    control,
    //@ts-ignore
    name: `${name}.notificationEmails`
  });

  //@ts-ignore
  const watchTime = useWatch({ control, name: `${name}.schedulerTime` })

  //@ts-ignore
  const watchNotificatonStatus = useWatch({ control, name: `${name}.notificationStatus` })
  //@ts-ignore
  const watchScheduledActiveField = useWatch({ control, name: `${name}.schedulerActive` })

  useEffect(() => {
    //@ts-ignore
    const testedConnection = getValues(`ftps[${index}].connection`);
    if (name && testedConnection === undefined) {
      checkConnection()
    }
  }, [])

  async function checkConnection() {
    const {
      protocol,
      ftpIp,
      ftpPort,
      ftpUser,
      ftpPassword
    } = getValues(name)

    setLoadingConnection(true)
    try {
      const response = await CheckFtpConnection(id, {
        protocol,
        ftpIp,
        ftpPort,
        ftpUser,
        ftpPassword
      })
      //@ts-ignore
      setValue(`${name}.connection`, Boolean(response.data))
      setConnection(Boolean(response.data))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setLoadingConnection(false)
  }

  function renderFtpContent() {
    const optionsScheduler = typeof (removeScheduleTime) === 'boolean' ? options.days : options.daysOfMonth
    const labelScheduler = typeof (removeScheduleTime) === 'boolean' ? t('Scheduler days') : t(' Day of the month')
    let ftpsName: string
    ftpsName = name
    return <>
      <FormFields.Checkbox
        //@ts-ignore
        name={`${ftpsName}.schedulerActive`}
        control={control}
        defaultValue={0}
        style={{ marginTop: '1em' }}
        checkboxValue={1}
        label={t('Schedule active')}
      />
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        {!removeScheduleTime && <FormFields.Select
          control={control}
          options={optionsScheduler?.map(option => ({ value: option.key.toString(), text: option.label })) || []}
          selection
          placeholder={labelScheduler}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please select any option')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.schedulerDays`}
          multiple
          label={labelScheduler}
          selectOnBlur={false}
        />
        }
        {!removeScheduleTime && <Controller
          control={control}
          //@ts-ignore
          name={`${name}.schedulerTime`}
          rules={{
            //@ts-ignore
            required: watchScheduledActiveField ? t('Please select time') : false
          }}
          render={() => (
            <Form.Field required={Boolean(watchScheduledActiveField)}>
              <label>{t('Exporting time')}</label>
              <TimeInput
                name='time'
                placeholder='Select time'
                //@ts-ignore
                value={watchTime}
                iconPosition='left'
                //@ts-ignore
                onChange={(_, { value }) => setValue(`${name}.schedulerTime`, value === '' ? null : value)}
                popupPosition='bottom left'
                closable
              />
            </Form.Field>
          )}
        />
        }
      </Form.Group>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Select
          control={control}
          options={options?.protocols.map((protocol, index) => ({ value: index, text: protocol }))}
          selection
          placeholder={t('Select FTPS')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please select protocol')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.protocol`}
          label={t('FTPS protocol')}
          selectOnBlur={false}
        />
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.ftpIp`}
          label={t('IP address')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter ip adress')
            }
          } : null}
          placeholder={t('Enter ip adress')}
        />
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.ftpPort`}
          label={t('Port')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter port')
            }
          } : null}
          placeholder={t('Enter port')}
        />
      </Form.Group>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.ftpUser`}
          autoComplete='off'
          label={t('User')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter user')
            }
          } : undefined}
          placeholder={t('Enter user')}
        />
        <FormFields.Input
          control={control}
          type={'password'}
          autoComplete='off'
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter password')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.ftpPassword`}
          label={t('Password')}
          placeholder={t('Enter password')}
        />
      </Form.Group>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.folder`}
          label={t('Folder')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter folder name')
            }
          } : null}
          placeholder={t('Enter folder name')}
        />
      </Form.Group>
      <FormFields.Checkbox
        control={control}
        //@ts-ignore
        name={`${ftpsName}.notificationStatus`}
        label={t('Notification status')}
        defaultValue={0}
        checkboxValue={1}
      />
      <Form.Field
        required={Boolean(watchNotificatonStatus)}
        style={{ display: 'flex', alignItems: 'baseline' }}
      >
        <label style={{ marginRight: 10 }}>{t('Notification emails')}</label>
        <Popup
          content={t('Add new notification email')}
          trigger={
            <Button
              onClick={() => notificationEmails.append({ email: '' })}
              primary
              size='mini'
              type={'button'}
              icon='plus'
            />
          }
        />
      </Form.Field>
      <>
        {notificationEmails.fields.map((notificationEmail, notificationEmailIndex) => {
          return <Form.Group key={notificationEmail.id}>
            <FormFields.Input
              control={control}
              width={12}
              placeholder={t('Email')}
              label={t('New email')}
              autoComplete='nofill'
              //@ts-ignore
              name={`${ftpsName}.notificationEmails[${notificationEmailIndex}].email`}
              rules={{
                required: {
                  value: true,
                  message: t('Please enter email')
                },
                pattern: {
                  value: emailRegex,
                  message: t('Please enter valid email')
                }
              }}
            />
            <Form.Button
              style={{ marginLeft: 10, marginTop: 24 }}
              onClick={() => notificationEmails.remove(notificationEmailIndex)}
              size={'small'}
              basic
              color='red'
              type={'button'}
              icon='trash'
            />
          </Form.Group>
        })}
      </>
    </>
  }

  function renderRefreshConnection() {
    return <Popup
      content={t('Check connection')}
      trigger={<CustomIcon
        name='sync'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          checkConnection()
        }} />}
    />
  }

  const active = activeFtp === index

  return <StyledAccordion styled key={name}>
    <Accordion.Title
      index={index}
      active={active}
      onClick={handleRecipientsHeaderClick}
    >
      <AccordionTittleWrapper>
        <StyledTitle color={loadingConnection ? 'black' : connection ? 'green' : 'red'}>
          {!loadingConnection ? renderRefreshConnection() : <StyledLoader active={loadingConnection} />}
          <div>
            {`FTP - ${props.title}`}
          </div>
        </StyledTitle>
        <Icon name={active ? 'minus' : 'plus'} />
      </AccordionTittleWrapper>
    </Accordion.Title>
    <Transition visible={active} animation='slide down' duration={200}>
      <Accordion.Content
        active={active}
        content={renderFtpContent()}
      />
    </Transition>
  </StyledAccordion>
}
export default TestRingFtps