import React, { useEffect } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { CheckboxProps, Form } from 'semantic-ui-react';
import { getLabel, getError } from './util';

interface CheckboxFormFiled extends UseControllerProps {
  label?: string | JSX.Element;
  value?: boolean;
  control: any;
  checkboxValue?: any;
}

type CheckboxFormFiledProps = CheckboxFormFiled & CheckboxProps;

const Checkbox: React.FC<CheckboxFormFiledProps> = (props) => {
  const { field, fieldState } = useController(props);
  const { control, checkboxValue, label, rules, value, defaultValue, ...checkboxProps } = props;

  function notInViewport(element: any, offset = 0) {
    if (!element) return false;
    const top = element.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset >= window.innerHeight;
  }

  useEffect(() => {
    if (fieldState.error) {
      const element = document.getElementById(field.name);
      if (notInViewport(element)) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [fieldState]);

  function getValue(data: any) {
    if (checkboxValue) {
      if (data.checked) {
        return checkboxValue;
      }
      return 0;
    }
    return data.checked;
  }

  return (
    <Form.Checkbox
      id={field.name + props.id}
      {...checkboxProps}
      error={fieldState.error ? getError(fieldState) : false}
      label={getLabel(label, rules)}
      onBlur={field.onBlur}
      onChange={(e, data) => field.onChange(getValue(data))}
      checked={checkboxValue && field.value ? checkboxValue === field.value : !!field.value}
    />
  );
};
export default Checkbox;
