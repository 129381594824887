import React from 'react'
import {toast} from 'react-toastify'

const theme = 'colored'

function notify(messages,type) {
  if(typeof messages === 'string') {
    toast(messages, {type, theme})
  } else {
    if(messages.length) {
      toast(<div>
        {messages.map((msg,i) => <p key={i}>{msg}</p>)}
      </div>, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type,
        theme
      })
    }
  }
}

export default notify
