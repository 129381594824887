import { createGlobalStyle } from 'styled-components'

export const theme = {
  colors: {
    primary: '#3D315B',
    secondary: '#444B6E',
    background: '#708B75',
    link: '#9AB87A',
    white: '#FFFFFF'
  },
  font: {
    size: {
      extraSmall: '14px',
      small: '16px',
      medium: '18px',
      large: '20px',
      extraLarge: '24px'
    }
  },
  breakpoint: {
    mobileS: '320px',
    mobileM: '640px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1280px'
  }
}

export const GlobalStyle = createGlobalStyle`
  *, ::before, ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  body {
    margin: 0;
    line-height: 1.25rem;
  }

  a {
    color: inherit;
    font-family: "Nunito Sans" !important;
    text-decoration: inherit;
  }

  html {
    background-color: #1e3a8a;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .draggable {
  top: auto !important;
  left: auto !important;
}

  input[type=number]{
    -moz-appearance: textfield;
  }

  .remove.link.icon {
    opacity: 1 !important;
  }

  .remove.link.icon::before {
    color: red !important;
    opacity: 1 !important;
  }

  .calendar.icon {
    opacity: 1 !important;
  }

  .calendar.icon::before {
    color: #044395 !important;
  }

  html body {
    overflow-x: hidden;
    background: transparent;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #1e293b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
    font-family: "Nunito Sans" !important;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    margin: 0;
    font-family: "Nunito Sans" !important;
    padding: 0;
  }

  button,
  select {
    text-transform: none;
  }

  :-moz-focusring {
    outline: auto;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
    font-family: "Nunito Sans" !important;
  }

  div {    
    font-family: "Nunito Sans" !important;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }
  
  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: "Nunito Sans" !important;
  }

  .ui.form .field>label {
    margin-bottom: .5rem;
    color: #5e6672;
    font-family: "Nunito Sans" !important;
    font-size: 1rem;
  }

  .table-large-icons {
    font-size: 1.2em !important;
  }

  /*** Loader ***/
  .milk-can-loader {
    position: absolute;
    top: 40%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -40%);

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      width: 1.9rem;
      max-height: 25px;
      bottom: 5px;
      transform: translate(-50%, 0);
      background-image: linear-gradient(#f7f0d2, #f7f0d2);
      animation: animateBg 3s cubic-bezier(.2, .6, .8, .4) infinite;
    }

    .milk-can-color {
      fill: #1a3175;
    }

    svg {
      width: 5rem;
      height: 5rem;
    }
    
    @keyframes animateBg {
      0% {
        height: 0;
      }
      100% {
        height: 100%;
      }
    }
  }
`
