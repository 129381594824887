import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'
import notify from '~/components/notify'
import { deleteSftpAccount, getSftpAccounts, getSftpOptions } from '~/services/sftpAccounts'
import i18n from '~/components/i18n'
import TableComponent from '~/components/TableComponent'
import { RouteTypes } from '~/Constants'
import Dialog from '~/components/Dialog'
import { Icon, Input, Label } from 'semantic-ui-react'
import { useDebounce } from 'use-lodash-debounce'

const defaultTableColumns = [
  {
    name: i18n.t('Name'),
    key: 'name',
    sorted: 'desc'
  },
  {
    name: i18n.t('Username'),
    key: 'username',
    sorted: 'desc'
  },
  {
    name: i18n.t('Password'),
    key: 'password'
  },
  {
    name: i18n.t('Created at'),
    key: 'createdAt',
    type: 'date',
    format: 'DD.MM.YYYY',
    sorted: 'desc'
  },
  {
    name: i18n.t('Folders'),
    key: 'folders',
    type: 'folders'
  },
  {
    name: i18n.t('Actions')
  }
]

const actions = [
  {
    popupMessage: i18n.t('Edit'),
    type: 'link',
    name: 'pencil',
    size: 'large',
    link: RouteTypes.SFTP_ACCOUNTS_EDIT
  },
  {
    popupMessage: i18n.t('Delete'),
    type: 'icon',
    color: 'red',
    name: 'trash',
    size: 'large',
    actionType: 'delete'
  }
]

const SftpAccounts: React.FC = () => {
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const [options, setOptions] = useState(null)
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 300)
  const [deleteSftp, setDeleteSftp] = useState(null)
  const [sftpAccountsRows, setSftpAccountsRows] = useState([])
  const [total, setTotal] = useState(0)
  const [dataFiltered, setDataFiltered] = useState({
    rows: 10,
    page: 0,
    search: ''
  })
  const [tableColumns, setTableColumns] = useState(defaultTableColumns)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (options) {
      fetchFilteredData()
    }
  }, [dataFiltered])

  useEffect(() => {
    setDataFiltered({ ...dataFiltered, search: debouncedValue, page: 0 })
  }, [debouncedValue])

  async function fetchFilteredData() {
    setIsBusy(true)
    try {
      const response = await getSftpAccounts({ ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })
      setSftpAccountsRows(response.data.rows)
      setTotal(response.data.filtered)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await getSftpAccounts()
      const responseOptions = await getSftpOptions()
      setSftpAccountsRows(response.data.rows)
      setTotal(response.data.filtered)
      setOptions(responseOptions.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function handleClickAction(row, type) {
    switch (type) {
      case 'delete':
        return setDeleteSftp(row)
      default:
        return <></>
    }
  }

  function renderTable() {
    return <TableComponent
      tableColumnHeaders={tableColumns}
      rows={sftpAccountsRows}
      actions={actions}
      dataFiltered={dataFiltered}
      handleClickAction={(row, type) => handleClickAction(row, type)}
      handleChangeDataFiltered={(data, tableColumnHeaders) => {
        setTableColumns(tableColumnHeaders)
        setDataFiltered(data)
      }}
      total={total}
    />
  }

  async function handleDeleteSftp(id) {
    setIsBusy(true)
    try {
      await deleteSftpAccount(id)
      setDeleteSftp(null)
      setSftpAccountsRows(sftpAccountsRows.filter(row => row.id !== id))
      setTotal(total - 1)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderDeleteModal() {
    const name = deleteSftp?.name || ''
    return <Dialog
      open={Boolean(deleteSftp)}
      textHeader={t('Delete SFTP account: {{name}}', { name })}
      handleClose={() => setDeleteSftp(null)}
      handleSubmit={() => handleDeleteSftp(deleteSftp?.id)}
      textContent={<>{t('Do you want to delete SFTP account {{name}}?', { name })}</>}
      textSubmit={t('Delete')}
      textClose={t('Close')}
      type={'abort'}
    />
  }

  function renderFilters() {
    return <Input
      icon={{ name: 'search', circular: true, link: true }}
      placeholder={t('Search...')}
      value={search}
      onChange={(e, data) => setSearch(data.value)}
    />
  }

  function renderHostAndPort() {
    return <div style={{ display: 'flex', alignItems: 'baseline', marginLeft: 20 }}>
      <Label size='large' color='blue'>
        <Icon name='cloud' />
        {t('Host')}:
        <Label.Detail>{options?.host}</Label.Detail>
      </Label>
      <Label size='large' color='blue'>
        <Icon name='fork' />
        {t('Port')}:
        <Label.Detail>{options?.port}</Label.Detail>
      </Label>
    </div>
  }

  return <Layout
    title={t('SFTP accounts')}
    isBusy={isBusy}
    filters={renderFilters()}
    segmented
    actionName={'Create'}
    action={RouteTypes.SFTP_ACCOUNTS_CREATE}
  >
    {options && renderHostAndPort()}
    {deleteSftp && renderDeleteModal()}
    {options && !isBusy && renderTable()}
  </Layout>
}
export default SftpAccounts