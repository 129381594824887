
import { Segment, Header, Form } from 'semantic-ui-react'
import React, { ReactElement } from 'react'
import { HeaderDiv, ActionsDiv, StyledViewDairyFooter, StyledSegment } from './style'
import { DimmerContainer } from '~/components/Layout/style'
import LoaderMilkCan from '~/components/Loader'
import styled from 'styled-components'

type DairyViewTabsLayoutProps = {
  header: string,
  actions?: any,
  children: any,
  isBusy?: boolean,
  search?: ReactElement,
  onSubmit?: () => void,
  segmented?: boolean
}

const HeaderWrapper = styled.div`
display: flex;
align-items: baseline;
border: none !important;
border-radius: 10px !important;
padding: 8px !important;
`

const ChildWrapper = styled.div`
min-height: 60vh;
position: relative; 
padding: 0px 20px;
border: none !important;
border-radius: 10px !important;
`

const DairyViewTabsLayout = (props: DairyViewTabsLayoutProps) => <StyledViewDairyFooter
  onSubmit={props.onSubmit}
  as={props.onSubmit ? Form : Segment}
  basic
  autoComplete='off'
>
  <div style={{ border: 'none', padding: 0 }} >
    <HeaderDiv as={props.segmented ? StyledSegment : 'div'}>
      <HeaderWrapper>
        <Header style={{ marginRight: 10 }} as='h3' content={props.header} />
        {props.search}
      </HeaderWrapper>
      {props.actions && <ActionsDiv>
        {props.actions}
      </ActionsDiv>}
    </HeaderDiv>
  </div>
  <ChildWrapper as={props.segmented ? Segment : 'div'}>
    <DimmerContainer inverted active={props.isBusy}>
      <LoaderMilkCan isLoading={props.isBusy} />
    </DimmerContainer>
    {props.children}
  </ChildWrapper>
</StyledViewDairyFooter>

export default DairyViewTabsLayout
