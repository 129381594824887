import React from 'react'
import { Dropdown, Flag, FlagNameValues } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import { ProfileApi } from '~/services'
import notify from '~/components/notify'
import { useTranslation } from 'react-i18next'
import i18n from '~/components/i18n'

const Languages: React.FC = () => {
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const { t } = useTranslation()
  const language = systemData?.languages.find(language => language.id === systemData.logged.languageId)
  const languageOptions = systemData?.languages.filter(language => language.code === 'en' || language.code === 'de')

  async function handleLanguageChange(languageId: number, languageCode: string) {
    try {
      await ProfileApi.languageUpdate(languageId)
      i18n.changeLanguage(languageCode)
      window.location.reload()
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
  }

  function getFlagName(flag = null) {
    const flagName = flag || language?.code
    switch (flagName) {
      case 'en':
        return 'gb'
      case 'fr_BE':
        return 'be'
      default:
        return flagName
    }
  }

  return <Dropdown
    icon={null}
    //@ts-ignore semantic type error
    text={<div style={{ color: 'white', display: 'flex' }}>
      <Flag name={getFlagName() as FlagNameValues} />
      {language?.label}
    </div>}
  >
    <Dropdown.Menu>
      {languageOptions.map(language => {
        return <Dropdown.Item
          key={language.id}
          value={systemData?.logged.languageId}
          flag={getFlagName(language.code)}
          text={language.label}
          onClick={() => handleLanguageChange(language.id, language.code)}
        />
      })}
    </Dropdown.Menu>
  </Dropdown>
}
export default Languages
