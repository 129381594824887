import React, { useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Form, Icon } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { emailRegex } from '~/Constants'

const EmailNewsletter = (props) => {
  const { control, setValue } = props
  const { t } = useTranslation()

  const watchEmailSuffix = useWatch({ control: control, name: 'emailSuffix' });
  const watchEmail = useWatch({ control, name: 'email' });
  const emailPrefix = watchEmail?.split('@')[0];
  const emailSuffix = `@${watchEmail?.split('@')[1]}`;
  const watchNewsletterEmail = useWatch({ control, name: 'newsletterEmail' });
  const newsletterEmailPrefix = watchNewsletterEmail?.split('@')[0];
  const newsletterEmailSuffix = `@${watchNewsletterEmail?.split('@')[1]}`;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emailSuffix'
  });

  useEffect(() => {
    if (fields.length === 0) {
      addSuffix();
    }
  }, [watchEmailSuffix]);

  function addSuffix() {
    append({ suffix: 'default-suffix' });
  }

  return <>
    <Form.Field inline required>
      <label>{t('Email Sufixes')}</label>
      <Button type='button' compact icon onClick={addSuffix} size='tiny'>
        <Icon name='plus' />
      </Button>
    </Form.Field>
    <Form.Field style={{ width: '50%' }}>
      {fields.map((emailSuffix, index) => (
        <Form.Group key={emailSuffix.id} style={{ alignItems: 'center' }}>
          <FormFields.Input
            //@ts-ignore
            name={`emailSuffix[${index}].suffix`}
            type='text'
            style={{ width: 400 }}
            size='large'
            rules={{
              required: {
                value: true,
                message: t('Please enter email suffix')
              }
            }}
            placeholder={t('Email suffix')}
            control={control}
          />
          {fields.length > 1 && <Button
            style={{ marginLeft: 10 }}
            onClick={() => remove(index)}
            size={'small'}
            basic
            color='red'
            type={'button'}
            icon='trash' />}
        </Form.Group>
      ))}
    </Form.Field>
    <FormFields.Input
      control={control}
      placeholder={t('Email Header')}
      name={'emailHeader'}
      rules={{
        required: {
          value: true,
          message: t('Please enter email header')
        },
        maxLength: {
          value: 150,
          message: t('Max char is 150')
        }
      }}
      label={t('Email Header')}
    />
    <Form.Group widths={'equal'}>
      <FormFields.Input
        control={control}
        name={'emailFromName'}
        label={t('Email sender name')}
        placeholder={t('Email sender name')}
        rules={{
          required: {
            value: true,
            message: t('Please enter email sender name')
          },
          maxLength: {
            value: 50,
            message: t('Max character number is 50')
          }
        }}
      />
      <Form.Input
        control={control}
        value={emailPrefix}
        onChange={(e, data) => setValue('email', `${data.value}${emailSuffix}`)}
        rules={{
          required: {
            value: true,
            message: t('Please enter email')
          },
          maxLength: {
            value: 100,
            message: t('Max character number is 100')
          },
          pattern: {
            value: emailRegex,
            message: t('Please enter valid email')
          }
        }}
        placeholder={t('Email')}
        label={t('Email')}
      />
      <Form.Select
        style={{ marginTop: 24 }}
        placeholder={t('Select suffix')}
        control={control}
        selectOnBlur={false}
        value={emailSuffix}
        options={watchEmailSuffix?.map(field => ({ value: `@${field.suffix}`, text: `@${field.suffix}` }))}
        onChange={(e, data) => setValue('email', `${emailPrefix}${data.value}`)}
      />
    </Form.Group>
    <Form.Group widths={'equal'}>
      <FormFields.Input
        control={control}
        name={'newsletterEmailFromName'}
        label={t('Newsletter email sender name')}
        placeholder={t('Newsletter email sender name')}
        rules={{
          required: {
            value: true,
            message: t('Please enter newsletter email sender name')
          },
          maxLength: {
            value: 50,
            message: t('Max character number is 50')
          }
        }}
      />
      <Form.Input
        control={control}
        value={newsletterEmailPrefix}
        onChange={(e, data) => setValue('newsletterEmail', `${data.value}${newsletterEmailSuffix}`)}
        rules={{
          required: {
            value: true,
            message: t('Please enter newsletter email')
          },
          maxLength: {
            value: 100,
            message: t('Max character number is 100')
          },
          pattern: {
            value: emailRegex,
            message: t('Please enter valid email')
          }
        }}
        placeholder={t('Newsletter email')}
        label={t('Newsletter email')}
      />
      <Form.Select
        style={{ marginTop: 24 }}
        placeholder={t('Select suffix')}
        control={control}
        selectOnBlur={false}
        value={newsletterEmailSuffix}
        options={watchEmailSuffix?.map(field => ({ value: `@${field.suffix}`, text: `@${field.suffix}` })) || []}
        onChange={(e, data) => setValue('newsletterEmail', `${newsletterEmailPrefix}${data.value}`)}
      />
    </Form.Group>
  </>
}
export default EmailNewsletter
