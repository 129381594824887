import styled from 'styled-components'

export const StyledImageWrapper = styled.div`
display: inline-block; 
position: relative;
&:hover .trashCan {
  box-shadow: 2px 3px #888888 !important;
  display: -webkit-box !important;
}
img{
  left: 0;
  width: 140px;
  right: 0;
  margin-right: auto;
  object-fit: cover;
  height: 140px;
  border-radius: 50% !important;  
}
&:hover img {
  transform: scaleX(1.05) scaleY(1.05);
  box-shadow:  0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.trashCan {
  cursor: pointer; 
  display: none;
  font-size: 1.3em;
  z-index: 101;
  position: absolute;
  top: 5px; 
  right: 3px;
  background: white;
  
}
i.circular.icon {
  padding-left: 11px !important;
}
`
export const StyledImageContainer = styled.div`
max-width: 150px;
width: auto;
position: absolute;
width: 140px;
z-index: 100;
box-shadow: 10px 10px #888888 !important;

top: 0;
-webkit-transition: all 0.2s;
left: 0;
right: 0;
margin-right: auto;
height: 140px;
object-fit: cover;
border-radius: 50%;
`
