const ENVIRONMENT = {
  API_URL: 'https://admin-api-development.foodbusiness.services/api',
  language: 'de'
}
// Include local or production environment
let EXTERNAL_ENVIRONMENT = {}
try {
  const env = process.env.NODE_ENV === 'production' ? 'production' : 'local'
  EXTERNAL_ENVIRONMENT = require('./environment.' + env + '.js')
  //later define production environment (env === 'production' ? EXTERNAL_ENVIRONMENT )
  EXTERNAL_ENVIRONMENT = EXTERNAL_ENVIRONMENT['default']
} catch (e) { /**/ }

export default {
  ...ENVIRONMENT,
  ...EXTERNAL_ENVIRONMENT
}
