import styled, {css} from 'styled-components'

export const LoginFormWrapper = styled.div`
  ${({theme}) => css`
    position: relative;
    overflow: hidden;
    background-color: ${theme.colors.white};

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      margin-top: -20%;
      margin-bottom: -12%;
      margin-left: -13%;
      width: 57%;
      border-radius: 100%;
      transform: translate(0, 0) rotate(-4deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
      background: url('../assets/bg-auth.jpg') no-repeat center #eee;
      background-size: 125%;
      background-position: -50% 65%;  
    }

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      margin-top: -28%;
      margin-bottom: -15%;
      margin-left: -13%;
      width: 57%;
      transform: translate(0, 0) rotate(-4deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
      border-radius: 100%;
      background: #d2d8e8;
    }

    .ui.form .field, .ui.form .error {
      margin-bottom: 0;
      position: relative;

      .prompt.label {
        position: absolute;
        left: 0;
        bottom: -0.75rem;
      }
    }
  `}
`
export const FormHeading = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`
export const FormParagraph = styled.p`
  font-size: 1.15rem;
`
export const FormLogo = styled.h1`
  font-size: 2.25rem;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  font-weight: 600;
`
export const FormTermsAndPrivacy = styled.a`
  margin-top: 6rem;
  text-align: left;
  display: block;
`

export const loginForm = {
  marginLeft: '5rem',
  marginRight: 'auto',
  marginTop: 'auto',
  marginBottom: 'auto',
  width: 'auto',
  padding: '0'
}

export const loginFormField = {
  marginTop: '1rem',
  marginBottom: '1.75rem',
  position: 'relative',
  minWidth: '350px',
  lineHeight: '1.25rem'
}

export const loginFormRememberFieldWrapper = {
  display: 'flex',
  marginTop: '1rem',
  fontSize: '1rem',
  alignItems: 'center'
}

export const loginFormRememberField = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto'
}

export const loginFormForgotPassword = {
  cursor: 'pointer',
  background: 'transparent',
  marginLeft: 'auto',
  border: 'none',
  fontSize: '1rem'
}

export const loginFormSubmitWrapper = {
  display: 'flex',
  marginTop: '1rem'
}

export const loginFormSubmitButton = {
  justifyContent: 'center',
  borderRadius: '.375rem',
  borderWidth: '1px',
  fontWeight: 500,
  paddingTop: '1rem',
  paddingBottom: '1rem',
  minWidth: '8rem',
  paddingLeft: '1.2rem',
  paddingRight: '1.2rem',
  fontSize: '1.2rem'
}

export const loginFormBackIcon = {
  cursor: 'pointer',
  marginRight: '0.75rem'
}