import dayjs from 'dayjs'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFields } from '~/components/FormFields'
import { capitalizeFirstLetter } from '~/util'

interface AmountFarmerGroupProps {
  farmerGroupList: any[]
  farmersOptions: any[]
}

const AmountFarmerGroup: React.FC<AmountFarmerGroupProps> = (props) => {
  const { farmerGroupList, farmersOptions } = props

  const { t } = useTranslation()
  const amountUnit = 'kg'

  const { control } = useFormContext()
  const watchAmountType = useWatch({ control, name: 'amountType' })
  const wholeNumberErrorMessage = t('Please enter whole number')
  const watchFarmerType = useWatch({ control, name: 'farmerType' })

  function renderBackwardsMonths(lastMonthIndex, arrayOfMonths) {
    const inputFields = []
    for (let i = lastMonthIndex, j = 0; i >= 0; i--, j++) {
      inputFields.push(<FormFields.Input
        control={control}
        typeInput={'controled'}
        allowDecimals={false}
        label={capitalizeFirstLetter(arrayOfMonths[i])}
        placeholder={t('Amount in {{amountUnit}}', { amountUnit })}
        rules={{
          required: {
            value: true,
            message: t('Please enter amount')
          },
          validate: v => (v - Math.floor(v) === 0) || wholeNumberErrorMessage
        }}
        //@ts-ignore
        name={`amounts[${i}].amount`}
        key={arrayOfMonths[i]}
      />)
    }
    return inputFields
  }

  function renderForwardMonths(lastMonthIndex, arrayOfMonths) {
    const inputFields = []
    for (let i = 11, j = lastMonthIndex + 1; i > lastMonthIndex; i--, j++) {
      inputFields.push(<FormFields.Input
        control={control}
        placeholder={t('Amount in {{amountUnit}}', { amountUnit })}
        label={capitalizeFirstLetter(arrayOfMonths[i])}
        typeInput={'controled'}
        allowDecimals={false}
        //@ts-ignore
        name={`amounts[${j}].amount`}
        rules={{
          required: {
            value: true,
            message: t('Please enter amount')
          },
          validate: v => (v - Math.floor(v) === 0) || wholeNumberErrorMessage
        }}
        key={arrayOfMonths[i]}
      />)
    }
    return inputFields
  }

  function renderMonths() {
    const lastMonthIndex = dayjs().month() - 1
    const arrayOfMonths = dayjs.months()
    return <>
      {renderBackwardsMonths(lastMonthIndex, arrayOfMonths)}
      {renderForwardMonths(lastMonthIndex, arrayOfMonths)}
    </>
  }

  return <>
    <FormFields.Select
      control={control}
      name={'amountType'}
      label={t('Amount type')}
      placeholder={t('Select amount type')}
      rules={{
        required: {
          value: true,
          message: t('Please select amount type')
        }
      }}
      selectOnBlur={false}
      //@ts-ignore fix when types are in
      options={farmersOptions.amountTypes.map(amountType => ({ value: amountType.key, text: amountType.label }))}
    />
    {watchAmountType === 'year' && <FormFields.Input
      control={control}
      label={t('Amount in {{amountUnit}}', { amountUnit })}
      typeInput={'controlled'}
      allowDecimals={false}
      rules={{
        required: {
          value: watchAmountType === 'year',
          message: t('Please select amount type')
        },
        validate: v => (v - Math.floor(v) === 0) || wholeNumberErrorMessage
      }}
      placeholder={t('Amount in {{amountUnit}}', { amountUnit })}
      name='amount'
    />}
    {watchAmountType === 'month' && renderMonths()}
    {watchFarmerType !== 'sub' && <FormFields.Select
      selectOnBlur={false}
      control={control}
      label={t('Farmer group')}
      name='farmerGroupId'
      placeholder={t('Select farmer group')}
      rules={{
        required: {
          value: true,
          message: t('Select farmer group')
        }
      }}
      options={farmerGroupList}
    />
    }
  </>
}
export default AmountFarmerGroup