import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormFields } from '~/components/FormFields'
import { Form } from 'semantic-ui-react'
import { Control } from 'react-hook-form'
import { DairyOptionsType } from '~/services/dairies'
import { emailRegex } from '~/Constants'

interface LoactionProps {
  control: Control<any>,
  dairyOptions: DairyOptionsType,
  prefixValue?: string
}

const Location = (props: LoactionProps) => {
  const { dairyOptions, prefixValue } = props
  const [control, setControl] = useState(props.control)
  const { t } = useTranslation()

  useEffect(() => {
    setControl(props.control)
  }, [props.control])

  return <>
    <FormFields.Input
      control={control}
      name={'name'}
      rules={{
        required: {
          value: true,
          message: t('Please enter office name')
        },
        maxLength: {
          value: 100,
          message: t('Max char is 100')
        }
      }}
      placeholder={t('Office name')}
      label={t('Office name')}
    />
    <FormFields.Select
      control={control}
      options={dairyOptions.officeTypes?.map(officeType => ({ value: officeType.key, text: officeType.label }))}
      name={'type'}
      label={t('Office type')}
      placeholder={t('Office type')}
      selectOnBlur={false}
      rules={{
        required: {
          value: true,
          message: t('Please select office type')
        }
      }}
    />
    <FormFields.Input
      control={control}
      placeholder={t('Office email')}
      label={t('Office email')}
      name={'officeEmail'}
      rules={{
        required: {
          value: true,
          message: t('Please select office email')
        },
        pattern: {
          value: emailRegex,
          message: t('Please enter valid email')
        }
      }}
    />
    <FormFields.Input
      control={control}
      rules={{
        required: {
          value: true,
          message: t('Please enter street name')
        },
        maxLength: {
          value: 100,
          message: t('Max char is 100')
        }
      }}
      name={'streetName'}
      placeholder={t('Street name')}
      label={t('Street name')}
    />
    <FormFields.Input
      control={control}
      name={'streetNumber'}
      rules={{
        required: {
          value: true,
          message: t('Please enter street number')
        },
        maxLength: {
          value: 100,
          message: t('Max char is 100')
        }
      }}
      placeholder={t('Street number')}
      label={t('Street number')}
    />
    <FormFields.Input
      control={control}
      name={'city'}
      rules={{
        required: {
          value: true,
          message: t('Please enter city')
        },
        maxLength: {
          value: 100,
          message: t('Max char is 100')
        }
      }}
      placeholder={t('City')}
      label={t('City')}
    />
    <FormFields.Input
      control={control}
      name={'zip'}
      rules={{
        required: {
          value: true,
          message: t('Please enter zip code')
        },
        maxLength: {
          value: 10,
          message: t('Max char is 10')
        }
      }}
      placeholder={t('Zip Code')}
      label={t('Zip Code')}
    />
    <FormFields.Select
      control={control}
      selectOnBlur={false}
      options={dairyOptions?.countries.map(country => ({ value: prefixValue ? country[prefixValue] : country.iso, text: country.label }))}
      rules={{
        required: {
          value: true,
          message: t('Please please select country')
        }
      }}
      placeholder={t('Country')}
      name={'countryId'}
      label={t('Country')}
    />
    <Form.Group style={{ alignItems: 'end' }}>
      <FormFields.Select
        control={control}
        selection
        width={6}
        search
        selectOnBlur={false}
        rules={{
          required: {
            value: true,
            message: t('Please select phone prefix')
          }
        }}
        placeholder={t('Select phone prefix')}
        options={dairyOptions?.countries.map(country => ({ value: prefixValue ? country[prefixValue] : country.phoneCode, text: country.phoneCode }))}
        name={'phonePrefix'}
        label={t('Phone number')}
      />
      <FormFields.Input
        width={6}
        rules={{
          required: {
            value: true,
            message: t('Please select phone number')
          }
        }}
        placeholder={t('Phone number')}
        control={control}
        name={'phoneNumber'}
      />
    </Form.Group>
    <Form.Group style={{ alignItems: 'end' }}>
      <FormFields.Select
        control={control}
        selection
        width={6}
        search
        selectOnBlur={false}
        placeholder={t('Select telefax prefix')}
        options={dairyOptions?.countries.map(country => ({ value: prefixValue ? country[prefixValue] : country.phoneCode, text: country.phoneCode }))}
        name={'telefaxPrefix'}
        label={t('Telefax number')}
      />
      <FormFields.Input
        width={6}
        placeholder={t('Telefax number')}
        control={control}
        name={'telefaxNumber'}
      />
    </Form.Group>
  </>
}
export default Location
