import { Button, Form, Header } from 'semantic-ui-react';
import styled from 'styled-components';

const DrawerContainer = styled.div`
  .drawer {
    background: #ffffff !important;
    border-top-right-radius: 1rem !important;
    width: 30% !important;
    margin-top: 80px !important;
    height: calc(100% - 80px) !important;
    padding: 0 1rem !important;
    min-width: 300px !important;
    .pushable:not(body) {
      transform: none;
    }
    z-index: 1000;
    background: #ffffff !important;
    .pushable:not(body) > .ui.sidebar,
    .pushable:not(body) > .fixed,
    .pushable:not(body) > .pusher:after {
      position: fixed;
    }
  }
`;

const DrawerHeader = styled.div`
  align-items: center;
  display: flex;
`;

const DrawerTitle = styled(Header)`
  margin-right: auto;
  font-weight: bold;
`;

const DrawerAction = styled.div`
  margin-left: 0.75rem;
  position: relative;
  cursor: pointer !important;
`;

const DrawerActionClose = styled(Button)`
  background: transparent !important;
  padding: 0 !important;
  color: #1e293b !important;
  cursor: pointer !important;
`;

const DrawerFooter = styled(Form.Field)`
  margin-top: 3rem !important;
  float: right;
`;

export const DrawerEl = {
  DrawerContainer,
  DrawerHeader,
  DrawerTitle,
  DrawerAction,
  DrawerActionClose,
  DrawerFooter
};
