import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableComponent from '~/components/TableComponent'
import i18n from '~/components/i18n'
import Dialog from '~/components/Dialog'
import { Popup } from 'semantic-ui-react'
import CustomIcon from '~/components/CustomIcon'
import { useParams } from 'react-router-dom'
import notify from '~/components/notify'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import { MilkTypeQueries } from '~/QueryHooks/MilkTypeQueries'
import { PageHeaderActionButton } from '~/components/Layout/style'
import CreateEditMilkTypes from './CreateEditMilkTypes'
import { MilkMoneyApi } from '~/services'

const tableColumns = [
  {
    name: i18n.t('Abbreviation'),
    key: 'abbreviation'
  },
  {
    name: i18n.t('Designation'),
    key: 'name'
  },
  {
    name: i18n.t('Actions')
  }
];

const MilkTypes: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [isBusy, setIsBusy] = useState(false)
  const [dataFiltered, setDataFiltered] = useState({
    rows: 20,
    page: 0
  })
  const [drawer, setDrawerState] = useState({
    isVisible: false,
    id: null
  })
  const [selectedType, setSelectedType] = useState(null)
  const actions = [
    {
      popupMessage: i18n.t('Edit'),
      type: 'icon',
      name: 'pencil',
      size: 'large',
      actionType: 'edit',
      displayedText: i18n.t('No actions')
    },
    {
      popupMessage: i18n.t('Delete'),
      type: 'icon',
      name: 'trash',
      size: 'large',
      actionType: 'delete',
      displayedText: i18n.t('No actions'),
      renderActions: getAction
    }
  ]

  function getAction(row, type) {
    return row.default === 0 && row.farmersCount === 0 && <Popup
      content={t('Delete')}
      trigger={<CustomIcon
        size='large'
        style={{ marginBottom: 2 }}
        onClick={() => setSelectedType(row)}
        name='trash' />}
    />
  }

  const onSuccess = () => {
    notify(t('Successfully deleted milk type'), 'success');
    fetchData();
  };

  const {
    isLoading: isBusyData,
    data: milkTypes,
    refetch: fetchData
  } = MilkTypeQueries.getMilkTypes(id,
    { ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })

  const { isLoading: isStateDeleteLoading, mutate: deleteMilkType } = MilkTypeQueries.deleteMilkType(
    onSuccess
  );

  async function onMilkTypeDelete() {
    setIsBusy(true)
    try {
      await MilkMoneyApi.MilkTypeDelete(id, selectedType?.id)
      notify(t('Sucessfully delete milk type'), 'sucess')
      setSelectedType(null)
      fetchData()
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function handleActionClick(row, type) {
    switch (type) {
      case 'edit':
        return setDrawerState({ isVisible: true, id: row?.id })
      default:
        return
    }
  }

  function renderTable() {
    return <TableComponent
      tableColumnHeaders={tableColumns}
      rows={milkTypes || []}
      total={milkTypes.length || 0}
      dataFiltered={dataFiltered}
      handleChangeDataFiltered={(data) => setDataFiltered(data)}
      handleClickAction={handleActionClick}
      actions={actions}
    />
  }

  function renderActions() {
    return <PageHeaderActionButton
      onClick={() => setDrawerState({ isVisible: true, id: null })}
    >{t('Add new milk type')}</PageHeaderActionButton>
  }

  function renderEditCreateState() {
    return <CreateEditMilkTypes
      isVisible={drawer.isVisible}
      idMilkType={drawer.id}
      fetchData={fetchData}
      handleHiddenDrawer={() => setDrawerState({ isVisible: false, id: null })}
    />
  }

  function renderDeleteModal() {
    return <Dialog
      key='dialog-milk-type-delete'
      disableSubmit={isBusy}
      textHeader={t(`Delete milk type: ${selectedType?.name}`)}
      textClose={t('Close')}
      textContent={t(`Are you sure that you want to delete ${selectedType?.name} milk type?`)}
      textSubmit={t('Delete milk type')}
      handleClose={() => setSelectedType(null)}
      handleSubmit={onMilkTypeDelete}
      type='abort'
      isDialogOpen={Boolean(selectedType)}
      isLoading={isStateDeleteLoading}
    />
  }

  return <DairyViewTabsLayout
    header={t('Milk types')}
    isBusy={isBusyData}
    segmented
    actions={renderActions()}
  >
    {selectedType && renderDeleteModal()}
    {renderEditCreateState()}
    {milkTypes && renderTable()}
  </DairyViewTabsLayout>
}
export default MilkTypes