import React, { ReactElement } from 'react'
import { useDropzone, FileRejection, DropEvent, FileWithPath } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import notify from '../notify'
import { StyledDropzone } from './style'
interface FileUploadPropTypes {
  mimeTypes?: string[],
  disabled?: boolean,
  children?: ReactElement,
  multiple?: boolean,
  description?: string,
  onChange?: (acceptedFiles: FileWithPath[], fileRejections: FileRejection[], event: DropEvent, field?: string) => void,
  file?: File,
  encodeFiles?: boolean
  sizeLimitInMB?: number
}

const ONE_MILLION = 1000000;

const FileUpload = (props: FileUploadPropTypes) => {
  const { t } = useTranslation()
  const { mimeTypes, children, onChange, multiple, description, encodeFiles } = props

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: encodeFiles ? handleUploadFiles : onChange,
    multiple: multiple
  })

  function handleUploadFiles(acceptedFiles: FileWithPath[]) {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    return reader.onload = () => {
      const separatorSize = file?.name?.split('.')
      const basicType = file?.name?.split('.')[separatorSize.length - 1]
      if (props.sizeLimitInMB && (file.size / ONE_MILLION) > props.sizeLimitInMB) {
        notify(t('File size is too large'), 'error')
        return
      }
      if (mimeTypes && !mimeTypes.find(mimeType => mimeType === basicType)) {
        notify(t('File type not supported'), 'error')
        return
      }
      //@ts-ignore
      onChange(reader.result)
    }
  }

  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return <section className='dropbox'>
    {!children ? <StyledDropzone
      {...getRootProps({ isDragAccept, isFocused, isDragReject })}
      onClick={open}
      className={'empty'}>
      <>
        <input {...getInputProps()} />
        <p>{description || t('Drop or select a file to upload.')}</p>
      </>
    </StyledDropzone> : children
    }
  </section>
}

export default FileUpload
