import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { StateEl } from './style';
import { FormFields } from '~/components/FormFields';
import Drawer from '~/components/Drawer';
import { useParams } from 'react-router-dom'
import { StyledMenuItem } from './style';
import { PageHeaderActionButton } from '~/components/Layout/style';
import { useSelector } from 'react-redux';
import { SystemDataState } from '~/reducers';
import { SystemDataType } from '~/services/login';
import notify from '~/components/notify';
import { StyledTabPane } from '~/pages/DairyFactory/style';
import { MilkMoneyApi } from '~/services';

interface CreateEditMilkTypeProps {
  isVisible: boolean;
  handleHiddenDrawer: () => void;
  isLoading?: boolean;
  fetchData?: () => void,
  idMilkType?: any
}

const CreateEditMilkTypes: React.FunctionComponent<CreateEditMilkTypeProps> = (props) => {
  const { isVisible, handleHiddenDrawer, idMilkType } = props;
  const { id } = useParams()
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const languages = systemData?.languages.filter(language => language.code === 'en' || language.code === 'de')

  const [isBusy, setIsBusy] = useState(false)
  const { t } = useTranslation();
  const { handleSubmit, control, reset, watch, formState } = useForm({
    mode: 'onSubmit'
  });

  const watchDefaultField = watch('default')

  useEffect(() => {
    if (idMilkType) {
      fetchEditData()
    } else {
      const languages = getLangs()
      reset({
        status: 0,
        default: 0,
        override: 0,
        languages
      });
    }
  }, [isVisible, idMilkType]);

  async function fetchEditData() {
    setIsBusy(true)
    try {
      const response = await MilkMoneyApi.MilkTypeEdit(id, idMilkType)
      response.data.languages = getParsedLanguages(response.data.languages)
      reset(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function getLangs() {
    return languages.map(language => ({
      id: language.id,
      abbreviation: '',
      name: ''
    }))
  }

  function getParsedLanguages(milkTypeLanguages) {
    languages.map((language) => {
      const index = milkTypeLanguages.findIndex(languageText => languageText.id === language.id)
      if (index < 0) {
        milkTypeLanguages.push({
          id: language.id,
          abbreviation: '',
          name: '',
          order: language.order
        })
      } else {
        milkTypeLanguages[index].order = language.order
      }
    })
    milkTypeLanguages.sort((a, b) => a.order - b.order)
    milkTypeLanguages?.forEach(lang => delete lang.order)
    return milkTypeLanguages
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      if (!idMilkType) {
        await MilkMoneyApi.MilkTypeStore(id, data)
        notify(t('Successfully created milk type'), 'success');
      } else {
        await MilkMoneyApi.MilkTypeUpdate({ idDiary: id, idMilkType, data })
        notify(t('Successfully updated milk type'), 'success');
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
    handleHiddenDrawer();
    props.fetchData()
  };

  function errorTab() {
    return formState.errors?.languages?.map((lang, index) => index)?.filter(item => typeof (item) === 'number')
  }

  function getMenuItem(label, index) {
    const errors = errorTab() || []

    return <StyledMenuItem
      key={label}
      className='tab-item'
    >
      <div className={`${errors.includes(index) ? 'error' : ''}`}>{label}</div>
    </StyledMenuItem>
  }

  function renderLanguageFields() {
    return <StateEl.StyledTabs
      menu={{ secondary: true, pointing: true }}
      renderActiveOnly={false}
      panes={
        languages.map((language) => {
          const indexLanguage = languages.findIndex(languageIndex => languageIndex['id'] === language.id)
          return {
            //@ts-ignore
            menuItem: getMenuItem(language['label'], indexLanguage),
            pane: <StyledTabPane key={language.id}>
              <FormFields.Input
                control={control}
                //@ts-ignore
                name={`languages[${indexLanguage}].name`}
                label={t('Designation')}
                placeholder={t('Designation')}
                rules={{
                  required: {
                    value: true,
                    message: t('Please enter designation')
                  }
                }}
              />
              <FormFields.Input
                control={control}
                label={t('Abbreviation')}
                placeholder={t('Abbreviation')}
                //@ts-ignore
                name={`languages[${indexLanguage}].abbreviation`}
                rules={{
                  required: {
                    value: true,
                    message: t('Please enter abbreviation')
                  },
                  maxLength: {
                    value: 20,
                    message: t('Max. 20 characters')
                  }
                }}
              />
            </StyledTabPane>
          }
        })}
    />
  }

  const renderContent = () => {
    return (
      <>
        <FormFields.Checkbox
          control={control}
          name={'status'}
          defaultValue={0}
          checkboxValue={1}
          label={t('Status')}
        />
        <FormFields.Checkbox
          control={control}
          name={'default'}
          checkboxValue={1}
          defaultValue={0}
          label={t('Default')}
        />
        {!idMilkType && watchDefaultField === 1 && <FormFields.Checkbox
          control={control}
          checkboxValue={1}
          name={'override'}
          defaultValue={0}
          label={t('Override')}
        />
        }
        {renderLanguageFields()}
        <StateEl.DrawerFooter>
          <PageHeaderActionButton>{idMilkType ? t('Update') : t('Add')}</PageHeaderActionButton>
        </StateEl.DrawerFooter>
      </>
    );
  };

  return <Drawer
    isVisible={isVisible}
    as
    handleSubmit={handleSubmit(onSubmit)}
    isBusy={isBusy}
    header={idMilkType ? t('Edit milk type') : t('Create milk type')}
    closeOnEscape
    onHide={() => {
      //   setUpdateLogsData(null);
      reset();
      handleHiddenDrawer();
    }}
    content={renderContent()}
  />
};

export default CreateEditMilkTypes;
