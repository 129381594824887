import {Icon, Label} from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledImageWrapper = styled.div`
  display: inline-block; 
  position: relative;
  border-radius: .375rem;
  padding: 1.25rem;
  border: 2px dashed rgba(226 232 240 / 0.6);
  box-shadow:  0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);
  .trashCan{
    cursor: pointer;
  }
  img {
    &:hover {
      transform: scaleX(1.05) scaleY(1.05);
      box-shadow:  0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
      transition-duration: 300ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
`
export const RemoveImageWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`
export const RemoveImageButton = styled(Icon)`
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.3rem;
  background: #B91C1C; 
  color: #FFFFFF;
`
export const TableFooterWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`
export const StyledLabel = styled(Label)`
white-space: normal;
background: #fff!important;
border: 1px solid #e0b4b4!important;
color: #9f3a38!important;
`
