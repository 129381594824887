import {applyMiddleware, createStore} from 'redux'
import {allReducers} from './reducers'
import thunk from 'redux-thunk'

function middlewareForProduction() {
  return applyMiddleware(thunk)
}

function middlewareForDevelopment() {
  return applyMiddleware(thunk)
}

let middleware
if (false) {
  middleware = middlewareForProduction()
} else {
  middleware = middlewareForDevelopment()
}

const createdStore = createStore(allReducers, {}, middleware)
export default createdStore
