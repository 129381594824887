import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  Icon,
  Label,
  Pagination,
  Table,
  SemanticICONS,
  SemanticCOLORS,
  Popup,
  Transition,
  Segment,
  Header,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { optionsLimit } from '~/Constants';
import { Link } from 'react-router-dom';
import CustomIcon from '../CustomIcon';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import { StyledNoResultsMessage, StyledTableWrapper, TableFooterWrapper, StyledTableRow } from './style'
import cloneDeep from 'lodash.clonedeep'
import dayjs from 'dayjs';
import { formatNumber, formatNumberWithThreeDigitPrecision, formatNumberWithoutFractions } from '~/util';

type ActionColumnType = {
  popupMessage: string
  type: string,
  name: string,
  size?: string,
  link?: string,
  display?: any[],
  color?: string,
  actionType?: string,
  verifiedAction?: number,
  verifiedActionKey?: string,
  displayedText?: string,
  displayedTextKey?: string
  renderActions?: (action: ActionColumnType, actionType: string) => JSX.Element,
  replaceKey?: string
}

interface TableProps {
  tableColumnHeaders: any[],
  dataFiltered?: any,
  pagination?: boolean,
  handleChangeDataFiltered?: (data: any, tableColumnHeaders?) => void,
  actions?: ActionColumnType[],
  rows: any[],
  total: number,
  handleClickAction?: (row, type) => void,
  loading?: boolean,
  search?: boolean,
  options?: any,
  handleCellValueChange?: (value, index?, row?) => void,
  removePagination?: boolean,
  customTableFooter?: React.ReactNode
  animated?: boolean
  isFetchingData?: boolean,
  renderColumn?: (type: string, value: any, row?, index?) => JSX.Element
  upward?: boolean
}

const TableComponent: React.FC<TableProps> = (props) => {
  const { t } = useTranslation()
  const { tableColumnHeaders, handleChangeDataFiltered } = props
  const [dataFiltered, setDataFiltered] = useState(props.dataFiltered)
  const firstColor = '#1a3175'
  const { total, rows } = props

  useEffect(() => {
    setDataFiltered(props.dataFiltered)
  }, [props.dataFiltered])

  const snakeToCamel = str =>
    str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );

  useEffect(() => {
    if (dataFiltered && JSON.stringify(props.dataFiltered) !== JSON.stringify(dataFiltered)) {
      const tempColumnHeader = cloneDeep(tableColumnHeaders)
      const { column, sort } = dataFiltered
      if (column && sort) {
        const columnKeyRevertedToCamel = snakeToCamel(column)
        const indexColumn = tableColumnHeaders.findIndex(tableColumn => tableColumn.key === columnKeyRevertedToCamel)
        if (indexColumn >= 0) {
          tempColumnHeader[indexColumn].sorted = sort
          tempColumnHeader.forEach((tableColumn, index) => {
            if (tableColumn.sorted && index !== indexColumn) {
              tableColumn.sorted = 'desc'
            }
          })
        }
      }
      handleChangeDataFiltered(dataFiltered, tempColumnHeader)
    }
  }, [dataFiltered])

  function renderLinkAction(row, action: ActionColumnType) {
    const show = action.display ? action.display.find(actionStatus => actionStatus === row.status) : true
    const verified = action.verifiedAction !== undefined ? row[action.verifiedActionKey] === action.verifiedAction : true
    return show && verified && <Popup
      key={row.id + action.popupMessage}
      content={action.popupMessage}
      trigger={<Link key={row.id + 'link'} to={action.link.replace(action.replaceKey || ':id', row.id)}>
        <CustomIcon
          name={action.name as SemanticICONS}
          size={action.size as IconSizeProp}
        />
      </Link>}
    />
  }

  function renderIconAction(row, action: ActionColumnType, index: number) {
    const show = action.display ? action.display.find(actionStatus => actionStatus === row.status) !== undefined : true
    const verified = action.verifiedAction !== undefined ? row[action.verifiedActionKey] === action.verifiedAction : true
    return show && verified ? <Popup
      key={row.id + action.actionType}
      content={action.popupMessage}
      trigger={<CustomIcon
        name={action.name as SemanticICONS}
        size={action.size as IconSizeProp}
        color={action.color as SemanticCOLORS}
        onClick={() => props.handleClickAction(row, action.actionType)}
      />}
    /> : (action.displayedText || (index === 0 && row[action.displayedTextKey])) || ''
  }

  function actionColumn(row) {
    return <div style={{ display: 'flex' }} key={row.id + 'button'}>
      {props.actions.map((action, index) => {
        if (action.renderActions) {
          return <div key={row.id + action.actionType}>{action.renderActions(row, action.actionType)}</div>
        }
        return action.type === 'link' ? renderLinkAction(row, action) : renderIconAction(row, action, index)
      })}
    </div>
  }

  function renderActionColumn(row) {
    return props.actions?.length > 0 ? actionColumn(row) : props.actions?.length === 0 && t('No actions')
  }

  function renderCellBasedOnType(value, row, index, column) {
    const { type } = column
    const confirmedAmountRemaining = row.confirmedAmount ? row.confirmedAmount : 0

    switch (type) {
      case 'verified':
        return value == 1 ? <Icon color='green' name='check circle outline' /> : <Icon color='red' name='close' />
      case 'email-type':
          return value === 'email' ? (
            <Popup
              content={t('Email')}
              trigger={<CustomIcon name='envelope square' size='large' />}
            />
          ) : (
            <Popup
              content={t('Newsletter')}
              trigger={<CustomIcon name='newspaper' size='large' />}
            />
          );
      case 'email-status':
          switch (value) {
              case 'Delivery':
                    return (
                        <Popup
                          content={t('Delivered')}
                          trigger={<CustomIcon name='envelope outline' size='large' />}
                        />
                    );
              case 'Open':
                    return (
                        <Popup
                            content={t('Opened')}
                            trigger={<CustomIcon name='envelope open' size='large' />}
                        />
                    );
              case 'Send':
                    return (
                        <Popup
                            content={t('Send')}
                            trigger={<CustomIcon name='envelope' size='large' />}
                        />
                    );
            }
      case 'date':
        return dayjs(value).format(column.format)
      case 'folders':
        return value.map(value => value.name).join(', ')
      case 'percent':
        return formatNumber(value)
      case 'number':
        return value ? `${formatNumberWithoutFractions(parseFloat(value).toFixed(2))} l` : '0 l'
      case 'confirmWithPercent':
        return row.confirmedAmount ? `(${formatNumberWithoutFractions(row.confirmedAmount)} l) / 
            [${formatNumber(row.confirmedAmount / row?.yearlyAmount * 100)} %]` : '(0 l) / [0,00 %]'
      case 'remaining-farmer':
        const remainingAmount = formatNumberWithoutFractions(row.yearlyAmount - confirmedAmountRemaining)
        return row.yearlyAmount - confirmedAmountRemaining > 0 ? `(${remainingAmount} l) / 
              [${formatNumberWithThreeDigitPrecision(100 * Number(row.yearlyAmount - confirmedAmountRemaining) / Number(row.yearlyAmount))} %]` :
          '(0 l) / [0,00 %]'
      case 'no-unit-int-number':
        return value ? `${formatNumber(parseFloat(value), 0)}` : '0'
      default:
        return value
    }
  }

  function getTableCell(column, row, index, tableColumnIndex) {
    const { key } = column
    //refactor this ASAP
    const cellValue = key ? renderCellBasedOnType(row[key], row, index, column) : renderActionColumn(row)
    return <Table.Cell key={`${key} ${row.id} ${index} ${tableColumnIndex}`}>{cellValue}</Table.Cell>
  }

  function tableBody() {
    return <Table.Body>
      {rows?.map((row, index) => {
        return <StyledTableRow key={row.id + index}>
          {tableColumnHeaders.map((tableColumn, tableColumnIndex) => {
            return getTableCell(tableColumn, row, index, tableColumnIndex)
          })}
        </StyledTableRow>
      })}
    </Table.Body>
  }

  function renderHeaderTitle(name) {
    return <div style={{ marginRight: '5px' }}>{name}</div>
  }

  function renderSortedIcon(sorted) {
    const direction = sorted === 'desc' ? 'down' : 'up'
    return <Icon name={`chevron ${direction}` as SemanticICONS} />
  }

  const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

  function tableHeaders() {
    return <Table.Row>
      {tableColumnHeaders.map((tableColumn) => {
        const { name, sorted } = tableColumn
        return <Table.HeaderCell
          className={sorted ? 'sortable' : ''}
          selectable={sorted}
          onClick={() => sorted && setDataFiltered({ ...dataFiltered, column: camelToSnakeCase(tableColumn.key), sort: sorted === 'asc' ? 'desc' : 'asc' })}
          style={{ cursor: sorted ? 'pointer' : 'default' }}
          key={name + Math.random()}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            {renderHeaderTitle(name)}
            {sorted && renderSortedIcon(sorted)}
          </div>
        </Table.HeaderCell>
      })}
    </Table.Row>
  }

  function renderNoResultsMessage() {
    return <StyledNoResultsMessage>
      <Segment placeholder>
        <Header icon>
          <Icon name='search' />
          {t('No results found....')}
        </Header>
        <Segment.Inline>
        </Segment.Inline>
      </Segment>
    </StyledNoResultsMessage>
  }

  function renderAnimatedTable() {
    return <Transition visible={!props.isFetchingData} animation='scale' duration={150}>
      <Table celled padded striped>
        <Table.Header>
          {tableHeaders()}
        </Table.Header>
        {rows && !props.isFetchingData && tableBody()}
        {props.loading && renderLoading()}
      </Table>
    </Transition>
  }

  function renderContentTable() {
    return <Table celled padded striped>
      <Table.Header>
        {tableHeaders()}
      </Table.Header>
      {rows && tableBody()}
    </Table>
  }

  function renderTable() {
    return <>
      {props.animated ? renderAnimatedTable() : renderContentTable()}
      {rows?.length === 0 && !props.isFetchingData && renderNoResultsMessage()}
    </>
  }

  function renderPagination() {
    return <div>
      <Pagination
        onPageChange={(_, data) => setDataFiltered({ ...dataFiltered, page: parseInt(data.activePage.toString()) })}
        activePage={dataFiltered.page === 0 ? 1 : dataFiltered.page}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
        totalPages={Math.ceil(total / dataFiltered.rows)} />
    </div>
  }

  function renderTableFooter() {
    return props.customTableFooter || <TableFooterWrapper>
      <div>
        {!props.removePagination && dataFiltered && <Dropdown
          basic
          button
          upward={true}
          size='small'
          value={dataFiltered.rows}
          onChange={(e, data) => {
            setDataFiltered({
              ...dataFiltered,
              rows: data.value as number
            })
          }}
          options={optionsLimit}
        />
        }
        <Label basic pointing={'left'}>
          {t('Total: ')}
          <Label.Detail>{total}</Label.Detail>
        </Label>
      </div>
      {!props.removePagination && renderPagination()}
    </TableFooterWrapper>
  }

  function renderLoading() {
    return <Dimmer inverted active={props.loading}>
      <Loader active={props.loading} />
    </Dimmer>
  }

  return <StyledTableWrapper color={firstColor}>
    {renderTable()}
    {!props.isFetchingData && rows.length > 0 && renderTableFooter()}
  </StyledTableWrapper>
}
export default TableComponent
