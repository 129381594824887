import styled, {css} from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 2.5rem;

  ${({theme}) => css`
    @media (min-width: ${theme.breakpoint.mobileM}) {
      max-width: 640px;
    }

    @media (min-width: ${theme.breakpoint.tablet}) {
      max-width: 768px;
    }

    @media (min-width: ${theme.breakpoint.laptop}) {
      max-width: 1024px;
    }

    @media (min-width: ${theme.breakpoint.laptopL}) {
      max-width: 1280px;
    }
  `}
`

export const AuthGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

 > form .ui.error.message {
    position: absolute;
    left: 0 !important;
    bottom: 0 !important;
 }
`
export const AuthRightSide = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: 5rem;
  border-radius: .375rem;
  width: auto;
  background-color: transparent;
  padding: 0px;
`
export const AuthLeftSide = styled.div`
  min-height: 100vh;
  flex-direction: column;
  display: flex;
`