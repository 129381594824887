import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { Icon, Menu, Popup } from 'semantic-ui-react'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ADMIN_TOKEN, RouteTypes } from '~/Constants'
import Languages from './Languages'
import { TopBarMenu, TopBar, BrandLogo, LeftSideWrapper, StyledProfileWrapper } from './style'
import { ProfileApi } from '~/services'
import { LazyLoadImage } from 'react-lazy-load-image-component';


const MainMenu: React.FC = () => {
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const { t } = useTranslation()
  const history = useHistory()
  const [avatar, setAvatar] = useState('')


  useEffect(() => {
    fetchFileProfile()
  }, [systemData])

  async function fetchFileProfile() {
    try {
      const response = await ProfileApi.getFileProfile()
      setAvatar(response.data)
    } catch (error) {
      // do nothing
    }
  }

  function leftSide() {
    return <>
      <BrandLogo onClick={() => history.push(RouteTypes.HOME)} key={'1'}>
        Food Business Digital
      </BrandLogo>
    </>
  }

  function rightSide() {
    return <>
      <Menu.Item onClick={() => history.push(RouteTypes.PROFILE)} key={'1'}>
        <Popup
          content={t('My user profile')}
          trigger={
            <div style={{ display: 'flex', color: 'white', cursor: 'pointer', alignItems: 'center' }}>
              {avatar ? <StyledProfileWrapper>
                <LazyLoadImage
                  alt={''}
                  effect='blur'
                  src={avatar} />
              </StyledProfileWrapper> : <Icon name='user circle' size='big' />}
              <p style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>{systemData?.logged?.name}</p>
            </div>
          }
        />
      </Menu.Item>
      <Menu.Item key={'5'}>
        <Languages />
      </Menu.Item>
      <Menu.Item onClick={() => {
        localStorage.removeItem(ADMIN_TOKEN)
        document.location.pathname = '/'
      }} key={'6'}>
        <Popup
          content={t('Log out')}
          trigger={<Icon name='shutdown' style={{ color: 'white', cursor: 'pointer' }} size='large' />}
        />
      </Menu.Item>
    </>
  }

  return <TopBar>
    <TopBarMenu>
      <LeftSideWrapper position='left'>
        {leftSide()}
      </LeftSideWrapper>
      <Menu.Menu position='right'>
        {rightSide()}
      </Menu.Menu>
    </TopBarMenu>
  </TopBar>
}
export default MainMenu
