import React, { Suspense } from 'react'
import Router from './pages/Router'
import store from './Store'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import i18n from './components/i18n'
import 'semantic-ui-css/semantic.min.css';
import { I18nextProvider } from 'react-i18next'

function App() {
  return <Suspense fallback={<div>Internal error</div>}>
    <ToastContainer
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      position={toast.POSITION.TOP_RIGHT}
    />
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router />
      </Provider>
    </I18nextProvider>
  </Suspense>
}

export default App
