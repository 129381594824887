import api from './api'

export const getUpdateLogs = (data?) =>
  api.post('/update-logs/index', data)

export const getNewestUpdateLog = () =>
  api.post<UpdateLogType>('/update-logs/newest')

export const createUpdateLog = (data: UpdateLogType) =>
  api.post('/update-logs/store', data)

export const getUpdateLog = (id: string) =>
  api.post<UpdateLogType>(`/update-logs/${id}/edit`)

export const updateLog = (id: string, data: UpdateLogType) =>
  api.post(`/update-logs/${id}/update`, data)

export const deleteLog = (id: string) =>
  api.post(`/update-logs/${id}/delete`)

export const getOptionsForLogs = () =>
  api.post<UpdateLogOptionsType>('/update-logs/get-options-data')

export interface CreateUpdateLogType {
  date: string
  version: string
  description?: string
  visible?: boolean
}

export interface UpdateLogType {
  createdAt?: string
  dairies?: Array<any>
  date: string
  description: string
  id?: number
  updatedAt?: string
  version: string
  visible?: number
}

export interface UpdateLogOptionsType {
  dairies: Array<{ companyName: string, id: string }>
}
