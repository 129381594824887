import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormFields } from '~/components/FormFields';
import Drawer from '~/components/Drawer';
import { Tab } from 'semantic-ui-react';
import ColorPicker from '~/components/ColorPicker';
import { StyledMenuItem, StyledTabPane } from './style';
import { useSelector } from 'react-redux';
import { SystemDataState } from '~/reducers';
import { SystemDataType } from '~/services/login';
import { PageHeaderActionButton } from '~/components/Layout/style';
import { StateEl } from '../MilkMoney/MilkTypes/style';
import notify from '~/components/notify';
import { DairyApi } from '~/services';
import { useParams } from 'react-router-dom'

interface CreateEditHusbandryTypeProps {
  isVisible: boolean;
  handleHiddenDrawer: () => void;
  isLoading?: boolean;
  fetchData?: () => void,
  idHusbandryForm?: any
}

const CreateEditHusbandryForm: React.FunctionComponent<CreateEditHusbandryTypeProps> = (props) => {
  const { isVisible, handleHiddenDrawer, idHusbandryForm, fetchData } = props;
  const { t } = useTranslation();
  const { languages } = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const { id } = useParams()

  const [isBusy, setIsBusy] = useState(false)

  const { handleSubmit, control, reset, setValue, watch, formState, getValues } = useForm({
    mode: 'onSubmit'
  });

  const watchColorField = watch('color')

  useEffect(() => {
    if (idHusbandryForm) {
      fetchEditData()
    } else {
      getLangs()
    }
  }, [isVisible, idHusbandryForm]);

  async function fetchEditData() {
    setIsBusy(true)
    try {
      const response = await DairyApi.editHusbandryForm(id, idHusbandryForm)
      const responseDairy = await DairyApi.getDairy(id)
      const languagesOnDairy = languages.filter(language => responseDairy.data.languages.find(languageCode => languageCode === language.code))
      response.data.languages = getParsedLanguages(languagesOnDairy, response.data.languages)
      reset(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function getLangs() {
    setIsBusy(true)
    try {
      const responseDairy = await DairyApi.getDairy(id)
      const languagesOnDairy = languages.filter(language => responseDairy.data.languages.find(languageCode => languageCode === language.code))
      languagesOnDairy.map(language => ({
        id: language.id,
        abbreviation: '',
        name: '',
        description: ''
      }))
      reset({
        color: '',
        languages: languagesOnDairy
      });
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        const languagesOnDairy = languages.map(language => ({
          id: language.id,
          abbreviation: '',
          name: '',
          description: ''
        }))
        reset({
          color: '',
          languages: languagesOnDairy
        });
        // notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function getParsedLanguages(languagesOnDairy, husbandryTypeLangs) {
    languagesOnDairy.map((language) => {
      const index = husbandryTypeLangs.findIndex(languageText => languageText.id === language.id)
      if (index < 0) {
        husbandryTypeLangs.push({
          id: language.id,
          abbreviation: '',
          name: '',
          description: '',
          order: language.order
        })
      } else {
        husbandryTypeLangs[index].order = language.order
      }
    })
    husbandryTypeLangs.sort((a, b) => a.order - b.order)
    husbandryTypeLangs?.forEach(lang => delete lang.order)
    return husbandryTypeLangs
  }

  async function onSubmit(data) {
    data.price = data.price?.toString().replace(',', '.')
    data.bonusPrice = data.bonusPrice?.toString().replace(',', '.')
    try {
      if (!idHusbandryForm) {
        await DairyApi.createHusbandryForm(id, data)
        notify(t('Successfully created husbandry form'), 'success')
      } else {
        await DairyApi.updateHusbandryForm(id, idHusbandryForm, data)
        notify(t('Successfully updated husbandry form'), 'success')
      }
      fetchData()
      handleHiddenDrawer();
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  };

  function errorTab() {
    return formState.errors?.languages?.map((lang, index) => index)?.filter(item => typeof (item) === 'number')
  }

  function getMenuItem(label, index) {
    const errors = errorTab() || []

    return <StyledMenuItem
      key={label}
      className='tab-item'
    >
      <div className={`${errors.includes(index) ? 'error' : ''}`}>{label}</div>
    </StyledMenuItem>
  }

  function renderLanguageFields() {
    const languagesValues = getValues('languages')
    return <Tab
      menu={{ secondary: true, pointing: true }}
      renderActiveOnly={false}
      panes={
        languagesValues?.map((language, index) => {
          const languageOption = languages.find(languageIndex => languageIndex['id'] === language.id)
          return {
            //@ts-ignore
            menuItem: getMenuItem(languageOption.code.toLocaleUpperCase(), index),
            pane: <StyledTabPane key={language.id}>
              <FormFields.Input
                control={control}
                //@ts-ignore
                name={`languages[${index}].name`}
                label={t('Name')}
                rules={{
                  required: {
                    value: true,
                    message: t('Please enter name')
                  },
                  maxLength: {
                    value: 100,
                    message: t('Max. 100 characters')
                  }
                }}
              />
              <FormFields.Input
                control={control}
                label={t('Abbreviation')}
                //@ts-ignore
                name={`languages[${index}].abbreviation`}
                rules={{
                  required: {
                    value: true,
                    message: t('Please enter abbreviation')
                  },
                  maxLength: {
                    value: 20,
                    message: t('Max. 20 characters')
                  }
                }}
              />
              <FormFields.TextArea
                control={control}
                label={t('Description')}
                //@ts-ignore
                name={`languages[${index}].description`}
                rules={{
                  required: {
                    value: true,
                    message: t('Please enter description')
                  },
                  maxLength: {
                    value: 200,
                    message: t('Max. 200 characters')
                  }
                }}
              />
            </StyledTabPane>
          }
        })}
    />
  }

  const renderContent = () => {
    return (
      <>
        <FormFields.External
          fluid
          name={'color'}
          label={t('Color')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter color')
            }
          }}
        >
          <ColorPicker value={watchColorField}>
            <FormFields.Input
              control={control}
              hideError
              onChange={(e, data) => setValue('color', data.value)}
              placeholder={t('Color')}
              name={'color'}
            />
          </ColorPicker>
        </FormFields.External>
        <FormFields.Input
          control={control}
          label={t('Price')}
          typeInput={'controlled'}
          allowDecimals
          decimalScale={2}
          //@ts-ignore
          name={`price`}
          rules={{
            min: {
              value: 0,
              message: t('Please enter price bigger then 0')
            }
          }}
        />
        <FormFields.Input
          control={control}
          label={t('Bonus price')}
          typeInput={'controlled'}
          allowDecimals
          decimalScale={2}
          //@ts-ignore
          name={`bonusPrice`}
          rules={{
            min: {
              value: 0,
              message: t('Please enter bonus price bigger then 0')
            }
          }}
        />
        {renderLanguageFields()}
        <StateEl.DrawerFooter>
          <PageHeaderActionButton>{idHusbandryForm ? t('Update') : t('Add')}</PageHeaderActionButton>
        </StateEl.DrawerFooter>
      </>
    );
  };

  return <Drawer
    isVisible={isVisible}
    as
    handleSubmit={handleSubmit(onSubmit)}
    isBusy={isBusy}
    header={idHusbandryForm ? t('Edit form of husbandry') : t('Create form of husbandry')}
    closeOnEscape
    onHide={() => {
      reset();
      handleHiddenDrawer();
    }}
    content={!isBusy && renderContent()}
  />
};

export default CreateEditHusbandryForm;
