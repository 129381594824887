import React from 'react'
import { Icon, IconProps, Popup } from 'semantic-ui-react'
import styled from 'styled-components'

type InfoPopup = {
  content?: string | React.ReactElement
  styleIcon?: any
}

type InfoIconPopup = IconProps & InfoPopup

const InfoPopup: React.FC<InfoIconPopup> = (props) => {
  const { content, styleIcon } = props

  return <StyledInfoPopUpIcon
    color={'#1a3175'}
    content={content}
    trigger={<Icon style={{ ...styleIcon, color: styleIcon.color || '#1a3175' }} name='info circle' />}
  />
}

export default InfoPopup

const StyledInfoPopUpIcon = styled(Popup)`
background: ${props => props.color} !important;
color: white !important;
border-radius: 10px !important;
`