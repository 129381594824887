import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Icon, Input, Dropdown } from 'semantic-ui-react'
import Layout from '~/components/Layout'
import { ProfileApi, UsersApi } from '~/services'
import { ProfileDataType } from '~/services/profile'
import { FormFields } from '~/components/FormFields'
import { useForm } from 'react-hook-form'
import FileUpload from '~/components/FileUpload'
import { systemData as actionSystemData } from '~/actions/systemData'

import { StyledImageWrapper, StyledImageContainer } from './style'
import notify from '~/components/notify'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import { useDispatch, useSelector } from 'react-redux'

const defaultImage = '/assets/default.png'
const Profile: React.FC = () => {
  const { t } = useTranslation()
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const [profileData, setProfileData] = useState<ProfileDataType | null>(null)
  const [countriesCodes, setCountriesCodes] = useState([])
  const defaultLanguage = systemData?.languages.find(language => language.id === systemData?.logged.languageId)
  const languageOptions = systemData?.languages.filter(language => language.code === 'en' || language.code === 'de')
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useDispatch()

  const { handleSubmit, control, setValue, reset, watch } = useForm({
    mode: 'onSubmit'
  })

  const photoFieldUrl = watch('photo')
  const watchPhoneCodeField = watch('phonePrefix')
  const wathcMobileCodeField = watch('mobilePrefix')
  const mobileNumber = watch('mobileNumber')
  const phoneNumber = watch('phoneNumber')

  useEffect(() => {
    fetchProfileData()
  }, [])

  async function fetchProfileData() {
    setIsBusy(true)
    try {
      const profileResponse = await ProfileApi.editProfileData()
      const options = await UsersApi.getOptionsForUsers()
      const response = await ProfileApi.getFileProfile()
      setCountriesCodes(options.data.countries.map(country => ({ value: country.id, text: country.phoneCode })))
      setProfileData(profileResponse.data)
      reset(profileResponse.data)
      setValue('photo', response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      await ProfileApi.updateProfileData(data)
      dispatch(actionSystemData.setSystemData({ ...systemData, logged: { languageId: data.languageId } }))
      notify(t('Successfully updated profile'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function handleUploadFiles(acceptedFiles: File[]) {
    //setValues()
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setValue('photo', reader.result)
    }
  }

  function getFlagName(flag = null) {
    switch (flag) {
      case 'en':
        return 'gb'
      case 'fr_BE':
        return 'be'
      default:
        return flag
    }
  }

  function renderProfile() {
    return <Form.Group>
      <Form.Field width={3}>
        {photoFieldUrl && <StyledImageWrapper>
          <img src={photoFieldUrl ? photoFieldUrl : defaultImage}></img>
          <StyledImageContainer />
          {photoFieldUrl && <Icon
            circular
            onClick={(e) => { e.preventDefault(); setValue('photo', null) }}
            name='trash'
            className='trashCan'
          />
          }
        </StyledImageWrapper>
        }
        {!photoFieldUrl && <FileUpload
          onChange={handleUploadFiles}
          mimeTypes={['bmp', 'jpg', 'jpeg', 'png']}
          sizeLimitInMB={5}
        />
        }
      </Form.Field>
      <Form.Field width={12}>
        <FormFields.Input
          control={control}
          label={t('First name')}
          rules={{
            required: {
              value: true,
              message: t('Please enter first name')
            }
          }}
          defaultValue={profileData.firstName}
          style={{ marginBottom: 10 }}
          name={'firstName'}
          placeholder={t('First name')}
        />
        <FormFields.Input
          control={control}
          defaultValue={profileData.lastName}
          label={t('Last name')}
          rules={{
            required: {
              value: true,
              message: t('Please enter last name')
            }
          }}
          style={{ marginBottom: 10 }}
          name={'lastName'}
          placeholder={t('Last name')}
        />
        <FormFields.Select
          options={languageOptions.map(language => ({ value: language.id, text: language.label, flag: getFlagName(language.code) }))}
          selection
          selectOnBlur={false}
          placeholder={t('Select Language')}
          control={control}
          style={{ marginBottom: 10 }}
          defaultValue={defaultLanguage.id}
          label={t('Language')}
          name={'languageId'}
        />
        <Form.Field style={{ marginBottom: '10px' }} width={4}>
          <label>{t('Phone number')}</label>
          <Input
            value={phoneNumber}
            label={profileData && <Dropdown
              size='small'
              options={countriesCodes}
              selectOnBlur={false}
              basic
              scrolling
              value={parseInt(watchPhoneCodeField)}
              placeholder={t('Code')}
              onChange={(e, data) => setValue('phonePrefix', data.value)}
              name={'phonePrefix'}
            />}
            onChange={(e, data) => setValue('phoneNumber', data.value)}
            labelPosition='left'
            placeholder={watchPhoneCodeField ? t('Phone number') : t('Please select phone code')}
          />
        </Form.Field>
        <Form.Field width={4}>
          <label>{t('Mobile number')}</label>
          <Input
            value={mobileNumber}
            label={<Dropdown
              scrolling
              basic
              options={countriesCodes}
              selectOnBlur={false}
              onChange={(e, data) => setValue('mobilePrefix', data.value)}
              value={parseInt(wathcMobileCodeField)}
              placeholder={t('Code')}
              name={'mobilePrefix'}
            />}
            onChange={(e, data) => setValue('mobileNumber', data.value)}
            labelPosition='left'
            placeholder={wathcMobileCodeField ? t('Mobile number') : t('Please select mobile code')}
            name={'mobileNumber'}
          />
        </Form.Field>
      </Form.Field>
    </Form.Group>
  }

  return <Layout
    onSubmit={handleSubmit(onSubmit)}
    isBusy={isBusy}
    action={t('Update')}
    title={t('User profile')}
  >
    {profileData && renderProfile()}
  </Layout>
}
export default Profile
