import React, { useEffect, useState } from 'react'
import i18n from '~/components/i18n'
import { useTranslation } from 'react-i18next'
import { Input } from 'semantic-ui-react'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useParams } from 'react-router-dom'
import notify from '~/components/notify'
import { DairyApi } from '~/services'
import { Link } from 'react-router-dom'
import { RouteTypes } from '~/Constants'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-lodash-debounce'
import { PageHeaderActionButton } from '~/components/Layout/style'
import TableComponent from '~/components/TableComponent'
import Dialog from '~/components/Dialog'

const tableColumns = [
  {
    name: i18n.t('ID'),
    key: 'uniqueId'
  },
  {
    name: i18n.t('Name'),
    key: 'name'
  },
  {
    name: i18n.t('Number of farmers'),
    key: 'farmersCount'
  },
  {
    name: i18n.t('Trading amount (% / year)'),
    key: 'amountPercent',
    type: 'percent'
  },
  {
    name: i18n.t('Is a cooperative?'),
    type: 'verified',
    key: 'isOrganization'
  },
  {
    name: i18n.t('Status'),
    type: 'verified',
    key: 'status'
  },
  {
    name: i18n.t('Actions')
  }

]

const FarmerGroups = () => {
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const [farmerGroupRows, setFarmerGroupRows] = useState([])
  const [isBusy, setIsBusy] = useState(false)
  const { id } = useParams()
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [deleteGroup, setDeleteGroup] = useState(null)
  const [dataFiltered, setDataFiltered] = useState({
    page: 0,
    rows: systemData.defaultRowsPerPage,
    search: ''
  })
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 300)

  const actions = [
    {
      popupMessage: i18n.t('Edit'),
      type: 'link',
      name: 'pencil',
      size: 'large',
      link: RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP_EDIT.replace(':idDairy', id)
    },
    {
      popupMessage: i18n.t('Delete'),
      type: 'icon',
      color: 'red',
      name: 'trash',
      size: 'large',
      actionType: 'delete'
    },
    {
      popupMessage: i18n.t('Settings'),
      type: 'link',
      name: 'settings',
      size: 'large',
      link: RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP_SETTINGS.replace(':idDairy', id),
      verifiedAction: 1,
      verifiedActionKey: 'isOrganization'
    }
  ]

  useEffect(() => {
    fetchFarmersGroupsData()
  }, [])

  useEffect(() => {
    if (dataFiltered && total !== 0) {
      fetchFilteredData()
    }
  }, [dataFiltered])

  useEffect(() => {
    setDataFiltered({ ...dataFiltered, search: debouncedValue })
  }, [debouncedValue])

  async function fetchFilteredData() {
    setIsBusy(true)
    try {
      const response = await DairyApi.FarmerGroups(id, { ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })
      setFarmerGroupRows(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function fetchFarmersGroupsData() {
    setIsBusy(true)
    try {
      const responseViewFarmers = await DairyApi.FarmerGroups(id)
      setFarmerGroupRows(responseViewFarmers.data.rows)
      setTotal(responseViewFarmers.data.total)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderActions() {
    return <PageHeaderActionButton
      as={Link}
      to={RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP_CREATE.replace(':idDairy', id)}
    >
      {t('Create')}
    </PageHeaderActionButton>
  }

  async function deleteFarmerGroup(idGroup) {
    setIsBusy(true)
    try {
      await DairyApi.DeleteFarmerGroup(id, idGroup)
      await fetchFarmersGroupsData()
      setDeleteGroup(null)
      notify(t('Successfully deleted farmer group'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderSearch() {
    return <Input
      icon={{ name: 'search', circular: true, link: true }}
      placeholder={t('Search...')}
      value={search}
      onChange={(e, data) => setSearch(data.value)}
    />
  }

  function handleClickAction(row, type) {
    switch (type) {
      case 'delete':
        return setDeleteGroup(row)
      default:
        return <></>
    }
  }

  function renderContent() {
    return <TableComponent
      tableColumnHeaders={tableColumns}
      actions={actions}
      handleClickAction={(row, type) => handleClickAction(row, type)}
      dataFiltered={dataFiltered}
      rows={farmerGroupRows}
      total={Math.ceil(total / dataFiltered.rows)}
    />
  }

  function renderDeleteModal() {
    const name = deleteGroup?.name || ''
    return <Dialog
      open={Boolean(deleteGroup)}
      textHeader={t('Delete group: {{name}}', { name })}
      handleClose={() => setDeleteGroup(null)}
      handleSubmit={() => deleteFarmerGroup(deleteGroup?.id)}
      textContent={<>{t('Do you want to delete group {{name}}?', { name })}</>}
      textSubmit={t('Delete')}
      textClose={t('Close')}
      type={'abort'}
    />
  }

  return <DairyViewTabsLayout
    header={t('Farmer Groups')}
    isBusy={isBusy}
    search={renderSearch()}
    segmented
    actions={renderActions()}
  >
    {deleteGroup && renderDeleteModal()}
    {renderContent()}
  </DairyViewTabsLayout>
}
export default FarmerGroups
