import { useQuery } from "react-query"
import { DairyApi } from "~/services"
import { onError } from "~/util"

const getFarmers = (idDairy: string, data?) => useQuery(
  `farmers-index-${data.search}`,
  () => DairyApi.FarmersIndex(idDairy, data),
  {
    cacheTime: 60000,
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 1,
    onError,
    select: (data) => {
      return data.data
    }
  }
)

const getFarmersOptions = (idDairy: string) => useQuery(
  'farmers-index-data',
  () => DairyApi.FarmersIndexData(idDairy),
  {
    cacheTime: 60000,
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 1,
    onError,
    select: (data) => {
      return data.data
    }
  }
)

const getEmailsOptions = (id) =>
  useQuery("farmers-emails-options", () => DairyApi.getEmailsOptions(id), {
    onError,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 1,
    select: (data) => {
      return data.data;
    },
  });

const getEmailsList = (id, data?) => useQuery(
  `farmers-emails-list-data-${data.search}`,
  () => DairyApi.getEmails(id, data),
  {
    onError,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 1,
    select: (data) => {
      return data.data;
    },
  });

export const FarmerQueries = {
  getFarmers,
  getFarmersOptions,
  getEmailsOptions,
  getEmailsList

};
