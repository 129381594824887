import React, { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { RouteTypes } from '~/Constants'
import Layout from '~/components/Layout'
import notify from '~/components/notify'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { createSftpAccount, editSftpAccount, updateSftpAccount } from '~/services/sftpAccounts'
import { FormFields } from '~/components/FormFields'
import InfoPopup from '~/components/InfoPopup'
import { Button, Form, Popup } from 'semantic-ui-react'

const CreateEditSftpAccount: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false)
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()
  const { handleSubmit, control, reset } = useForm({
    mode: 'onSubmit'
  })

  const folders = useFieldArray({
    control,
    //@ts-ignore
    name: 'folders'
  });

  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await editSftpAccount(id)
      response.data.folders = response.data?.folders.map(folder => ({ folder: folder })) || []
      reset(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderForm() {
    return <>
      <FormFields.Input
        name={'name'}
        rules={{
          required: {
            value: true,
            message: t('Please enter name')
          }
        }}
        control={control}
        placeholder={t('Enter name')}
        label={t('Name')}
      />
      <FormFields.Input
        name={'username'}
        control={control}
        placeholder={t('Enter username')}
        rules={{
          minLength: {
            value: 6,
            message: t('Min number of characters is 6.')
          },
          maxLength: {
            value: 32,
            message: t('Min number of characters is 32.')
          }
        }}
        label={<div style={{ display: 'flex', marginBottom: '0.5rem', color: '#5e6672' }}>
          <label>{t('Username')}</label>
          <InfoPopup
            content={t('If you do not choose a new one, the system will automatically generate one for you.')}
            styleIcon={{ marginLeft: 3, cursor: 'pointer' }}
          />
        </div>}
      />
      <FormFields.Input
        name={'password'}
        control={control}
        rules={{
          minLength: {
            value: 6,
            message: t('Min number of characters is 6.')
          },
          maxLength: {
            value: 32,
            message: t('Min number of characters is 32.')
          }
        }}
        placeholder={t('Enter password')}
        label={<div style={{ display: 'flex', marginBottom: '0.5rem', color: '#5e6672' }}>
          <label>{t('Password')}</label>
          <InfoPopup
            content={t('If you do not choose a new one, the system will automatically generate one for you.')}
            styleIcon={{ marginLeft: 3, cursor: 'pointer' }}
          />
        </div>}
      />
      <Form.Field
        style={{ display: 'flex', alignItems: 'baseline' }}
      >
        <label style={{ marginRight: 10 }}>{t('Folders')}</label>
        <Popup
          content={t('Add new folder')}
          trigger={
            <Button
              onClick={() => folders.append({ folder: '' })}
              primary
              size='mini'
              type={'button'}
              icon='plus'
            />
          }
        />
      </Form.Field>
      <>
        {folders.fields.map((folder, folderIndex) => {
          return <Form.Group key={folder.id}>
            <FormFields.Input
              control={control}
              width={12}
              placeholder={t('Folder')}
              label={t('New folder')}
              autoComplete='off'
              //@ts-ignore
              name={`folders[${folderIndex}].folder`}
            />
            <Form.Button
              style={{ marginLeft: 10, marginTop: 24 }}
              onClick={() => folders.remove(folderIndex)}
              size={'small'}
              basic
              color='red'
              type={'button'}
              icon='trash'
            />
          </Form.Group>
        })}
      </>
    </>
  }

  function renderActions() {
    return <div>
      <PageHeaderActionButton>{id ? t('Update') : t('Save')}</PageHeaderActionButton>
    </div>
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      data.folders = data?.folders.map(folder => folder.folder) || []
      if (id) {
        await updateSftpAccount(id, data)
        notify(t('Successfully updated sftp account'), 'success')
      } else {
        await createSftpAccount(data)
        notify(t('Successfully created sftp account'), 'success')
      }
      history.push(RouteTypes.SFTP_ACCOUNTS)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  return <Layout
    title={id ? ('Edit SFTP account') : t('Create SFTP account')}
    isBusy={isBusy}
    segmented
    close={RouteTypes.SFTP_ACCOUNTS}
    actions={renderActions()}
    onSubmit={handleSubmit(onSubmit)}
  >
    {renderForm()}
  </Layout>

}
export default CreateEditSftpAccount