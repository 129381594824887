import React, {useEffect, useState} from 'react'
import {Control, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Dropdown, Form, Input} from 'semantic-ui-react'
import {FormFields} from '~/components/FormFields'

interface SupportProps {
  index: number,
  control: Control<any>,
  setValue: any,
  watch: any,
  farmerGroupOptions: any
}

const Support = (props: SupportProps) => {
  const {control, farmerGroupOptions, setValue, watch} = props
  const [index, setIndex] = useState<number>(props.index)
  const {t} = useTranslation()

  const watchPhoneCodeField = watch(`support.languages[${index}].phonePrefix`)
  const wathcMobileCodeField = watch(`support.languages[${index}].mobilePrefix`)
  const mobileNumber = watch(`support.languages[${index}].mobileNumber`)
  const phoneNumber = watch(`support.languages[${index}].phoneNumber`)
  const wathcDescription = useWatch({control, name: `support.languages[${index}].descriptionStatus`})
  const watchFooter = useWatch({control, name: `support.languages[${index}].footerDescriptionStatus`})
  const watchPhone = useWatch({control, name: `support.languages[${index}].phoneStatus`})
  const watchTelefax = useWatch({control, name: `support.languages[${index}].faxStatus`})
  const watchEmail = useWatch({control, name: `support.languages[${index}].emailStatus`})

  useEffect(() => {
    setIndex(props.index)
  },[props.index])

  return <div style={{display: 'flex'}}>
    <div style={{width: '45%', marginRight: 30}}>
      <FormFields.Input
        label={t('Tooltip')}
        name={`support.languages[${index}].tooltip` as any}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('Please enter tooltip')
          }
        }}
        placeholder={t('Tooltip')}
      />
      <FormFields.Checkbox
        label={t('Description')}
        control={control}
        name={`support.languages[${index}].descriptionStatus` as any}
      />
      <FormFields.TextArea
        name={`support.languages[${index}].description` as any}
        control={control}
        disabled={!wathcDescription}
        placeholder={t('Description')}
      />
      <FormFields.Checkbox
        label={t('Footer Description')}
        control={control}
        name={`support.languages[${index}].footerDescriptionStatus` as any}
      />
      <FormFields.TextArea
        control={control}
        name={`support.languages[${index}].footerDescription` as any}
        placeholder={t('Footer description')}
        disabled={!watchFooter}
      />
    </div>
    <div style={{width: '45%'}}>
      <FormFields.Checkbox
        label={t('Phone Number')}
        control={control}
        name={`support.languages[${index}].phoneStatus` as any}
      />
      <Form.Field disabled={!watchPhone}>
        <Input
          value={phoneNumber}
          label={<Dropdown
            options={farmerGroupOptions?.countries.map(countryCode => ({value: countryCode.id, text: countryCode.phoneCode}))}
            selectOnBlur={false}
            value={watchPhoneCodeField}
            scrolling
            basic
            placeholder={t('Code')}
            onChange={(e,data) => setValue(`support.languages[${index}].phoneCode`, data.value)}
            name={`support.languages[${index}].phoneCode`}
          />}
          onChange={(e,data) => {
            setValue(`support.languages[${index}].phoneNumber`, data.value)
          }}
          labelPosition='left'
          placeholder={watchPhoneCodeField ? t('Phone number') : t('Please select phone code')}
        />
      </Form.Field>
      <FormFields.Checkbox
        label={t('Telefax Number')}
        control={control}
        name={`support.languages[${index}].faxStatus` as any}
      />
      <Form.Field disabled={!watchTelefax}>
        <Input
          value={mobileNumber}
          label={<Dropdown
            scrolling
            basic
            options={farmerGroupOptions?.countries.map(countryCode => ({value: countryCode.id, text: countryCode.phoneCode}))}
            selectOnBlur={false}
            value={wathcMobileCodeField}
            onChange={(e,data) => setValue(`support.languages[${index}].faxCode`, data.value)}
            placeholder={t('Code')}
            name={`support.languages[${index}].faxCode`}
          />}
          onChange={(e,data) => setValue(`support.languages[${index}].faxNumber`, data.value)}
          labelPosition='left'
          placeholder={wathcMobileCodeField ? t('Mobile number') : t('Please select mobile code')}
          name={`support.languages[${index}].faxNumber`}
        />
      </Form.Field>
      <FormFields.Checkbox
        label={t('Email')}
        control={control}
        name={`support.languages[${index}].emailStatus` as any}
      />
      <FormFields.Input
        name={`support.languages[${index}].email` as any}
        control={control}
        placeholder={t('Email')}
        disabled={!watchEmail}
      />
    </div>
  </div>
}
export default Support
