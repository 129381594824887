import api from "./api"

// Milk Types 

export const MilkTypes = (id: string, data?) =>
  api.post(`/dairies/${id}/milk-types/index`, data)

export const MilkTypesOptions = (id: string) =>
  api.post(`/dairies/${id}/milk-types/get-options-data`)

export const MilkTypeStore = (id: string, data) =>
  api.post(`/dairies/${id}/milk-types/store`, data)

export const MilkTypeEdit = (idDiary: string, idMilkType: string) =>
  api.post(`/dairies/${idDiary}/milk-types/${idMilkType}/edit`)

export const MilkTypeUpdate = async ({ idDiary, idMilkType, data }): Promise<any> => {
  const response = await api.post(`/dairies/${idDiary}/milk-types/${idMilkType}/update`, data)

  return response.data;
};

export const MilkTypeDelete = (idDiary: string, idMilkType: string) =>
  api.post(`/dairies/${idDiary}/milk-types/${idMilkType}/delete`)


// Mga factors

export const GetMgaFactors = (id: string, data?) =>
  api.post(`/dairies/${id}/mga-factors/index`, data)

export const ReorderMgaFactors = (id: string, data?) =>
  api.post(`/dairies/${id}/mga-factors/reorder`, data)

export const MgaFactorEdit = (id: string, idMga: string) =>
  api.post(`/dairies/${id}/mga-factors/${idMga}/edit`)

export const UpdateMgaFactor = (id: string, idMga: string, data) =>
  api.post(`/dairies/${id}/mga-factors/${idMga}/update`, data)

// Milk Quality Classes

export const GetMilkQualityClasses = (id: string, data?) =>
  api.post(`/dairies/${id}/milk-quality-classes/index`, data)

export const ReorderMilkQualityClasses = (id: string, data?) =>
  api.post(`/dairies/${id}/milk-quality-classes/reorder`, data)

export const MilkQualityClassEdit = (id: string, IdMilkQualityClass: string) =>
  api.post(`/dairies/${id}/milk-quality-classes/${IdMilkQualityClass}/edit`)

export const UpdateMilkQualityClass = (id: string, IdMilkQualityClass: string, data) =>
  api.post(`/dairies/${id}/milk-quality-classes/${IdMilkQualityClass}/update`, data)
