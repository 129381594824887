import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import notify from '~/components/notify'
import { DairyApi } from '~/services'
import { Link, useParams } from 'react-router-dom'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { RouteTypes } from '~/Constants'
import { CustomRow, StyledGrid, StyledGridColumns, StyledGridRow, StyledSegment } from './style'
import { Divider, Grid, Icon } from 'semantic-ui-react'

const ShowStoneX: React.FC = () => {
  const { t } = useTranslation()
  const { id, idAccount } = useParams()
  const [isBusy, setIsBusy] = useState(false)
  const [showData, setShowData] = useState(null)

  useEffect(() => {
    fetchShowData()
  }, [])

  async function fetchShowData() {
    setIsBusy(true)
    try {
      const response = await DairyApi.ShowStoneXAccount(id, idAccount)
      setShowData(response.data)
    }
    catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderShow() {
    return <StyledSegment placeholder>
      <StyledGrid columns={2} stackable>
        <Divider vertical style={{ margin: 0 }} />
        <Grid.Column>
          <Grid columns={2} stackable>
            <StyledGridRow>
              <Grid.Column>{t('Currency')}</Grid.Column>
              <StyledGridColumns>{showData?.accountBaseCurrency}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Account Nr.')}</Grid.Column>
              <StyledGridColumns>{showData?.accountNumber}</StyledGridColumns>
            </StyledGridRow>
            <CustomRow>
              <Grid.Column>{t('Api key')}</Grid.Column>
              <StyledGridColumns>{showData?.apiKey || '-'}</StyledGridColumns>
            </CustomRow>
            <StyledGridRow>
              <Grid.Column>{t('Asset class')}</Grid.Column>
              <StyledGridColumns>{showData?.assetClass}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Company country')}</Grid.Column>
              <StyledGridColumns>{showData?.companyCountry}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Locality')}</Grid.Column>
              <StyledGridColumns>{showData?.companyLocality}</StyledGridColumns>
            </StyledGridRow>
            <CustomRow>
              <Grid.Column>{t('Company name')}</Grid.Column>
              <StyledGridColumns>{showData?.companyName}</StyledGridColumns>
            </CustomRow>
          </Grid>
        </Grid.Column>
        <Grid.Column>
          <Grid columns={2} stackable style={{ marginLeft: 5 }}>
            <StyledGridRow>
              <Grid.Column>{t('Postal code')}</Grid.Column>
              <StyledGridColumns>{showData?.companyPostalCode}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Street address')}</Grid.Column>
              <StyledGridColumns>{showData?.companyStreetAddress}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Interval')}</Grid.Column>
              <StyledGridColumns>{showData?.interval}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Name')}</Grid.Column>
              <StyledGridColumns>{showData?.name}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Username')}</Grid.Column>
              <StyledGridColumns>{showData?.username}</StyledGridColumns>
            </StyledGridRow>
            <StyledGridRow>
              <Grid.Column>{t('Connected')}</Grid.Column>
              <StyledGridColumns>
                <Icon
                  name={showData?.connected ? 'check circle outline' : 'close'}
                  color={showData?.connected ? 'green' : 'red'}
                />
              </StyledGridColumns>
            </StyledGridRow>
          </Grid>
        </Grid.Column>
      </StyledGrid>
    </StyledSegment>
  }

  function renderActions() {
    return <PageHeaderActionButton
      as={Link}
      to={RouteTypes.DAIRY_FACTORY_VIEW_STONEX.replace(':id', id)}
    >
      {t('Cancel')}
    </PageHeaderActionButton>
  }

  return <DairyViewTabsLayout
    header={t('Show StoneX accounts')}
    isBusy={isBusy}
    actions={renderActions()}
    segmented
  >
    {renderShow()}
  </DairyViewTabsLayout>
}
export default ShowStoneX