import React from 'react';
import { Button, Dimmer, Icon, Loader, Modal, ModalProps } from 'semantic-ui-react';
import LoaderMilkCan from '../Loader';
import { StyledAbortModal, StyledModal, StyledPreviewModal } from './style'

type DialogProps = {
  textSubmit?: string;
  textClose?: string;
  textContent: string | React.ReactElement;
  textHeader: string;
  isDialogOpen?: boolean;
  handleClose?: () => void;
  handleSubmit?: (...args) => any;
  size?: 'small' | 'mini' | 'tiny' | 'large' | 'fullscreen';
  isLoading?: boolean;
  type?: 'abort' | 'dialog' | 'preview'
  disableSubmit?: boolean
  onMount?: (...args) => any,
  style?: any
  htmlContent?: any
  hideCloseIcon?: boolean
  noScrollingContent?: boolean,
  heightDialog?: string | number
  contentAutoSize?: boolean
}

type DialogPropsCombined = DialogProps & ModalProps

const Dialog: React.FunctionComponent<DialogPropsCombined> = (props) => {
  const {
    size,
    isDialogOpen,
    handleSubmit,
    handleClose,
    textSubmit,
    textClose,
    textContent,
    textHeader,
    type,
    onMount,
    
    heightDialog,
    style,
    ...modalProps
  } = props;

  function renderDialog() {
    return <StyledModal
      onClose={() => handleClose()}
      size={size}
      open={isDialogOpen}
      onMount={onMount}
      style={style}
      {...modalProps}
    >
      <Modal.Header>
        <div className='header-title'>{textHeader}</div>
        {!props.hideCloseIcon && <Icon onClick={() => handleClose()} className='header-close-icon' name='close' />}
      </Modal.Header>
      <Modal.Content scrolling={!props.noScrollingContent}>
        <Dimmer inverted active={props.isLoading}>
          <LoaderMilkCan isLoading={props.isLoading} />
        </Dimmer>
        {!props.htmlContent ? <p>{textContent}</p> : textContent}
      </Modal.Content>
      <Modal.Actions>
        {textClose && <Button negative onClick={() => handleClose()}>
          {textClose}
        </Button>
        }
        {textSubmit && <Button
          disabled={Boolean(props.disableSubmit)}
          loading={props.isLoading}
          positive onClick={() => handleSubmit()}
        >
          {textSubmit}
        </Button>
        }
      </Modal.Actions>
    </StyledModal>
  }

  function renderPreviewModal() {
    return <StyledPreviewModal
      onClose={() => handleClose()}
      size={size}
      open={isDialogOpen}
      onMount={onMount}
      style={style}
      {...modalProps}
      height={heightDialog}
    >
      <Modal.Header>
        <div className='header-title'>{textHeader}</div>
        <Icon onClick={() => handleClose()} className='header-close-icon' name='close' />
      </Modal.Header>
      <Modal.Content>
        <Dimmer inverted active={props.isLoading}>
          <Loader active={props.isLoading} />
        </Dimmer>
        {textContent}
      </Modal.Content>
    </StyledPreviewModal>
  }

  function renderAbortModal() {
    return <StyledAbortModal
      open={Boolean(isDialogOpen)}
      onClose={() => handleClose()}
      size={props.size || 'tiny'}
      {...modalProps}
    >
      <Modal.Header>
        <div className='header-title'>{textHeader}</div>
        <Icon onClick={() => handleClose()} className='header-close-icon' name='close' />
      </Modal.Header>
      <Modal.Content>
        <div>{textContent}</div>
      </Modal.Content>
      <Modal.Actions>
        {textSubmit && <Button
          disabled={Boolean(props.disableSubmit)}
          onClick={() => handleSubmit()}
          positive
          loading={props.isLoading}
        >
          {textSubmit}
        </Button>
        }
        {textClose && <Button onClick={() => handleClose()} negative>
          {textClose}
        </Button>
        }
      </Modal.Actions>
    </StyledAbortModal>
  }

  function renderModalBasedOnType() {
    switch (type) {
      case 'abort':
        return renderAbortModal()
      case 'dialog':
        return renderDialog()
      case 'preview':
        return renderPreviewModal()
      default:
        return renderDialog()
    }
  }

  return renderModalBasedOnType()
};

export default Dialog;
