import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

interface StyledModalProps {
	contentAutoSize?: boolean
}

export const StyledAbortModal = styled(Modal)`
.header {
	display: flex !important;
	align-items: baseline;
	justify-content: space-between;
	border-bottom: none;
	padding: 15px 10px !important;
}

.content {
	min-height: 100px;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	text-align: center;
}

.header-title {
	font-size: 20px !important;
}

.header-close-icon {
	font-size: 20px !important;
	cursor: pointer;
}

.actions {
	border-top: none;
}
`
export const StyledModal = styled(Modal) <StyledModalProps>`
.header {
	display: flex !important;
	align-items: baseline;
	justify-content: space-between;
	border-bottom: none;
	padding: 15px 10px !important;
}

.content {
	min-height: ${props => props.contentAutoSize ? 'auto' : '200px'};
	position: relative;
}

.header-title {
	font-size: 20px !important;
}

.header-close-icon {
	font-size: 20px !important;
	cursor: pointer;
}
`

export const StyledPreviewModal = styled(Modal) <{ height?: any }>`
height: ${props => props.height || '90vh'} !important;
 .content {
  height: 100% !important;
 }

 .header {
	display: flex !important;
	align-items: baseline;
	justify-content: space-between;
	border-bottom: none;
	padding: 15px 10px !important;
}

.content {
	min-height: 200px;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	text-align: center;
}

.header-title {
	font-size: 20px !important;
}

.header-close-icon {
	font-size: 20px !important;
	cursor: pointer;
}
`