import styled from 'styled-components'
import { Button, Menu, Segment } from 'semantic-ui-react'

export const CardSettingsWrapper = styled.div`
flex: 0 0 30.3333333333%;
max-width: 30.3333333333%; 
min-width: 300px;
margin: 10px;
.cardSettings {
  margin-bottom: 20px;   
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: #f8f8f9;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 10px;
}
`

export const StyledButton = styled(Button)`
margin: 1em 0 !important;
width: fit-content;
background-color: #1a3175 !important;
color: #FFFFFF !important;
`
export const HeaderDiv = styled.div`
  display: flex;  
  border: none !important; 
  border-radius: 10px !important;
  justify-content: space-between;
  align-items: baseline;
  & > h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      cursor: default;
    }
  }
`

export const ActionsDiv = styled.div`
  display: flex;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  justify-content: space-around;
`
export const StyledViewDairyFooter = styled(Segment)`
  height: 100%;
  border: none;
  padding: 0px !important;
  margin: -10px 0px 0px 0px !important;
  .segment {
    padding: 20px;
  }
`

export const TableFooterWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

export const StyledSegment = styled(Segment)`
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px !important;
`

export const StyledMenu = styled(Menu) <{ hasChilred?: boolean }>`
border: none !important;
border-right: 1px solid #e5e7eb !important;
height: 100%;
overflow-x: hidden;
box-shadow: none !important;

.titleWrapper {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

>div {
	cursor: pointer;
	border: 1px solid #e5e7eb !important border-radius: 10px !important;
}

>a.item,
>div.item {
	margin: 15px;
	border: 1px solid #e5e7eb !important;
	border-radius: 10px !important;

	&:hover {
		background-color: #304993 !important;
		color: #ffffff !important;

		.icon {
			color: white !important;
		}
	}

	&.active {
    transition: 0.2 seconds easy !important;
		border-right: none;
		background: #044395 !important;
		color: white !important;

		.icon {
			color: white !important;
		}
	}

	*.item {
		margin: 15px;
		border-radius: 10px !important;

		&:hover {
			background-color: #304993 !important;
			color: #ffffff !important;

			.icon {
				color: white !important;
			}
		}

		&.active {
      transition: 0.2 seconds easy !important;
			border-right: none;
			background: #044395 !important;
			color: white !important;

			.icon {
				color: white !important;
			}
		}
	}
  .menu {
    margin: 20px 5px !important;
    .item {
     padding-left: 0.9em !important;
     font-size: 14px !important;
     margin: 5px 0px 0px 0px !important;
     padding: 10px !important; 
     border: 1px solid #e5e7eb !important;
     border-radius: 10px !important;
    }
     a.item {   
     &.active {      
       transition: 0.2 seconds easy !important;
       background-color: #304993 !important;
       color: white !important;
     }
    }
  } 
}

>div.item {
	&:hover {
		background-color: white !important;
		color: #044395 !important;
		.icon {
			color: #044395 !important;
		}
	}
}

.icon {
	color: #044395;
}

a.item::before,
div.item::before {
	margin-top: -5px !important;
	display: none;
}

a.item::after {
	margin-top: -5px !important;
	display: none;
}

.itemWrapper {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}
`
export const StyledMenuItem = styled(Menu.Item)`

`