import React from 'react';
import { RegisterOptions, ControllerFieldState } from 'react-hook-form';

interface IFormFieldError {
  content: string;
}

export function getError(fieldState: ControllerFieldState, noErrorMessage?): IFormFieldError | boolean {
  return noErrorMessage ? true : {
    content: fieldState.error.message || 'Please fill in this field'
  };
}

export function getLabel(
  label: string | JSX.Element,
  rules: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>,
  withoutAsterisk?: boolean
): string | JSX.Element {
  if (typeof label === 'object') {
    return label
  } else {
    if (rules && rules.required) {
      return (
        <label style={{ textAlign: 'left', display: 'block', width: 'fit-content' }}>
          {label}
          {!withoutAsterisk && <span style={{ color: 'red' }}>*</span>}
        </label>
      );
    }
    return label ? <label>{label}</label> : null;
  }
}
