import api from './api'

export const getUsers = (data?) =>
  api.post<GetIndexUserType>('/users/index', data)

export const getOptionsForUsers = () =>
  api.post<UserOptionsType>('/users/get-options-data')

export const createUser = (data:UserType) =>
  api.post('/users/store', data)

export const getUser = (id: string) =>
  api.post<UserType>(`/users/${id}/edit`)

export const getUserProfile = (id: string) =>
  api.post<UserType>(`/users/${id}/get-file`)

export const updateUser = (id: string, data: UserType) =>
  api.post(`/users/${id}/update`, data)

export const blockUser = (id: string) =>
  api.post(`/users/${id}/block`)

export const unblockUser = (id: string) =>
  api.post(`/users/${id}/unblock`)

export const deleteUser = (id: string) =>
  api.post(`/users/${id}/delete`)

type Nullable<T> = T | null

type UserOptionsCountryType = {
  id: number,
  iso: string,
  phoneCode: string,
  label: string
}

type UserOptionsRoleType = {
  id: number,
  slug: string,
  label: string
}

export interface UserOptionsType {
  countries: UserOptionsCountryType[]
  roles: UserOptionsRoleType[]
}
export interface UserType {
  email: string
  firstName: string
  id?: string
  lastName: string
  mobileNumber: Nullable<string>
  mobilePrefix: Nullable<string>
  phoneNumber: Nullable<string>
  phonePrefix: Nullable<string>
  photo: Nullable<string>
  roleId: number
  verified: number
}

export type GetIndexUserTypeRow = {
  createdAt: string
  email: string
  id: string
  lastLogin: Nullable<string>
  loggedIn: number
  name: string
  roleId: number
  roleLabel: string
  roleSlug: string
  status: number
  verified: number
}
export interface GetIndexUserType {
  filtered: number,
  rows: GetIndexUserTypeRow[],
  total: number
}
