import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Image, Transition } from 'semantic-ui-react';
import { DairyApi } from '~/services';
import { LoaderWrapper } from '~/pages/DairyFactory/style';

interface LazyLoadProps {
  name?: string;
  alt?: string;
  id: string
  type?: string
}

const LazyLoadImage: React.FC<LazyLoadProps> = (props) => {
  const elementRef = useRef<HTMLElement>(null);
  const { alt, id, type } = props
  const { } = useTranslation()
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState('')
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            if (elementRef.current) {
              try {
                const response = await DairyApi.getDairyLogo(id)
                setSrc(response.data)
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                setSrc('/assets/no-image.png')
                if (error?.response?.data) {
                  //  notify(error?.response?.data?.message, 'error');
                } else {
                  // notify(t('General error'), 'error');
                }
              }
            }
            observer.disconnect();
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    elementRef.current = document.getElementById(id)
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
  }, [id, type]);

  useEffect(() => {
    if (!isLoading) {
      setIsVisible(true);
    }
  }, [isLoading]);

  function renderItem() {
    return <Image floated='right' size='tiny' className='img-shadow' src={src} alt={alt} />
  }

  return (
    <Dimmer.Dimmable dimmed={false}>
      {!isVisible && <LoaderWrapper>
        <div className='loaderWrapper'>
          <Loader active />
        </div >
      </LoaderWrapper>
      }
      <Transition visible={isVisible} animation='fade' duration={300}>
        {renderItem()}
      </Transition>
    </Dimmer.Dimmable>
  );
};

export default LazyLoadImage;