import { Accordion, Loader } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledAccordion = styled(Accordion)`
margin: 20px 0px;
width: 100% !important;
margin-top: 15px;
 .title {
   display: flex;
   align-items: baseline;
   justify-content: space-between;
   color: black !important;
 }
`
export const StyledLoader = styled(Loader)`
width: 1em !important;
height: 1em !important;
top: 0 !important;
left: 0 !important;
margin: 0em 1em !important;
position: inherit !important;
`