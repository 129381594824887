import React from 'react'
import {CardProps} from 'semantic-ui-react'
import {BoxContainer} from './style'

const Box: React.FC<CardProps> = props => {

  return <BoxContainer fluid={props.fluid}>
    <BoxContainer.Content header={props.header} />
    <BoxContainer.Content description={props.content} />
  </BoxContainer>
}

export default Box