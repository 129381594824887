import React, { useState, useRef, useEffect } from 'react';
import { DropdownProps } from 'semantic-ui-react';
import { FormFields } from '../FormFields';
import { useDebounce } from 'use-lodash-debounce';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { DairyApi } from '~/services';
import { useParams } from 'react-router-dom'
interface Option {
  value: string;
  text: string;
}

interface Props {
  options?: Option[];
  control: any
}

const InfinitiveScrollDropdown: React.FC<Props & DropdownProps> = (props) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation()
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(props.options);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [startValue, setStartValue] = useState([])
  const pageRef = useRef(null)
  const isFirstRun = useRef(true); // add a ref to track the first run of useEffect
  const debouncedValueFarmer = useDebounce(searchQuery, 300)
  const watchValue = useWatch({ control: props.control, name: props.name })
  const [storeSelectedItem, setStoreSelectedItem] = useState([])

  useEffect(() => {
    if (watchValue && !options.find(option => option.value === watchValue)) {
      fetchFarmerAndAddToOptions()
    }
  }, [])

  async function fetchFarmerAndAddToOptions() {
    setLoading(true);
    try {
      const response = await DairyApi.FarmerShow(id, watchValue)
      setOptions([...options, { value: watchValue, text: response.data?.farmer?.companyName }])
      setStartValue([{ value: watchValue, text: response.data?.farmer?.companyName }])
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    pageRef.current = page
  }, [page])

  useEffect(() => {
    if (open) { // only call handleSearchFarmers() after the first run
      loadMoreOptions({ page: 0, options: [], hasMore: true })
    } else {
      isFirstRun.current = false; // update ref after the first run
    }
  }, [debouncedValueFarmer]);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (!dropdown) {
      return;
    }

    function handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } = dropdown;

      if (scrollTop + clientHeight >= scrollHeight) {
        loadMoreOptions();
      }
    }
    dropdown.addEventListener('scroll', handleScroll);

    return () => {
      dropdown.removeEventListener('scroll', handleScroll);
    };
  }, [open, hasMore]);

  const loadMoreOptions = async (defaultValues = null) => {
    const hasMoreWithSearch = defaultValues ? defaultValues.hasMore : hasMore
    if (loading || !hasMoreWithSearch) {
      return;
    }

    setLoading(true);

    try {
      const response = await DairyApi.FarmersList(id, { page: defaultValues ? defaultValues.page : pageRef.current, search: searchQuery, isMain: 1 })
      const newOptions = response.data.map(item => ({ value: item.id, text: item.companyName }))
      if (newOptions) {
        setOptions((prevOptions) => defaultValues ? [...newOptions] : [...prevOptions, ...newOptions]);
        setHasMore(response.data.length !== 0);
        setPage(defaultValues ? defaultValues?.page + 1 : pageRef.current + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event: React.SyntheticEvent<HTMLElement>, data: { searchQuery: string }) => {
    setSearchQuery(data.searchQuery);
  };

  const handleOpen = () => {
    if (!dropdownRef.current) {
      const parentElement = document.getElementById('dropdown-farmers-menu-create');

      // Get all child elements of the parent element
      const children = parentElement.children;

      // Filter the child elements to select only those with the specified class
      const visibleMenuTransitions = Array.from(children).filter(child => {
        return child.className.toString() === 'menu transition';
      });
      dropdownRef.current = visibleMenuTransitions[0] as HTMLDivElement
      setOpen(true)
      setOptions([...props.options, ...startValue])
    }
  };

  const handleClose = () => {
    dropdownRef.current = null;
    setOpen(false)
    if (storeSelectedItem.length > 0) {
      setOptions(storeSelectedItem)
    }
  };

  return (
    <FormFields.Select
      {...props}
      control={props.control}
      onChange={(e, data) => {
        props.setValue(props.name, data.value)
        setSearchQuery('')
        if (data.value) {
          const option = options.find(option => option.value === data.value)
          setStoreSelectedItem([option])
          setOptions([option])
        } else {
          setStoreSelectedItem([])
        }
      }}
      placeholder={t('Select farmer')}
      selection
      clearable
      onClose={handleClose}
      name={props.name}
      searchQuery={searchQuery}
      loading={loading}
      value={watchValue}
      id='dropdown-farmers-menu-create'
      search={(options, query) => {
        return options;
      }}
      options={options}
      ref={dropdownRef}
      onSearchChange={handleSearchChange}
      onOpen={handleOpen}
    />
  );
};

export default InfinitiveScrollDropdown;