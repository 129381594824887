import { Loader } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledLoader = styled(Loader)`
width: 1em !important;
height: 1em !important;
top: 0 !important;
left: 0 !important;
margin: 0em 1em !important;
position: inherit !important;
`
export const StyledTitle = styled.div`
position: relative;
display: flex;
align-items: baseline;
color: ${props => props.color};
`
export const AccordionTittleWrapper = styled.div`
width: 100%;
display: flex;
align-items: baseline;
justify-content: space-between;
`