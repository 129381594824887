import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import i18n from '~/components/i18n';
import TableComponent from '~/components/TableComponent';
import { FarmerQueries } from '~/QueryHooks/FarmerQueries';
import { useDebounce } from 'use-lodash-debounce';
import { Dropdown, Input } from 'semantic-ui-react';
import DairyViewTabsLayout from '../../DairyViewTabsLayout';
import { DateInput } from 'semantic-ui-calendar-react';
import { useSelector } from 'react-redux';
import { SystemDataState } from '~/reducers';
import { SystemDataType } from '~/services/login';
import { FilterWrapper } from '~/pages/DairyFactory/style';

const defaultTableColumnHeaders = [
  {
    name: i18n.t('Name'),
    key: 'name',
  },
  {
    name: i18n.t('Email'),
    key: 'email',
  },
  {
    name: i18n.t('Status'),
    key: 'status',
    type: 'email-status',
  },
  {
    name: i18n.t('Type'),
    key: 'type',
    type: 'email-type',
  },
  {
    name: i18n.t('Subject'),
    key: 'subject',
  },
  {
    name: i18n.t('Sent date'),
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
    key: 'sentDate',
  },
  {
    name: i18n.t('Delivered date'),
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
    key: 'deliveredDate',
  },
  {
    name: i18n.t('Opened date'),
    type: 'date',
    format: 'DD.MM.YYYY HH:mm',
    key: 'deliveredDate',
  },
];
const Emails: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [dataFiltered, setDataFiltered] = useState({
    status: '',
    type: '',
    sentDateFrom: '',
    sendDateTo: '',
    search: '',
    column: '',
    sort: '',
    page: 0,
    rows: 30,
  });
  const [search, setSearch] = useState('');
  const [tableColumnHeaders, setTableColumnHeaders] = useState(
    defaultTableColumnHeaders
  );
  const systemData = useSelector<SystemDataState, SystemDataType>(
    (state) => state.systemData
  );
  const language = systemData?.languages.find(
    (language) => language.id === systemData.logged.languageId
  );
  const languageOptions = systemData?.languages.filter(
    (language) => language.code === 'en' || language.code === 'de'
  );

  const debouncedValue = useDebounce(search, 300);

  const { data: emailsOptions, isLoading: isBusyEmailOptions } =
    FarmerQueries.getEmailsOptions(id);

  const {
    data: emails,
    isLoading: isBusyEmails,
    refetch: fetchFilteredData,
    isFetching: isFetching,
  } = FarmerQueries.getEmailsList(id, {
    ...dataFiltered,
    page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1,
    sentDateFrom:
      dataFiltered.sentDateFrom !== ''
        ? parseDate(dataFiltered.sentDateFrom)
        : '',
    sentDateTo:
      dataFiltered.sendDateTo !== '' ? parseDate(dataFiltered.sendDateTo) : '',
  });

  function parseDate(value) {
    const arrayOfValues = value.split('.');
    return `${arrayOfValues[2]}-${arrayOfValues[1]}-${arrayOfValues[0]}`;
  }

  useEffect(() => {
    fetchFilteredData();
  }, [dataFiltered]);

  useEffect(() => {
    setDataFiltered({
      ...dataFiltered,
      search: debouncedValue,
      page: 0,
    });
  }, [debouncedValue]);

  function renderEmails() {
    return (
      <TableComponent
        tableColumnHeaders={tableColumnHeaders}
        dataFiltered={dataFiltered}
        handleChangeDataFiltered={(data, tableColumnHeaders) => {
          setTableColumnHeaders(tableColumnHeaders);
          setDataFiltered(data);
        }}
        rows={!isFetching ? rows || [] : []}
        animated
        upward
        isFetchingData={isFetching}
        total={filtered}
      />
    );
  }
  function renderFilters() {
    return (
      <FilterWrapper>
        <Input
          icon={{ name: 'search', circular: true, link: true }}
          placeholder={t('Search...')}
          value={search}
          onChange={(e, data) => setSearch(data.value)}
        />
        <Dropdown
          clearable
          selection
          selectOnBlur={false}
          options={
            emailsOptions?.types?.map((emailOption) => ({
              value: emailOption.key,
              text: emailOption.label,
            })) || []
          }
          placeholder={t('Select type...')}
          onChange={(_, data) =>
            setDataFiltered({
              ...dataFiltered,
              //@ts-ignore
              type: data.value,
              page: 0,
            })
          }
        />

        <Dropdown
          selection
          clearable
          selectOnBlur={false}
          options={
            emailsOptions?.statuses?.map((emailOption) => ({
              value: emailOption.key,
              text: emailOption.label,
            })) || []
          }
          placeholder={t('Select status...')}
          onChange={(_, data) =>
            setDataFiltered({
              ...dataFiltered,
              //@ts-ignorec
              status: data.value,
              page: 0,
            })
          }
        />

        <DateInput
          name='date'
          autoComplete='off'
          placeholder={t('Date from')}
          closable
          clearIcon={'close'}
          clearable
          localization={language?.code}
          closeOnMouseLeave={false}
          dateFormat={'DD.MM.YYYY'}
          className={'datePicker'}
          value={dataFiltered.sentDateFrom}
          iconPosition='left'
          onChange={(event, { name, value }) =>
            setDataFiltered({
              ...dataFiltered,
              sentDateFrom: value,
              page: 0,
            })
          }
        />
        <DateInput
          name='date'
          autoComplete='off'
          placeholder={t('Date to')}
          closable
          clearIcon={'close'}
          clearable
          localization={language?.code}
          closeOnMouseLeave={false}
          dateFormat={'DD.MM.YYYY'}
          className={'datePicker'}
          minDate={dataFiltered.sentDateFrom}
          value={dataFiltered.sendDateTo}
          iconPosition='left'
          onChange={(event, { name, value }) => {
            setDataFiltered({
              ...dataFiltered,
              sendDateTo: value,
              page: 0,
            });
          }}
        />
      </FilterWrapper>
    );
  }
  const { filtered, rows } = emails ? emails : { filtered: 0, rows: [] };
  return (
    <DairyViewTabsLayout
      header={t('Emails')}
      isBusy={isBusyEmails}
      segmented
      search={renderFilters()}
    >
      {renderEmails()}
    </DairyViewTabsLayout>
  );
};
export default Emails;
