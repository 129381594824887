import React, { useEffect } from 'react'
import { useController, UseControllerProps } from 'react-hook-form'
import { Form, SelectProps } from 'semantic-ui-react'
import { getLabel, getError } from './util'

interface SelectFormFiled extends UseControllerProps {
  label?: string | JSX.Element,
  value?: any,
  control: any
  style?: any
}

type SelectFormFiledProps = SelectFormFiled & SelectProps

const Select: React.FC<SelectFormFiledProps> = props => {
  const { field, fieldState } = useController(props)
  const fieldTemp = { ...field, ref: null }
  const { control, label, rules, value, options, defaultValue, ...selectProps } = props

  function notInViewport(element, offset = 0) {
    if (!element) return false
    const top = element.getBoundingClientRect().top
    return (top + offset) >= 0 && (top - offset) >= window.innerHeight
  }

  useEffect(() => {
    if (fieldState.error) {
      const element = document.getElementById(fieldTemp.name)
      if (notInViewport(element)) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [fieldState])

  return <Form.Select
    id={fieldTemp.name}
    error={fieldState.error ? getError(fieldState) : false}
    options={options || []}
    {...fieldTemp}
    value={value ? value : fieldTemp.value}
    onChange={(e, data) => fieldTemp.onChange(data.value)}
    {...selectProps}
    label={getLabel(label, rules)}
    style={props.style}
  />
}
export default Select
