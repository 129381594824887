import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'
import { RouteTypes, optionsLimit } from '~/Constants'
import { UsersApi } from '~/services'
import { Button, Dropdown, Icon, Label, Pagination, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import notify from '~/components/notify'
import InlineDialog from '~/components/InlineDialog'
import { GetIndexUserTypeRow } from '~/services/users'
import { TableFooterWrapper } from './style'
import { useSelector } from 'react-redux'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import i18n from '~/components/i18n'

const tableColumns = [
  {
    label: i18n.t('Name'),
    value: 'name'
  },
  {
    label: i18n.t('Email'),
    value: 'email'
  },
  {
    label: i18n.t('Role'),
    value: 'roleLabel'
  },
  {
    label: i18n.t('Verified'),
    value: 'verified'
  },
  {
    label: i18n.t('Action'),
    value: <Button basic icon='times circle outline' />
  }
]

const Users: React.FC = () => {
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const { t } = useTranslation()
  const [users, setUsers] = useState<GetIndexUserTypeRow[]>([])
  const [isBusy, setIsBusy] = useState(false)
  const [total, setTotal] = useState(0)
  const [dataFiltered, setDataFiltered] = useState({
    page: 0,
    rows: systemData.defaultRowsPerPage
  })

  useEffect(() => {
    fetchUsersData()
  }, [])

  useEffect(() => {
    fetchFilteredData()
  }, [dataFiltered])

  async function fetchFilteredData() {
    setIsBusy(true)
    try {
      const response = await UsersApi.getUsers({ ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })
      setUsers(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function fetchUsersData() {
    setIsBusy(true)
    try {
      const response = await UsersApi.getUsers()
      setTotal(response.data.total)
      setUsers(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function tableHeaders() {
    return <Table.Row>
      {tableColumns.map(tableColumn => {
        return <Table.HeaderCell key={tableColumn.label}>{tableColumn.label}</Table.HeaderCell>
      })}
    </Table.Row>
  }

  async function blockUser({ id, status }) {
    setIsBusy(true)
    try {
      if (status === 1) {
        await UsersApi.blockUser(id)
      } else if (status === 0) {
        await UsersApi.unblockUser(id)
      }
      const response = await UsersApi.getUsers()
      setUsers(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function deleteUser(id: string) {
    setIsBusy(true)
    try {
      await UsersApi.deleteUser(id)
      const response = await UsersApi.getUsers()
      setTotal(response.data.total)
      setUsers(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)

  }

  function getActionColonValue(value, user: GetIndexUserTypeRow) {
    return <div style={{ display: 'flex' }}>
      <InlineDialog
        control={<Button
          basic
          color={user.status === 0 ? 'green' : 'red'}
          icon={user.status === 0 ? 'check circle outline' : 'times circle outline'}
        />
        }
        text={user.status === 1 ? t('Really block?') : t('Really unblock')}
        action={() => blockUser(user)} />
      {user.verified === 0 && <InlineDialog
        control={<Button basic icon='trash' color='red' />}
        text={t('Really delete ?')}
        action={() => deleteUser(user.id)}
      />}
    </div>
  }

  function getVisibleIcons(value) {
    return value == 1 ? <Icon color='green' name='check circle outline' /> : <Icon color='red' name='close' />
  }

  function getTableCell(value, user: GetIndexUserTypeRow) {
    const cellValue = typeof (value) === 'string' ? value === 'verified' ? getVisibleIcons(user[value]) : user[value] : getActionColonValue(value, user)
    if (value === 'name') {
      return <Table.Cell style={{ color: '#4183c4' }} key={value + user.id}>
        <Link to={RouteTypes.USERS_EDIT.replace(':id', user.id)}>
          {cellValue}
        </Link>
      </Table.Cell>
    }
    return <Table.Cell key={value + user.id}>{cellValue}</Table.Cell>
  }

  function tableBody() {
    return <Table.Body>
      {users.map(user => {
        return <Table.Row key={user.id}>
          {tableColumns.map(tableColumn => {
            return getTableCell(tableColumn.value, user)
          })}
        </Table.Row>
      })}

    </Table.Body>
  }

  function renderTable() {
    return <Table celled padded>
      <Table.Header>
        {tableHeaders()}
      </Table.Header>
      {users.length > 0 && tableBody()}
    </Table>
  }

  function renderPagination() {
    return <div>
      <Pagination
        onPageChange={(_, data) => setDataFiltered({ ...dataFiltered, page: parseInt(data.activePage.toString()) })}
        activePage={dataFiltered.page === 0 ? 1 : dataFiltered.page}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
        totalPages={Math.ceil(total / dataFiltered.rows)} />
    </div>
  }

  function renderTableFooter() {
    return <TableFooterWrapper>
      <div>
        <Dropdown basic button size='small' value={dataFiltered.rows}
          onChange={(e, data) => {
            setDataFiltered({
              ...dataFiltered,
              rows: data.value as number
            })
          }}
          options={optionsLimit}
        />
        <Label basic pointing={'left'}>
          {t('Total: ')}
          <Label.Detail>{users.length}</Label.Detail>
        </Label>
      </div>
      {renderPagination()}
    </TableFooterWrapper>
  }

  function renderContent() {
    return <>
      {renderTable()}
      {renderTableFooter()}
    </>
  }

  return <Layout
    segmented
    title={t('Users')}
    isBusy={isBusy}
    action={RouteTypes.USERS_CREATE}>
    {renderContent()}
  </Layout>
}
export default Users
