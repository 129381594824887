import React from 'react'

interface ILoaderMilkCanProps {
  isLoading: boolean
}

const getDefaultStyle = (isVisible: boolean) => ({
  display: isVisible ? 'flex' : 'none'
})

const LoaderMilkCan = (props: ILoaderMilkCanProps) => {
  const { isLoading } = props

  return <div className='milk-can-loader' style={{ ...getDefaultStyle(isLoading) }}>
    <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
      viewBox='-740.8 -53 290 512'>
      <g>
        <path className='milk-can-color' d='M-590.6,419c-0.1,0-0.1,0-0.2,0h0C-590.8,419-590.7,419-590.6,419z' />
        <path className='milk-can-color' d='M-450.9,156.8c-0.2-4.4-3-7-6.2-9.6c-1.2-1-14.7-11.7-25.3-20.2h21.5c5.5,0,10-4.5,10-10
    c0-57-11-120.9-85-142.5V-43c0-5.5-4.5-10-10-10h-100c-5.5,0-10,4.5-10,10v17.6c-74,21.5-85,85.4-85,142.4c0,5.5,4.5,10,10,10h21.5
    l-27.7,22.2c0,0,0,0,0,0c-2.3,1.8-3.7,4.6-3.7,7.8c0,0,0,252.1,0,252.2c0.2,27.5,22.6,49.8,50.2,49.8H-501
    c27.7,0,50.2-22.5,50.2-50.2C-450.8,408-450.8,158.4-450.9,156.8z M-720.7,107c0.6-26.2,4.3-56.8,20.5-79.7
    c10.3-14.6,25-25.2,44.3-31.8V-3c0,5.5,4.5,10,10,10h100c5.5,0,10-4.5,10-10v-1.5c19.3,6.6,34,17.2,44.3,31.8
    c16.2,22.9,19.9,53.5,20.5,79.7h-29.9V77c0-5.5-4.5-10-10-10h-36c-4.6-22.8-24.8-40-49-40s-44.3,17.2-49,40h-36
    c-5.5,0-10,4.5-10,10v30H-720.7z M-567.5,67h-56.6c4.1-11.6,15.2-20,28.3-20C-582.8,47-571.7,55.4-567.5,67z M-470.8,408.8
    c0,16.6-13.5,30.2-30.2,30.2h-189.6c-13.1,0-24.2-8.4-28.4-20h1.2c-1.7,0-3-1.3-3-3v-17h0V167h250V408.8z'/>
        <path className='milk-can-color' d='M-590.8,399L-590.8,399c0.1,0,0.1,0,0.2,0C-590.7,399-590.8,399-590.8,399z' />
      </g>
    </svg>
  </div>
}

export default LoaderMilkCan