import styled from 'styled-components'
import { Menu } from 'semantic-ui-react'

export const TopBar = styled.div`
  position: relative;
  z-index: 51;
  margin-bottom: 3rem;
  border-bottom-width: 1px;
  height: 70px;
  border-color: rgb(255, 255, 255, 0.08);
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: -1.25rem;
  padding-top: 0px;
`
export const TopBarMenu = styled(Menu)`
  background: transparent !important;
  min-height: unset !important;
  border: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  align-items: center;
  height: 100%;
`
export const BrandLogo = styled(Menu.Item)`
  display: flex !important;
  position: relative !important;
  font-size: 1.25rem;
  font-weight: bold;
  color: #FFFFFF !important;
`
export const LeftSideWrapper = styled(Menu.Menu)`
  height: 100% !important;
`

export const StyledProfileWrapper = styled.div`
  position: relative;
  flex: none;
  height: 2rem;
  width: 2rem;
> span {
  position: absolute;
    height: 100%;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 9999px;
    background: #f5f5f5;
    > img {
      position: absolute;
      height: 100%;
      width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      border-radius: 9999px;
      background: #f5f5f5;
    }
`