import React, {Fragment, ReactElement, useEffect} from 'react'
import {Controller, useController, UseControllerProps} from 'react-hook-form'
import {Form, Label} from 'semantic-ui-react'
import {getLabel, getError} from './util'
import styled from 'styled-components'

const StyledErrorLabel = styled(Label)`
white-space: normal;
background: #fff!important;
border: 1px solid #e0b4b4!important;
color: #9f3a38!important;
`

interface ExternalProps extends UseControllerProps {
  children: ReactElement,
  onChange?: Function,
  label?: string | JSX.Element,
  [prop: string]: any,
  control: any
}

const External: React.FC<ExternalProps> = props => {
  const {field, fieldState} = useController(props)
  const {control, children, name, rules, label, ...externalProps} = props
  const fieldTemp = {...field, ref: null}

  function notInViewport(element, offset = 0) {
    if (!element) return false
    const top = element.getBoundingClientRect().top
    return (top + offset) >= 0 && (top - offset) >= window.innerHeight
  }

  useEffect(() => {
    if (fieldState.error) {
      const element = document.getElementById(fieldTemp.name)
      if (notInViewport(element)) {
        element.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [fieldState])

  return <Form.Field>
    {label && getLabel(label, rules)}
    <Controller
      control={control}
      name={name}
      render={() => {
        const ChildComponent = React.cloneElement(children, {
          ...fieldTemp,
          ...externalProps,
          value: field.value,
          id: fieldTemp.name
        })
        return <Fragment>
          {ChildComponent}
          {fieldState.error && <StyledErrorLabel basic pointing>
            {fieldState.error.message}
          </StyledErrorLabel>
          }
        </Fragment>
      }}
    />
  </Form.Field>
}
export default External
