import { useMutation, useQuery } from "react-query"
import { MilkMoneyApi } from "~/services"
import { onError } from "~/util"

const getMilkTypes = (idDairy: string, data?) => useQuery(
  'milk-types-index',
  () => MilkMoneyApi.MilkTypes(idDairy, data),
  {
    cacheTime: 60000,
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 1,
    onError,
    select: (data) => {
      return data.data
    }
  }
)

const getMilkTypesOptions = (idDairy: string) => useQuery(
  'milk-types-options',
  () => MilkMoneyApi.MilkTypesOptions(idDairy),
  {
    cacheTime: 60000,
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: 1,
    onError,
    select: (data) => {
      return data.data
    }
  }
)

const deleteMilkType = (onSuccess: any) => {
  //@ts-ignore
  return useMutation(MilkMoneyApi.MilkTypeDelete, {
    onSuccess,
    onError,
    retry: 1
  });
};

const createMilkType = (onSuccess: any) => {
  //@ts-ignore
  return useMutation(MilkMoneyApi.MilkTypeStore, {
    onSuccess,
    onError,
    retry: 1
  });
};

const editMilkType = (onSuccess: any) => {
  //@ts-ignore
  return useMutation(MilkMoneyApi.MilkTypeEdit, {
    onSuccess,
    onError,
    retry: 1
  });
};

export const MilkTypeQueries = {
  getMilkTypes,
  getMilkTypesOptions,
  deleteMilkType,
  editMilkType,
  createMilkType
};