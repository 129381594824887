import styled from 'styled-components';

export const ListItemWrapper = styled.div`
  margin-top: 10px;
  margin-right: 5px;
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 10px;
   .icon {
    cursor: pointer !important;
    position: absolute !important;
    right: -40px !important;
    top: 28px !important;
   }
`