import { useMutation, useQuery } from "react-query";
import { DairyApi } from "~/services";
import { onError } from "~/util"

const getHusbandryForms = (id) => {
  return useQuery(
    'query-husbadry',
    () => DairyApi.getHusbandryForms(id), {
    enabled: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError,
    retry: 1,
    select: (data) => {
      return data.data
    }
  });
};

export const HusbandryQueries = {
  getHusbandryForms
}