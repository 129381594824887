import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
padding: 20px;
position: relative;
.visible.transition {
    display: table !important;
  }
.sortable {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
 .ui.ribbon.label {
  width: 120px;
  max-width 120px;
 }
`
export const StyledNoResultsMessage = styled.div`
display: flex;
justify-content: center;
.ui.placeholder.segment {
  width: 200px;
  min-height: 200px;
  border-radius: 50%;
  .header {
    margin-right: unset !important
   }
}
  
`
export const TableFooterWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

export const StyledTableRow = styled(Table.Row)`
  @media (max-width: 1900px) {
    .buttons {
      display: inline-flex;
    }
  }
`;

export const StyledCellValue = styled.p`
  margin: 0;
`;
