import React from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'

const Home: React.FC = () => {
  const { t } = useTranslation()

  return <Layout columns={3} title={t('Dashboard')}>
  </Layout>
}
export default Home
