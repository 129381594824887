import React, {useState} from 'react'
import {Label, Icon} from 'semantic-ui-react'

interface InlineDialogProps {
  style?: Object
  control: any
  color?: any
  text: string
  action: Function
}

const InlineDialog: React.FC<InlineDialogProps> = ({style, control, color, text, action}) => {
  const [expanded, setExpanded] = useState(false)

  function renderExpanded() {
    return <Label color={color ? color : 'red'} size='large' style={style || {}
    }>
      {text}
      < Label.Detail as='a' onClick={() => {
        setExpanded(false)
        action()
      }}>
        <Icon name='checkmark' />
      </Label.Detail >
      <Label.Detail as='a' onClick={() => setExpanded(false)}>
        <Icon name='cancel' />
      </Label.Detail>
    </Label >
  }

  return expanded ?
    renderExpanded() :
    React.cloneElement(control, {
      onClick: () => setExpanded(true)
    })
}
export default InlineDialog
