import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFields } from '~/components/FormFields'
import { FtpsDairyType } from '~/services/dairies'
interface ExportImportProps {
  ftpsData: FtpsDairyType[]
}

const ExportImport = (props: ExportImportProps) => {
  const { ftpsData } = props
  const { control } = useFormContext()
  const { t } = useTranslation()

  function renderExportImport() {
    return <FormFields.Select
      placeholder={t('Ftps')}
      control={control}
      multiple
      options={ftpsData.map(ftp => ({ value: ftp.key, text: ftp.label }))}
      name={'ftps'}
      defaultValue={[]}
      label={t('Ftps')}
    />
  }

  return <>
    {ftpsData && ftpsData.length > 0 && renderExportImport()}
  </>
}
export default ExportImport
