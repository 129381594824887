import React, { useEffect, useState } from 'react'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useParams, Link, useHistory } from 'react-router-dom'
import notify from '~/components/notify'
import { MilkMoneyApi } from '~/services'
import { Form } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { RouteTypes } from '~/Constants'

const EditMilkQualityClass: React.FC = () => {
  const { t } = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const { id, idMilkQ } = useParams()
  const history = useHistory()

  const { handleSubmit, control, reset, setValue, watch } = useForm({
    mode: 'onSubmit'
  })

  const watchGermCountStatus = watch('germCountGmLastFewMonthsStatus')
  const watchCellCountStatus = watch('cellCountGmLastFewMonthsStatus')
  const watchLastMonthGermStatus = watch('germCountGmLastMonthStatus')
  const watchLastMonthCellStatus = watch('cellCountGmLastMonthStatus')

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await MilkMoneyApi.MilkQualityClassEdit(id, idMilkQ)
      reset(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderForm() {
    const requiredMessage = t('Please enter amount')
    return <>
      <Form.Group widths={'equal'}>
        <FormFields.Checkbox
          control={control}
          defaultValue={0}
          checkboxValue={1}
          label={t('Inhibitor status')}
          name={'inhibitorStatus'}
        />
        <FormFields.Checkbox
          control={control}
          defaultValue={0}
          checkboxValue={1}
          label={t('Status')}
          name={'status'}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFields.Input
          control={control}
          name={'name'}
          label={t('Name')}
          rules={{
            required: {
              value: true,
              message: t('Please enter name')
            },
            maxLength: {
              value: 50,
              message: t('Max char. is 50')
            }
          }}
        />
        <FormFields.Input
          control={control}
          name={'price'}
          label={t('Price')}
          typeInput='controlled'
          allowDecimals
          decimalScale={2}
          rules={{
            min: {
              value: 0,
              message: t('Min value is 0')
            }
          }}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFields.Input
          control={control}
          withoutAsterisk
          placeholder={t('Enter Germ Count')}
          name={'germCountGmLastFewMonthsAmount'}
          label={
            <FormFields.Checkbox
              control={control}
              defaultValue={0}
              style={{ marginBottom: 5 }}
              checkboxValue={1}
              label={t('Germ Count (Last Few Months) Status')}
              name={'germCountGmLastFewMonthsStatus'}
            />}
          rules={{
            required: !Boolean(watchGermCountStatus) || requiredMessage,
            min: {
              value: 0,
              message: t('Min value is 0')
            }
          }}
          typeInput='controlled'
        />
        <FormFields.Input
          control={control}
          withoutAsterisk
          placeholder={t('Enter Cell Count')}
          name={'cellCountGmLastFewMonthsAmount'}
          label={<FormFields.Checkbox
            control={control}
            style={{ marginBottom: 5 }}
            defaultValue={0}
            checkboxValue={1}
            label={t('Cell Count (Last Few Months) Status')}
            name={'cellCountGmLastFewMonthsStatus'}
          />}
          rules={{
            required: !Boolean(watchCellCountStatus) || requiredMessage,
            min: {
              value: 0,
              message: t('Min value is 0')
            }
          }}
          typeInput='controlled'
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFields.Input
          withoutAsterisk
          control={control}
          placeholder={t('Enter Germ Count (Last Month)')}
          name={'germCountGmLastMonthAmount'}
          label={<FormFields.Checkbox
            control={control}
            style={{ marginBottom: 5 }}
            defaultValue={0}
            checkboxValue={1}
            label={t('Germ Count (Last Month) Status')}
            name={'germCountGmLastMonthStatus'}
          />}
          rules={{
            required: !Boolean(watchLastMonthGermStatus) || requiredMessage,
            min: {
              value: 0,
              message: t('Min value is 0')
            }
          }}
          typeInput='controlled'
        />
        <FormFields.Input
          control={control}
          placeholder={t('Enter Cell Count (Last Month)')}
          name={'cellCountGmLastMonthAmount'}
          withoutAsterisk
          label={<FormFields.Checkbox
            control={control}
            defaultValue={0}
            style={{ marginBottom: 5 }}
            checkboxValue={1}
            label={t('Cell Count (Last Month) Status')}
            name={'cellCountGmLastMonthStatus'}
          />}
          rules={{
            required: !Boolean(watchLastMonthCellStatus) || requiredMessage,
            min: {
              value: 0,
              message: t('Min value is 0')
            }
          }}
          typeInput='controlled'
        />
      </Form.Group>
    </>
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      await MilkMoneyApi.UpdateMilkQualityClass(id, idMilkQ, data)
      notify(t('Succesfully updated milk quality class'), 'success')
      history.push(RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS.replace(':id', id))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderActions() {
    return <>
      <PageHeaderActionButton
        as={Link}
        style={{ lineHeight: '1em' }}
        to={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS.replace(':id', id)}
        type='button'
      >{t('Cancel')}</PageHeaderActionButton>
      <PageHeaderActionButton>{t('Update')}</PageHeaderActionButton>
    </>
  }

  return <DairyViewTabsLayout
    segmented
    header={t('Edit milk quality classes')}
    isBusy={isBusy}
    onSubmit={handleSubmit(onSubmit)}
    actions={renderActions()}
  >
    {renderForm()}
  </DairyViewTabsLayout>
}
export default EditMilkQualityClass