import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { StyledField } from '../../style'
import { DateInput } from 'semantic-ui-calendar-react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'

interface LocationProps {
  farmersOptions: any[]
}

const Location: React.FC<LocationProps> = (props) => {
  const { farmersOptions } = props
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()


  const { languages, logged: { languageId } } = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const languageLocal = languages.find(language => language.id === languageId)

  const watchCancellationField = useWatch({ control, name: 'terminationDate' })
  const watchLastDeliveryDateField = useWatch({ control, name: 'lastDeliveryDate' })

  const terminationDate = watchCancellationField?.includes('-') ? dayjs(watchCancellationField).format('DD.MM.YYYY') : watchCancellationField
  const lastDeliveryDate = watchLastDeliveryDateField?.includes('-') ? dayjs(watchLastDeliveryDateField).format('DD.MM.YYYY') : watchLastDeliveryDateField

  const handleKeyPress = (event) => {
    event.preventDefault();
  }

  return <>
    <FormFields.Select
      selectOnBlur={false}
      control={control}
      label={t('District No.')}
      name={'districtId'}
      clearable
      rules={{
        required: {
          value: true,
          message: t('Please enter District No.')
        }
      }}
      placeholder={t('District No.')}
      //@ts-ignore
      options={farmersOptions?.districts?.map(district => (
        {
          value: district?.id,
          text: `${district?.stateName} / ${district?.name} / ${district?.number}`
        }
      )) || []}
    />
    <Form.Group style={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 0 }}>
      <StyledField width={14}>
        <label>{t('Cancellation date')}</label>
        <DateInput
          name='date'
          localization={languageLocal.code}
          pickerStyle={{ width: 200 }}
          placeholder={t('Date')}
          closeOnMouseLeave={false}
          onKeyPress={handleKeyPress}
          dateFormat={'DD.MM.YYYY'}
          closable
          value={terminationDate}
          iconPosition='left'
          onChange={(event, { name, value }) => setValue('terminationDate', value)}
        />
      </StyledField>
      <Form.Button
        style={{ marginTop: 21 }}
        width={2}
        type='button'
        icon='trash'
        basic
        onClick={() => setValue('terminationDate', null)}
      />
    </Form.Group>
    <Form.Group style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Form.Field width={14} style={{ marginBottom: 0 }}>
        <label>{t('Date of last delivery')}</label>
        <DateInput
          name='date'
          pickerStyle={{ width: 200 }}
          placeholder={t('Date')}
          localization={languageLocal.code}
          onKeyPress={handleKeyPress}
          className={'datePicker'}
          closeOnMouseLeave={false}
          closable
          dateFormat={'DD.MM.YYYY'}
          value={lastDeliveryDate}
          iconPosition='left'
          onChange={(event, { name, value }) => setValue('lastDeliveryDate', value)}
        />
      </Form.Field>
      <Form.Button
        style={{ marginTop: 21 }}
        width={2}
        type='button'
        icon='trash'
        basic
        onClick={() => setValue('lastDeliveryDate', null)}
      />
    </Form.Group>
    <Form.Group style={{ alignItems: 'end' }}>
      <FormFields.Input
        width={11}
        label={t('Street/Street number')}
        placeholder={t('Street')}
        rules={{
          required: {
            value: true,
            message: t('Please enter Street')
          }
        }}
        control={control}
        name={'streetName'}
      />
      <FormFields.Input
        width={5}
        label={''}
        rules={{
          required: {
            value: true,
            message: t('Please enter Street number')
          }
        }}
        control={control}
        placeholder={t('Street number')}
        name={'streetNumber'}
      />
    </Form.Group>
    <FormFields.Input
      name='district'
      control={control}
      label={t('District')}
      placeholder={t('District')}
    />
    <Form.Group style={{ alignItems: 'end' }}>
      <FormFields.Input
        width={5}
        label={t('Zip/City')}
        rules={{
          required: {
            value: true,
            message: t('Please enter Zip')
          }
        }}
        placeholder={t('zip')}
        control={control}
        name={'zip'}
        type='number'
      />
      <FormFields.Input
        width={11}
        control={control}
        placeholder={t('City')}
        rules={{
          required: {
            value: true,
            message: t('Please enter City')
          }
        }}
        styleLabel={{ left: '40%' }}
        name={'city'}
      />
    </Form.Group>
  </>
}
export default Location