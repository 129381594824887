import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import LocationForm from '../../../Tabs/Location'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useHistory, useParams } from 'react-router-dom'
import { DairyApi } from '~/services'
import notify from '~/components/notify'
import { RouteTypes } from '~/Constants'
import { PageHeaderActionButton } from '~/components/Layout/style'

const CreateEditLocation = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [locationOptions, setLocationOptions] = useState(null)
  const { idDairy, id } = useParams()
  const { t } = useTranslation()
  const history = useHistory()

  const { handleSubmit, control, reset } = useForm({
    mode: 'onSubmit'
  })

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const responseOptions = await DairyApi.OfficesOptions(idDairy)
      setLocationOptions(responseOptions.data)
      if (id) {
        const responseItem = await DairyApi.OfficesEdit(idDairy, id)
        reset(responseItem.data)
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function onSubmit(data) {
    setIsBusy(true)
    try {
      if (!data.id) {
        await DairyApi.OfficesStore(idDairy, data)
        notify(t('Successfully created location'), 'success')
      } else {
        await DairyApi.OfficesUpdate(idDairy, data.id, data)
        notify(t('Successfully updated location'), 'success')
      }
      history.push(RouteTypes.DAIRY_FACTORY_VIEW_LOCATION.replace(':id', idDairy))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderActions() {
    return <div>
      <PageHeaderActionButton>{id ? t('Update') : t('Save')}</PageHeaderActionButton>
      <PageHeaderActionButton type='button' onClick={() => history.goBack()}>{t('Cancel')}</PageHeaderActionButton>
    </div>
  }

  return <DairyViewTabsLayout
    isBusy={isBusy}
    segmented
    header={id ? t('Edit Location') : t('Create Location')}
    actions={renderActions()}
    onSubmit={handleSubmit(onSubmit)}
  >
    {locationOptions && <LocationForm
      control={control}
      prefixValue={'id'}
      dairyOptions={locationOptions}
    />
    }
  </DairyViewTabsLayout>
}
export default CreateEditLocation
