import React, {useCallback, useEffect, useState} from 'react'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import './theme.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {useTranslation} from 'react-i18next'

type Props = {
  value?: string,
  onChange?: (value: string) => void
}

const createEditorState = value => {
  if (value) {
    const contentBlock = htmlToDraft(value)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      return EditorState.createWithContent(contentState)
    }
  }
  return EditorState.createEmpty()
}

export default function WYSIWYGEditor(props: Props) {
  const [editorState, setEditorState] = useState<EditorState>(createEditorState(props.value))
  const {t} = useTranslation()
  const onChange = useCallback(props.onChange, [])

  function onEditorStateChange(editorState: EditorState) {
    setEditorState(editorState)
    if (onChange) {
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }
  }

  useEffect(() => {
    setEditorState(createEditorState(props.value))
  },[])

  return <Editor
    toolbar={{
      options: ['inline'],
      inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough']
      }
    }}
    on
    placeholder={t('Enter text')}
    editorState={editorState}
    wrapperClassName='wysiwyg-editor-wrapper'
    editorClassName='wysiwyg-editor'
    onEditorStateChange={onEditorStateChange}
  />
}
