import {combineReducers} from 'redux'
import {SystemDataType} from '~/services/login'

type ReturnSystemType = {
  systemData: SystemDataType
}
/** reducers */
import {userReducer} from './user'
import {systemDataReducer} from './systemData'

export const allReducers = combineReducers({
  user: userReducer,
  systemData: systemDataReducer
})

export type RootState = ReturnType<typeof userReducer>
export type SystemDataState = ReturnSystemType
