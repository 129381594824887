import i18n from "~/components/i18n";

export const checkboxFields = [
  {
    label: i18n.t('Status'),
    key: 'status',
  },
  {
    label: i18n.t('Always visible'),
    key: 'alwaysVisible',
  },
  {
    label: i18n.t('Top'),
    key: 'visibleTop',
  },
  {
    label: i18n.t('Center'),
    key: 'visibleCenter',
  }
]

export const inputFields = [
  [
    {
      label: i18n.t('Standard price'),
      key: 'standardPrice',
      allowDecimals: true,
      minValue: 0,
      required: true,
      requiredMessage: i18n.t('Please enter standard price')
    },
    {
      label: i18n.t('Min amount'),
      key: 'minAmount',
      allowDecimals: false,
      minValue: 0,
      required: true,
      requiredMessage: i18n.t('Please enter min amount')
    },
    {
      label: i18n.t('Max amount'),
      key: 'maxAmount',
      allowDecimals: false,
      minValue: 'minAmount',
      required: true,
      requiredMessage: i18n.t('Please enter max amount')
    }
  ],
  [
    {
      label: i18n.t('Min price'),
      key: 'minPrice',
      allowDecimals: true,
      minValue: 0,
      required: true,
      requiredMessage: i18n.t('Please enter min price')
    },
    {
      label: i18n.t('Max price'),
      key: 'maxPrice',
      allowDecimals: true,
      minValue: 'minPrice',
      required: true,
      requiredMessage: i18n.t('Please enter max price')
    }
  ],
  [
    {
      label: i18n.t('Amount'),
      key: 'amount',
      allowDecimals: false,
      minValue: 1,
      required: true,
      requiredMessage: i18n.t('Please enter amount')
    },
    {
      label: i18n.t('Price'),
      key: 'price',
      allowDecimals: true,
      minValue: 0.01,
      required: true,
      requiredMessage: i18n.t('Please enter price')
    }
  ]
]