import React, { useEffect } from 'react'
import { Dimmer, Form, Icon, Sidebar, SidebarProps } from 'semantic-ui-react'
import LoaderMilkCan from '../Loader'
import Box from '../Box'
import { DrawerEl } from './style'
import { ESCAPE_KEY_CODE_VALUE } from '~/Constants'

interface DrawerProps extends SidebarProps {
  isBusy?: boolean
  handleSubmit?: any
  header: string
  content: React.ReactNode
  isVisible: boolean
  closeOnEscape?: boolean
}

const Drawer: React.FC<DrawerProps> = (props) => {
  const { onHide, handleSubmit, isVisible, isBusy, closeOnEscape, onHidden } = props

  useEffect(() => {
    if (isVisible && closeOnEscape) {
      document.addEventListener('keydown', handleKeyPress)
    }
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [isVisible])

  function handleKeyPress(e) {
    if (e.keyCode === ESCAPE_KEY_CODE_VALUE) {
      if (onHide) {
        onHide(e, null)
      } else if (onHidden) {
        onHidden(e, null)
      }
    }
  }

  function renderHeader() {
    return <DrawerEl.DrawerHeader>
      <DrawerEl.DrawerTitle>
        {props.header}
      </DrawerEl.DrawerTitle>
      <DrawerEl.DrawerAction>
        {(onHide || onHidden) && <DrawerEl.DrawerActionClose
          compact
          icon
          size='large'
          type='button'
          onClick={(e, data) => {
            if (onHide) {
              onHide(e, data)
            } else if (onHidden) {
              onHidden(e, data)
            }
          }}
        >
          <Icon size='large' name='times circle outline'></Icon>
        </DrawerEl.DrawerActionClose>
        }
      </DrawerEl.DrawerAction>
    </DrawerEl.DrawerHeader>
  }

  return <DrawerEl.DrawerContainer>
    <Sidebar
      className='drawer'
      as={Form}
      animation='push'
      direction='right'
      visible={isVisible}
      onSubmit={handleSubmit}
      width='very wide'
    >
      <Dimmer active={isBusy} inverted>
        <LoaderMilkCan isLoading={isBusy} />
      </Dimmer>
      <Box header={renderHeader()} fluid content={props.content}></Box>
    </Sidebar>
  </DrawerEl.DrawerContainer>
}
export default Drawer