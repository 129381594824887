import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input, Dropdown, Image, Icon, Popup, Label } from 'semantic-ui-react'
import FileUpload from '~/components/FileUpload'
import { FormFields } from '~/components/FormFields'
import { StyledImageWrapper } from '~/pages/Profile/style'
import { RemoveImageButton, RemoveImageWrapper } from '~/pages/Users/style'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useHistory, useParams } from 'react-router-dom'
import { emailRegex, FARMER_MANAGER_ROLE_SLUG, IFE_ROLE_SLUG, passwordRegex, RouteTypes } from '~/Constants'
import { useForm } from 'react-hook-form'
import { DairyApi } from '~/services'
import notify from '~/components/notify'
import { PageHeaderActionButton } from '~/components/Layout/style'
import styled from 'styled-components'

const defaultImage = '/assets/default.png'
const MINIMUM_LENGTH_PASSWORD = 8
const MAX_LENGTH_PASSWORD = 32


const StyledLabel = styled(Label)`
white-space: normal;
background: #fff!important;
border: 1px solid #e0b4b4!important;
color: #9f3a38!important;
margin-top: 0 !important;
`

const CreateEditUser = () => {
  const [isBusy, setIsBusy] = useState(false)
  const { id, idUser } = useParams()
  const [usersOptions, setUsersOptions] = useState(null)
  const [correctEmail, setCorrectEmail] = useState(true)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [verifiedUser, setVerifiedUser] = useState(false)
  const [farmerGroups, setFarmerGroups] = useState([])
  const { t } = useTranslation()
  const history = useHistory()

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitted },
    reset,
    getValues,
    setValue
  } = useForm({
    mode: 'onSubmit'
  })

  const watchVerified = watch('verified')
  const watchPhotoField = watch('photo')
  const watchPhoneCodeField = watch('phonePrefix')
  const wathcMobileCodeField = watch('mobilePrefix')
  const mobileNumber = watch('mobileNumber')
  const phoneNumber = watch('phoneNumber')
  const watchRoleSelected = watch('roleId')

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const responseOptions = await DairyApi.UsersOptions(id)
      const responseGroupsList = await DairyApi.ListFarmerGroup(id, { isOrganisation: 1 })
      setFarmerGroups(responseGroupsList.data)
      responseOptions.data.roles = responseOptions.data.roles.filter(role => role.slug !== 'user')
      setUsersOptions(responseOptions.data)
      if (idUser) {
        const responseItem = await DairyApi.UsersEdit(id, idUser)
        setVerifiedUser(responseItem.data.verified === 1)
        reset(responseItem.data)
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function handleUploadFiles(acceptedFiles: File[]) {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setValue('photo', reader.result)
    }
  }

  function renderActions() {
    return <div>
      <PageHeaderActionButton>{idUser ? t('Update') : t('Save')}</PageHeaderActionButton>
      <PageHeaderActionButton type='button' onClick={() => history.goBack()}>{t('Cancel')}</PageHeaderActionButton>
    </div>
  }

  async function onSubmit(data) {
    data.verified = watchVerified ? 1 : 0
    if ((!mobileNumber || mobileNumber === '') || (!wathcMobileCodeField || wathcMobileCodeField === '')) {
      return
    }
    setIsBusy(true)
    try {
      if (!data.id) {
        await DairyApi.UsersStore(id, data)
        notify(t('Succesfully created user'), 'success')
      } else {
        await DairyApi.UserUpdate(id, data.id, data)
        notify(t('Succesfully updated user'), 'success')
      }
      history.push(RouteTypes.DAIRY_FACTORY_VIEW_USERS.replace(':id', id))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function displayPasswordField() {
    if (idUser) {
      if (verifiedUser) {
        return false
      }
      return typeof (watchVerified) === 'number' ? watchVerified === 1 : watchVerified
    }
    else if (!idUser && watchVerified) {
      return true
    }
    return false
  }

  function isRoleSelected(roleSlug) {
    const selectedRole = usersOptions?.roles?.find(role => watchRoleSelected === role.id)
    return selectedRole.slug === roleSlug
  }

  function displayErrorMobile() {
    if (isSubmitted) {
      return (!mobileNumber || mobileNumber === '') || (!wathcMobileCodeField || wathcMobileCodeField === '')
    }
    return false
  }

  function getEmailMessageError() {
    const email = getValues('email')
    const errorEmailRegex = emailRegex.test(email)
    const errorMessage = !errorEmailRegex ? t('Please enter valid email') : t('Email already used')
    return <StyledLabel basic pointing={'above'}>{errorMessage}</StyledLabel>
  }


  return <DairyViewTabsLayout
    actions={renderActions()}
    header={!idUser ? t('Create User') : t('Edit user')}
    onSubmit={handleSubmit(onSubmit)}
    isBusy={isBusy}
    segmented
  >
    <>
      <Form.Group>
        <Form.Field width={3}>
          <FileUpload
            onChange={handleUploadFiles}
            mimeTypes={['bmp', 'jpg', 'jpeg', 'png']}
            sizeLimitInMB={5}
          >
            <StyledImageWrapper>
              <Image
                style={{ maxWidth: 150, width: 'auto' }}
                size='medium'
                wrapped
                src={watchPhotoField ? watchPhotoField : defaultImage}
              />
              {watchPhotoField && <Popup
                content={t('Remove selected image')}
                trigger={
                  <RemoveImageWrapper>
                    <RemoveImageButton
                      circular
                      onClick={(e) => {
                        e.preventDefault()
                        setValue('photo', null)
                      }}
                      name='times'
                    />
                  </RemoveImageWrapper>
                }
              />}
            </StyledImageWrapper>
          </FileUpload>
        </Form.Field>
        <Form.Field width={6}>
          <FormFields.Input
            control={control}
            label={t('First name')}
            rules={{
              required: {
                value: true,
                message: t('Please enter first name')
              },
              maxLength: {
                value: 100,
                message: t('Max char is 100')
              }
            }}
            style={{ marginBottom: 10 }}
            name={'firstName'}
            placeholder={t('First name')}
          />
          <FormFields.Input
            control={control}
            label={t('Last name')}
            rules={{
              required: {
                value: true,
                message: t('Please enter last name')
              },
              maxLength: {
                value: 100,
                message: t('Max char is 100')
              }
            }}
            style={{ marginBottom: 10 }}
            name={'lastName'}
            placeholder={t('Last name')}
          />
          <FormFields.Input
            control={control}
            label={t('Email')}
            rules={{
              required: {
                value: true,
                message: t('Please enter email')
              },
              pattern: {
                value: emailRegex,
                message: t('Please enter valid email')
              }
            }}
            emailInputData={{ id: id, }}
            typeInput={'emailField'}
            handleEmailCheck={(correctEmail) => setCorrectEmail(correctEmail)}
            style={{ marginBottom: 10 }}
            name={'email'}
            placeholder={t('Email')}
          />
          {!correctEmail && getEmailMessageError()}
          {displayPasswordField() && <FormFields.Input
            control={control}
            name='password'
            label={t('Password')}
            type={visiblePassword ? 'text' : 'password'}
            icon={
              <Icon
                link
                name='eye'
                onMouseDown={() => setVisiblePassword(!visiblePassword)}
                onMouseUp={() => setVisiblePassword(!visiblePassword)}
              />
            }
            rules={{
              required: {
                value: true,
                message: t('Please enter password')
              },
              pattern: {
                value: passwordRegex,
                message: t('The password must contain at least one uppercase, lowercase letter, and at least one symbol')
              },
              minLength: {
                value: MINIMUM_LENGTH_PASSWORD,
                message: t('Password must be at least 8 characters long')
              },
              maxLength: {
                value: MAX_LENGTH_PASSWORD,
                message: t('Max character is 32')
              }
            }}
          />
          }
          {!verifiedUser && <FormFields.Checkbox
            name={'verified'}
            control={control}
            style={{ marginBottom: 20, marginTop: 20 }}
            label={t('Verified')}
          />}
        </Form.Field>
        <Form.Field width={6}>
          <>
            <Form.Field width={12} style={{ marginBottom: 10 }}>
              <label>{t('Phone number')}</label>
              <Input
                value={phoneNumber}
                label={<Dropdown
                  options={usersOptions?.countries.map(countryCode => ({ value: countryCode.id, text: countryCode.phoneCode, flag: countryCode?.iso }))}
                  selectOnBlur={false}
                  value={parseInt(watchPhoneCodeField)}
                  scrolling
                  basic
                  placeholder={t('Code')}
                  onChange={(e, data) => setValue('phonePrefix', data.value)}
                  name={'phonePrefix'}
                />}
                onChange={(e, data) => {
                  setValue('phoneNumber', data.value)
                }}
                labelPosition='left'
                placeholder={watchPhoneCodeField ? t('Phone number') : t('Please select phone code')}
              />
            </Form.Field>
            <Form.Field width={12} style={{ marginBottom: 10 }} required>
              <label>{t('Mobile number')}</label>
              <Input
                error={isSubmitted && (!mobileNumber || mobileNumber === '')}
                value={mobileNumber}
                label={<Dropdown
                  scrolling
                  basic
                  error={isSubmitted && (!wathcMobileCodeField || wathcMobileCodeField === '')}
                  options={usersOptions?.countries.map(countryCode => ({ value: countryCode.id, text: countryCode.phoneCode, flag: countryCode?.iso }))}
                  selectOnBlur={false}
                  value={parseInt(wathcMobileCodeField)}
                  onChange={(e, data) => setValue('mobilePrefix', data.value)}
                  placeholder={t('Code')}
                  name={'mobilePrefix'}
                />}
                onChange={(e, data) => setValue('mobileNumber', data.value)}
                labelPosition='left'
                placeholder={wathcMobileCodeField ? t('Mobile number') : t('Please select mobile code')}
                name={'mobileNumber'}
              />
              {displayErrorMobile() && <StyledLabel basic pointing={'above'}>{t('Please enter mobile number and mobile code')}</StyledLabel>}
            </Form.Field>
          </>
          <FormFields.Select
            style={{ marginBottom: 10 }}
            label={t('Role')}
            placeholder={t('Select role')}
            control={control}
            selectOnBlur={false}
            rules={{
              required: {
                value: true,
                message: t('Please select role')
              }
            }}
            width={12}
            options={usersOptions?.roles.map(role => ({ value: role.id, text: role.label }))}
            name={'roleId'}
          />
          {watchRoleSelected && isRoleSelected(FARMER_MANAGER_ROLE_SLUG) && <FormFields.Select
            options={farmerGroups.map(farmerGroup => ({ value: farmerGroup.id, text: farmerGroup.name }))}
            selection
            multiple
            search
            selectOnBlur={false}
            rules={{
              required: {
                value: true,
                message: t('Please select farmer group')
              }
            }}
            placeholder={t('Select farmer group')}
            control={control}
            label={t('Farmer group')}
            name={'farmerGroups'}
          />}
          {watchRoleSelected && isRoleSelected(IFE_ROLE_SLUG) && <FormFields.Input
            control={control}
            name={'companyName'}
            label={t('Company')}
            placeholder={t('Company name')}
            rules={{
              required: {
                value: true,
                message: t('Please enter company name')
              }
            }}
          />}
        </Form.Field>
      </Form.Group>
    </>
  </DairyViewTabsLayout>
}
export default CreateEditUser
