import React, { useEffect, useState } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Tab } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { LanguageType, SystemDataType } from '~/services/login'
import { StyledTabPane } from '../style'
import { useSelector } from 'react-redux'
import { SystemDataState } from '~/reducers'

type RedirectionPropsType = {
  languages: LanguageType[]
}

const Redirection = (props: RedirectionPropsType) => {
  const { control, getValues, setValue } = useFormContext()
  const watchTestEnvironment = useWatch({ control, name: 'isTest' })
  const [activeIndex, setActiveIndex] = useState(0)
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const { t } = useTranslation()
  const indexOfText = 0

  const { fields } = useFieldArray({
    control,
    //@ts-ignore
    name: `texts[${indexOfText}].languages`,
    keyName: 'key'
  })

  useEffect(() => {
    if (watchTestEnvironment) {
      const langCodes = getValues('languages')
      //@ts-ignore
      const textsLangs = getValues(`texts[${indexOfText}].languages`)
      const languages = systemData.languages.filter(lang => !!langCodes.find(langCode => langCode === lang.code))
      languages.map(language => {
        const index = textsLangs?.findIndex(lang => lang.id === language.code)
        if (index < 0) {
          textsLangs.push({
            id: language.code,
            translation: ''
          })
        }
      })
      //@ts-ignore
      setValue(`texts[${indexOfText}].languages`, textsLangs)
    } else {
      //@ts-ignore
      setValue(`texts[${indexOfText}].languages`, [])
    }
  }, [watchTestEnvironment])

  useEffect(() => {
    setActiveIndex(0)
  }, [fields.length])

  function languageTab(lan, indexLanguage) {
    return <StyledTabPane key={fields[indexLanguage]?.key}>
      <FormFields.TextArea
        control={control}
        //@ts-ignore
        name={`texts[${indexOfText}].languages[${indexLanguage}].translation`}
        rules={{
          required: {
            value: true,
            message: t('Please enter test platform text')
          },
          maxLength: {
            value: 1000,
            message: t('Max character number is 1000')
          }
        }}
        placeholder={t('Test platform text')}
        label={t('Test platform text')}
      />
    </StyledTabPane>
  }

  function renderForm() {
    const panes = []
    fields.map((language, index) => panes.push({
      menuItem: language['id'].toLocaleUpperCase(),
      render: () => languageTab(language, index)
    }))
    return <Tab
      activeIndex={activeIndex}
      //@ts-ignore
      onTabChange={(_, data) => setActiveIndex(data.activeIndex)}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  }

  return <>
    <FormFields.Checkbox
      control={control}
      name='isTest'
      label={t('Is test environment ?')}
      defaultValue={0}
      checkboxValue={1}
    />
    {watchTestEnvironment > 0 && renderForm()}
  </>
}
export default Redirection
