import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableComponent from '~/components/TableComponent'
import i18n from '~/components/i18n'
import CreateEditHusbandryForm from './CreateEditHusbandryForm'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useParams } from 'react-router-dom'
import { PageHeaderActionButton } from '~/components/Layout/style'
import { HusbandryQueries } from '~/QueryHooks/HusbandryQueries'
import DeleteHusbandryForm from './DeleteHusbandryForm'

const actions = [
  {
    popupMessage: i18n.t('Edit'),
    type: 'icon',
    name: 'pencil',
    size: 'large',
    actionType: 'edit',
    displayedText: i18n.t('No actions')
  },
  {
    popupMessage: i18n.t('Delete'),
    type: 'icon',
    name: 'trash',
    color: 'red',
    size: 'large',
    actionType: 'delete',
    verifiedAction: 1,
    verifiedActionKey: 'deleteAvailable'
  }
]

const tableColumns = [
  {
    name: i18n.t('Name'),
    key: 'name'
  },
  {
    name: i18n.t('Abbreviation'),
    key: 'abbreviation'
  },
  {
    name: i18n.t('Description'),
    key: 'description'
  },
  {
    name: i18n.t('Color'),
    key: 'color',
    type: 'color-label'
  },
  {
    name: i18n.t('Actions')
  }
];

const FormsOfHusbandry: React.FC = () => {
  const { t } = useTranslation()
  const [drawer, setDrawerState] = useState({
    isVisible: false,
    id: null
  })
  const { id } = useParams()
  const [selectedHusbandry, setSelectedHusbandry] = useState(null)

  const {
    isLoading: isBusyData,
    data: husbandryForms,
    refetch: fetchData
  } = HusbandryQueries.getHusbandryForms(id)

  function handleActionClick(row, type) {
    switch (type) {
      case 'edit':
        return setDrawerState({ isVisible: true, id: row?.id })
      case 'delete':
        return setSelectedHusbandry(row)
      default:
        return
    }
  }

  function renderTable() {
    return <TableComponent
      tableColumnHeaders={tableColumns}
      rows={husbandryForms || []}
      total={husbandryForms?.length || 0}
      handleClickAction={handleActionClick}
      removePagination
      actions={actions}
    />
  }

  function renderActions() {
    return <PageHeaderActionButton
      onClick={() => setDrawerState({ isVisible: true, id: null })}
    >{t('Add new husbandry form')}</PageHeaderActionButton>
  }

  function renderEditCreateState() {
    return <CreateEditHusbandryForm
      isVisible={drawer?.isVisible}
      idHusbandryForm={drawer?.id}
      fetchData={fetchData}
      handleHiddenDrawer={() => setDrawerState({ isVisible: false, id: null })}
    />
  }

  function renderDeleteModal() {
    return <DeleteHusbandryForm
      selectedHusbandry={selectedHusbandry}
      handleClose={(loadNew) => {
        if (loadNew) {
          fetchData()
        }
        setSelectedHusbandry(null)
      }}
    />
  }

  return <DairyViewTabsLayout
    header={t('Forms of husbandry')}
    isBusy={isBusyData}
    segmented
    actions={renderActions()}
  >
    {selectedHusbandry && renderDeleteModal()}
    {renderEditCreateState()}
    {husbandryForms && renderTable()}
  </DairyViewTabsLayout>
}
export default FormsOfHusbandry