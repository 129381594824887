import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Form, Header, Icon, Input, Segment, SemanticICONS, Tab } from 'semantic-ui-react'
import ColorPicker from '~/components/ColorPicker'
import FileUpload from '~/components/FileUpload'
import { FormFields } from '~/components/FormFields'
import { emailRegex } from '~/Constants'
import Support from './Support'
import { StyledImageWrapper, StyledImageContainer } from '~/pages/Profile/style'
import { StyledLabel } from '~/pages/Users/style'

const supportIcons = ['headphones', 'assistive listening systems', 'phone volume']
const defaultImage = '/assets/default.png'

const IsCooperatingForm = (props) => {
  const { control, farmerGroupOptions, isSubmitted, setValue, watch, getValues } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const { t } = useTranslation()
  const watchSupportStatus = watch('support.status')
  const [logo, headIcon32] = watch(['settings.logo', 'settings.headIcon32'])

  const watchPhoneCodeField = watch(`office.phonePrefix`)
  const watchTelefaxCodeField = watch('office.telefaxNumber')
  const telefaxPrefix = watch('office.telefaxPrefix')
  const phoneNumber = watch(`office.phoneNumber`)

  useEffect(() => {
    if (watchSupportStatus) {
      const languages = getValues('support.languages')
      languages.map((_, index) => {
        setValue(`support.languages[${index}].id`, farmerGroupOptions.languages[index].id)
      })
    }
  }, [watchSupportStatus])

  function renderPreviewImage(value: string, field: string) {
    return value && <StyledImageWrapper>
      <img src={value ? value : defaultImage}></img>
      <StyledImageContainer />
      {value && <Icon
        circular
        //@ts-ignore
        onClick={(e) => {
          e.preventDefault();
          setValue(field.toString(), null)
        }}
        name='trash'
        className='trashCan'
      />
      }
    </StyledImageWrapper>
  }

  function generalInformationForm() {
    return <div style={{ display: 'flex' }}>
      <div style={{ width: '45%', marginRight: '30px' }}>
        <FormFields.External
          name='settings.logo'
          label={t('Logo')}
          control={control}
        >
          <FileUpload
            description={t('Drag and drop Logo')}
            multiple={false}
            encodeFiles
            mimeTypes={['bmp', 'jpg', 'jpeg', 'png']}
            sizeLimitInMB={5}
          >
            {renderPreviewImage(logo, 'settings.logo')}
          </FileUpload>
        </FormFields.External>
        <FormFields.Input
          label={t('Company name')}
          placeholder={t('Company name')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter company name')
            }
          }}
          name={'settings.companyName'}
        />
        <FormFields.Input
          label={t('Header title')}
          placeholder={t('Header title')}
          rules={{
            required: {
              value: true,
              message: t('Please enter header tittle')
            }
          }}
          control={control}
          name={'settings.headerTitle'}
        />
        <FormFields.Input
          autoComplete='nofill'
          label={t('Email Header')}
          placeholder={t('Email Header')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter email header')
            }
          }}
          name={'settings.emailHeader'}
        />
      </div>
      <div style={{ width: '45%' }}>
        <FormFields.Input
          label={t('Facebook URL')}
          control={control}
          name={t('settings.facebookUrl')}
          placeholder={t('Facebook URL')}
        />
        <FormFields.Input
          label={t('Website URL')}
          control={control}
          name={t('settings.websiteUrl')}
          placeholder={t('Website URL')}
        />
        <FormFields.Input
          label={t('Contact URL')}
          control={control}
          name={t('settings.contactUrl')}
          placeholder={t('Contact URL')}
        />
        <FormFields.Input
          label={t('Impressum URL')}
          control={control}
          name={t('settings.impressumURL')}
          placeholder={t('Impressum URL')}
        />
        <FormFields.Input
          label={t('Data privacy URL')}
          control={control}
          name={t('settings.dataPrivacyUrl')}
          placeholder={t('Data privacy URL')}
        />
      </div>
    </div>
  }

  function headIconAndColorsForm() {
    return <div style={{ display: 'flex' }}>
      <div style={{ width: '45%', marginRight: '30px' }}>
        <FormFields.External
          name='settings.headIcon32'
          label={t('Head icon')}
          rules={{
            required: {
              value: true,
              message: t('Please upload head icon')
            }
          }}
          control={control}
        >
          <FileUpload
            description={t('Drag and drop Logo')}
            multiple={false}
            encodeFiles
            mimeTypes={['bmp', 'jpg', 'jpeg', 'png', 'ico', 'x-icon']}
            sizeLimitInMB={5}
          >
            {renderPreviewImage(headIcon32, 'settings.headIcon32')}
          </FileUpload>
        </FormFields.External>
        <FormFields.External
          fluid
          name={'settings.firstColor'}
          label={t('First color')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter first color')
            }
          }}
        >
          <ColorPicker>
            <FormFields.Input
              style={{ background: 'transparent' }}
              control={control}
              hideError
              placeholder={t('First color')}
              name={'settings.firstColor'}
            />
          </ColorPicker>
        </FormFields.External>
        <FormFields.External
          fluid
          name={'settings.secondColor'}
          label={t('Second color')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter second color')
            }
          }}
        >
          <ColorPicker>
            <FormFields.Input
              control={control}
              hideError
              placeholder={t('Second color')}
              name={'settings.secondColor'}
            />
          </ColorPicker>
        </FormFields.External>
      </div>
      <div style={{ width: '45%' }}>
        <FormFields.External
          name={'settings.chartBarColor'}
          label={t('Bar chart color')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter bar chart color')
            }
          }}
        >
          <ColorPicker>
            <FormFields.Input
              control={control}
              hideError
              placeholder={t('Bar chart color')}
              name={'settings.chartBarColor'}
            />
          </ColorPicker>
        </FormFields.External>
        <FormFields.External
          name={'settings.chartBarColor2'}
          label={t('Second bar chart color')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter line chart color2')
            }
          }}
        >
          <ColorPicker>
            <FormFields.Input
              control={control}
              hideError
              placeholder={t('Second bar chart color')}
              name={'settings.chartBarColor2'}
            />
          </ColorPicker>
        </FormFields.External>
        <FormFields.External
          name={'settings.chartLineColor'}
          label={t('Line chart color')}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter line chart color')
            }
          }}
        >
          <ColorPicker>
            <FormFields.Input
              control={control}
              hideError
              placeholder={t('Line chart color')}
              name={'settings.chartLineColor'}
            />
          </ColorPicker>
        </FormFields.External>
      </div>
    </div>
  }

  function displayErrorPhone() {
    if (isSubmitted) {
      return (!phoneNumber || phoneNumber === '') || (!watchPhoneCodeField || watchPhoneCodeField === '')
    }
    return false
  }

  function officeDetailsForm() {
    return <div style={{ display: 'flex' }}>
      <div style={{ width: '45%', marginRight: '30px' }}>
        <FormFields.Input
          control={control}
          name={'office.name'}
          rules={{
            required: {
              value: true,
              message: t('Please enter office name')
            },
            maxLength: {
              value: 100,
              message: t('Max char is 100')
            }
          }}
          placeholder={t('Office name')}
          label={t('Office name')}
        />
        <FormFields.Input
          control={control}
          placeholder={t('Office email')}
          label={t('Office email')}
          name={'office.officeEmail'}
          rules={{
            required: {
              value: true,
              message: t('Please select office email')
            },
            pattern: {
              value: emailRegex,
              message: t('Please enter valid email')
            }
          }}
        />
        <FormFields.Select
          control={control}
          selectOnBlur={false}
          options={farmerGroupOptions?.countries.map(country => ({ value: country.id, text: country.label }))}
          rules={{
            required: {
              value: true,
              message: t('Please please select country')
            }
          }}
          placeholder={t('Country')}
          name={'office.address.countryId'}
          label={t('Country')}
        />
        <Form.Field required>
          <label>{t('Phone number')}</label>
          <Input
            error={isSubmitted && (!phoneNumber || phoneNumber === '')}
            value={phoneNumber}
            label={<Dropdown
              error={isSubmitted && (!watchPhoneCodeField || watchPhoneCodeField === '')}
              options={farmerGroupOptions?.countries.map(countryCode => (
                {
                  value: countryCode.id,
                  text: countryCode.phoneCode,
                  flag: countryCode?.iso
                }
              ))}
              selectOnBlur={false}
              value={watchPhoneCodeField}
              scrolling
              basic
              placeholder={t('Code')}
              onChange={(e, data) => setValue(`office.phonePrefix`, data.value)}
              name={`office.phonePrefix`}
            />}
            onChange={(e, data) => {
              setValue(`office.phoneNumber`, data.value)
            }}
            labelPosition='left'
            placeholder={watchPhoneCodeField ? t('Phone number') : t('Please select phone code')}
          />
          {displayErrorPhone() && <StyledLabel basic pointing={'above'}>{t('Please enter phone number and phone code')}</StyledLabel>}
        </Form.Field>
        <Form.Field>
          <label>{t('Telefax number')}</label>
          <Input
            value={watchTelefaxCodeField}
            label={<Dropdown
              options={farmerGroupOptions?.countries.map(countryCode => (
                {
                  value: countryCode.id,
                  text: countryCode.phoneCode,
                  flag: countryCode?.iso
                }
              ))}
              selectOnBlur={false}
              value={telefaxPrefix}
              scrolling
              basic
              placeholder={t('Code')}
              onChange={(e, data) => setValue(`office.telefaxPrefix`, data.value)}
              name={`office.telefaxPrefix`}
            />}
            onChange={(e, data) => {
              setValue(`office.telefaxNumber`, data.value)
            }}
            labelPosition='left'
            placeholder={telefaxPrefix ? t('Telefax number') : t('Please select telefax code')}
          />
        </Form.Field>
      </div>
      <div style={{ width: '45%' }}>
        <FormFields.Input
          control={control}
          rules={{
            required: {
              value: true,
              message: t('Please enter street name')
            },
            maxLength: {
              value: 100,
              message: t('Max char is 100')
            }
          }}
          name={'office.address.streetName'}
          placeholder={t('Street name')}
          label={t('Street name')}
        />
        <FormFields.Input
          control={control}
          name={'office.address.streetNumber'}
          rules={{
            required: {
              value: true,
              message: t('Please enter street number')
            },
            maxLength: {
              value: 100,
              message: t('Max char is 100')
            }
          }}
          type='number'
          placeholder={t('Street number')}
          label={t('Street number')}
        />
        <FormFields.Input
          control={control}
          name={'office.address.city'}
          rules={{
            required: {
              value: true,
              message: t('Please enter city')
            },
            maxLength: {
              value: 100,
              message: t('Max char is 100')
            }
          }}
          placeholder={t('City')}
          label={t('City')}
        />
        <FormFields.Input
          control={control}
          name={'office.address.zip'}
          rules={{
            required: {
              value: true,
              message: t('Please enter zip code')
            },
            maxLength: {
              value: 10,
              message: t('Max char is 10')
            }
          }}
          placeholder={t('Zip Code')}
          label={t('Zip Code')}
        />
        <FormFields.Input
          control={control}
          name={'office.address.district'}
          placeholder={t('District')}
          label={t('District')}
        />
      </div>
    </div>
  }

  function supportForm() {
    return <>
      <Form.Group widths={5}>
        <FormFields.Checkbox
          label={t('Show support information')}
          defaultValue={0}
          checkboxValue={1}
          control={control}
          name={'support.status'}
        />
        {supportIcons.map((supportIcon) => {
          return <div key={supportIcon} style={{ display: 'flex', marginRight: 10 }}>
            <FormFields.Checkbox
              name={'support.icon'}
              label={''}
              radio
              icon={supportIcon}
              checkboxValue={supportIcon}
              fitted
              key={supportIcon}
              id={supportIcon}
              control={control}
            />
            <Icon name={supportIcon as SemanticICONS} />
          </div>
        })}
      </Form.Group>
      {Boolean(watchSupportStatus) && <Form.Field>
        <Tab
          activeIndex={activeIndex}
          renderActiveOnly={false}
          // @ts-ignore: Unreachable code error
          onTabChange={(_, data) => setActiveIndex(data.activeIndex)}
          style={{ marginTop: 10 }}
          panes={
            farmerGroupOptions.languages.map((lan, index) => {
              return {
                menuItem: lan.label,
                pane: <Tab.Pane
                  children={<Support
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    index={index}
                    farmerGroupOptions={farmerGroupOptions}
                  />}
                  active={index === activeIndex}
                  key={lan.id}
                />
              }
            })}
        >
        </Tab>

      </Form.Field>
      }
    </>
  }

  return <>
    <Header attached='top' block>{t('General info')}</Header>
    <Segment style={{ border: 'none', background: 'none' }} attached>
      {generalInformationForm()}
    </Segment>
    <Header attached='top' block>{t('Head icon & colors')}</Header>
    <Segment style={{ border: 'none', background: 'none' }} attached>
      {headIconAndColorsForm()}
    </Segment>
    <Header attached='top' block>{t('Office details')}</Header>
    <Segment style={{ border: 0, background: 'none' }} attached>
      {officeDetailsForm()}
    </Segment>
    <Header attached='top' block>{t('Support')}</Header>
    <Segment style={{ border: 0, background: 'none' }} attached>
      {supportForm()}
    </Segment>
  </>
}
export default IsCooperatingForm
