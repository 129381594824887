import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'
import { UpdateLogsApi } from '~/services'
import { Button, Dropdown, Icon, Label, Pagination, Popup, Table } from 'semantic-ui-react'
import dayjs from 'dayjs'
import CreateUpdateLogs from './CreateEditUpdateLogs'
import InlineDialog from '~/components/InlineDialog'
import notify from '~/components/notify'
import { optionsLimit } from '~/Constants'
import { TableFooterWrapper } from '../Users/style'
import { UpdateLogOptionsType } from '~/services/updateLogs'
import { useSelector } from 'react-redux'
import { SystemDataState } from '~/reducers'
import { SystemDataType } from '~/services/login'
import i18n from '~/components/i18n'

const tableColumns = [
  {
    label: i18n.t('Version'),
    value: 'version'
  },
  {
    label: i18n.t('Date'),
    value: 'date',
    type: 'date'
  },
  {
    label: i18n.t('Visible'),
    value: 'visible'
  },
  {
    label: i18n.t('Created'),
    value: 'created_at',
    type: 'date'
  },
  {
    label: i18n.t('Action'),
    value: <Popup
      trigger={<Icon size='large' name='remove circle' style={{ cursor: 'pointer' }} />}
      content={'Remove'}
    />
  }
]

const UpdateLogs: React.FC = () => {
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const [isBusy, setIsBusy] = useState(false)
  const [updateLogs, setUpdateLogs] = useState([])
  const [drawer, setDrawerState] = useState({
    isVisible: false,
    id: null
  })
  const [total, setTotal] = useState(0)
  const [options, setOptions] = useState<UpdateLogOptionsType>()
  const { t } = useTranslation()
  const [dataFiltered, setDataFiltered] = useState({
    page: 0,
    rows: systemData.defaultRowsPerPage
  })

  useEffect(() => {
    fetchUpdateLogsData()
  }, [])

  useEffect(() => {
    fetchFilteredData()
  }, [dataFiltered])

  async function fetchFilteredData() {
    setIsBusy(true)
    try {
      const response = await UpdateLogsApi.getUpdateLogs({ ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })
      setUpdateLogs(response.data.rows)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function fetchUpdateLogsData() {
    setIsBusy(true)
    try {
      const response = await UpdateLogsApi.getUpdateLogs()
      const responseOptions = await UpdateLogsApi.getOptionsForLogs()
      setTotal(response.data.total)
      setUpdateLogs(response.data.rows)
      setOptions(responseOptions.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function tableHeaders() {
    return <Table.Row>
      {tableColumns.map(tableColumn => {
        return <Table.HeaderCell key={tableColumn.label}>{tableColumn.label}</Table.HeaderCell>
      })}
    </Table.Row>
  }

  async function eraseItem(id: string) {
    setIsBusy(true)
    try {
      await UpdateLogsApi.deleteLog(id)
      const response = await UpdateLogsApi.getUpdateLogs()
      setTotal(response.data.total)
      setUpdateLogs(response.data.rows)
      notify(t('Successfully deleted log'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function getVisibleIcons(value) {
    return value == 1 ? <Icon color='green' name='check circle outline' /> : <Icon color='red' name='close' />
  }

  function getTableCell(value, log, type = null) {
    const cellValue = typeof (value) === 'string' ? value === 'visible' ? getVisibleIcons(log[value]) : log[value] : <InlineDialog
      control={<Button basic icon='trash' />}
      text={t('Really delete?')}
      action={() => eraseItem(log.id)} />
    if (value === 'version') {
      return <Table.Cell
        style={{ color: '#4183c4', cursor: 'pointer' }}
        key={value + log.id}
        onClick={() => setDrawerState({ isVisible: true, id: log.id })}
      >
        {cellValue}
      </Table.Cell>
    }
    return <Table.Cell key={value + log.id}>{type === 'date' ? dayjs(cellValue).format('DD.MM.YYYY') : cellValue}</Table.Cell>
  }

  function tableBody() {
    return <Table.Body>
      {updateLogs.map(log => {
        return <Table.Row key={log.id}>
          {tableColumns.map(tableColumn => {
            return getTableCell(tableColumn.value, log, tableColumn.type)
          })}
        </Table.Row>
      })}

    </Table.Body>
  }

  function renderTable() {
    return <Table celled padded>
      <Table.Header>
        {tableHeaders()}
      </Table.Header>
      {updateLogs.length > 0 && tableBody()}
    </Table>
  }

  function renderPagination() {
    return <div>
      <Pagination
        onPageChange={(_, data) => setDataFiltered({ ...dataFiltered, page: parseInt(data.activePage.toString()) })}
        activePage={dataFiltered.page === 0 ? 1 : dataFiltered.page}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
        totalPages={Math.ceil(total / dataFiltered.rows)} />
    </div>
  }

  function renderTableFooter() {
    return <TableFooterWrapper>
      <div>
        <Dropdown basic button size='small' value={dataFiltered.rows}
          onChange={(e, data) => {
            setDataFiltered({
              ...dataFiltered,
              rows: data.value as number
            })
          }}
          options={optionsLimit}
        />
        <Label basic pointing={'left'}>
          {t('Total: ')}
          <Label.Detail>{total}</Label.Detail>
        </Label>
      </div>
      {renderPagination()}
    </TableFooterWrapper>
  }

  function renderContent() {
    return <>
      {renderTable()}
      {renderTableFooter()}
    </>
  }

  return <Layout
    title={t('Update logs')}
    isBusy={isBusy}
    segmented
    action={() => setDrawerState({
      ...drawer,
      isVisible: true
    })}
  >
    <CreateUpdateLogs
      isVisible={drawer.isVisible}
      id={drawer.id}
      dairiesOptions={options?.dairies || []}
      handleHiddenDrawer={() => {
        setDrawerState({ isVisible: false, id: null })
        fetchUpdateLogsData()
      }}
    />
    {renderContent()}
  </Layout>
}

export default UpdateLogs
