import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dropdown, Form, Input } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'

interface PhoneFaxProps {
  farmersOptions: any[]
}

const PhoneFax: React.FC<PhoneFaxProps> = (props) => {
  const { t } = useTranslation()
  const { farmersOptions } = props

  const { control, setValue } = useFormContext()

  const phoneNumber = useWatch({ control, name: 'phoneNumber' })
  const telefaxNumber = useWatch({ control, name: 'telefaxNumber' })
  const watchPhonePrefixField = useWatch({ control, name: 'phonePrefix' })
  const wathcTelefaxPrefixField = useWatch({ control, name: 'telefaxPrefix' })

  return <>
    <FormFields.Select
      control={control}
      placeholder={t('Country')}
      selectOnBlur={false}
      name='countryId'
      rules={{
        required: {
          value: true,
          message: t('Please select Country')
        }
      }}
      label={t('Country')}
      //@ts-ignore
      options={farmersOptions?.countries.map(country => ({ value: country.id, text: country.label, flag: country.iso }))}
    />
    <Form.Field>
      <label>{t('Phone number')}</label>
      <Input
        style={{ minWidth: 300 }}
        value={phoneNumber}
        label={<Dropdown
          scrolling
          basic
          clearable
          //@ts-ignore
          options={farmersOptions?.countries?.map(country => ({ value: country.id, text: country.phoneCode, flag: country.iso })) || []}
          selectOnBlur={false}
          onChange={(e, data) => setValue('phonePrefix', data.value)}
          value={parseInt(watchPhonePrefixField) || ''}
          placeholder={t('Code')}
          name={'phonePrefix'}
        />}
        onChange={(e, data) => setValue('phoneNumber', data.value)}
        labelPosition='left'
        type={'number'}
        placeholder={watchPhonePrefixField ? t('Phone number') : t('Please select phone code')}
        name={'phoneNumber'}
      />
    </Form.Field>
    <Form.Field>
      <label>{t('Telefax number')}</label>
      <Input
        style={{ minWidth: 300 }}
        value={telefaxNumber}
        placeholder={t('Telefax number')}
        label={<Dropdown
          scrolling
          basic
          //@ts-ignore
          options={farmersOptions?.countries.map(country => ({ value: country.id, text: country.phoneCode, flag: country.iso }))}
          selectOnBlur={false}
          clearable
          onChange={(e, data) => setValue('telefaxPrefix', data.value)}
          value={parseInt(wathcTelefaxPrefixField) || ''}
          placeholder={t('Code')}
          name={'telefaxPrefix'}
        />}
        onChange={(e, data) => setValue('telefaxNumber', data.value)}
        labelPosition='left'
        type={'number'}
        name={'telefaxNumber'}
      />
    </Form.Field>
  </>
}
export default PhoneFax