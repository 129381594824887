import React, { useState } from 'react'

// Style
import {
  LoginFormWrapper,
  loginForm,
  FormLogo,
  FormHeading,
  loginFormField,
  loginFormRememberFieldWrapper,
  loginFormForgotPassword,
  FormTermsAndPrivacy,
  loginFormSubmitButton,
  loginFormSubmitWrapper,
  FormParagraph,
  loginFormBackIcon,
  loginFormRememberField
} from './style'
import { Container, AuthGrid, AuthLeftSide, AuthRightSide } from '~/styles/app'

import { useForm } from 'react-hook-form'
import { Form, Label, Dimmer, Icon, Popup, Button, Checkbox } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { useTranslation } from 'react-i18next'
import { ADMIN_TOKEN, emailRegex } from '~/Constants'
import { useDispatch } from 'react-redux'
import { systemData } from '~/actions/systemData'
import { LoginApi } from '~/services'
import notify from '~/components/notify'

import LoaderMilkCan from '~/components/Loader'

interface LoginData {
  email: string,
  password: string
}

const Login: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { handleSubmit, control, reset } = useForm<LoginData | { email: string }>({
    mode: 'onSubmit'
  })

  async function onSubmitLogin(data: LoginData) {
    setIsBusy(true)
    try {
      const response = await LoginApi.login(data)
      localStorage.setItem(ADMIN_TOKEN, response.data.token)
      /*  const responseUser = await LoginApi.getUser()
      dispatch(userData.setUserData(responseUser.data)) */
      const responseSystemData = await LoginApi.getSystemData()
      dispatch(systemData.setSystemData(responseSystemData.data))
      notify(t('Successfully logged in'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function onSubmitResetPassword(data: { email: string }) {
    setIsBusy(true)
    try {
      await LoginApi.forgotPassword(data)
      setForgotPassword(false)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderForgotPasswordForm() {
    return <Form style={loginForm} onSubmit={handleSubmit(onSubmitResetPassword)}>
      <FormLogo>{t('Food Business Digital')}</FormLogo>
      <FormHeading>
        <Popup
          content={t('Return to login page')}
          trigger={<Icon
            size='small'
            style={loginFormBackIcon}
            onClick={() => setForgotPassword(false)}
            name='chevron left'
          />}
        />
        {t('Reset password')}
      </FormHeading>
      <FormParagraph>{t('You will receive an email for resetting your password.')}</FormParagraph>
      <FormFields.Input
        name='email'
        type='text'
        key={'emailForgotPassword'}
        id={'emailForgotPassword'}
        style={loginFormField}
        rules={{
          required: {
            value: true,
            message: t('Please enter email')
          },
          pattern: {
            value: emailRegex,
            message: t('Please enter valid email')
          }
        }}
        placeholder={t('Email')}
        control={control}
      />
      <Form.Field style={loginFormSubmitWrapper}>
        <Button
          style={loginFormSubmitButton}
          primary
          content={t('Reset password')}
        />
      </Form.Field>
      <FormTermsAndPrivacy target='blank' href='https://foodbusiness.digital/'>
        {'Powered by ©: FoodBusiness.Digital GmbH'}
      </FormTermsAndPrivacy>
    </Form>
  }

  function renderLoginForm() {
    return <Form style={loginForm} onSubmit={handleSubmit(onSubmitLogin)}>
      <FormLogo>{t('Food Business Digital')}</FormLogo>
      <FormHeading>{t('Sign In')}</FormHeading>
      <FormFields.Input style={loginFormField}
        name="email"
        type="text"
        size="large"
        rules={{
          required: {
            value: true,
            message: t('Please enter email')
          },
          pattern: {
            value: emailRegex,
            message: t('Please enter valid email')
          }
        }}
        placeholder={t('Email')}
        control={control}
      />

      <FormFields.Input style={loginFormField}
        name="password"
        type="password"
        size="large"
        rules={{
          required: {
            value: true,
            message: t('Please enter password')
          }
        }}
        placeholder={t('Password')}
        control={control}
      />

      <Form.Field style={loginFormRememberFieldWrapper}>
        <Checkbox
          style={loginFormRememberField}
          name="remember"
          label={t('Remember me')}
          control={control}
        />
        <Label
          onClick={() => {
            reset()
            setForgotPassword(true)
          }}
          style={loginFormForgotPassword}
          basic>{t('Forgot Password?')}
        </Label>
      </Form.Field>
      <Form.Field style={loginFormSubmitWrapper}>
        <Button
          style={loginFormSubmitButton}
          primary
          content={t('Login')}
        />
      </Form.Field>
      <FormTermsAndPrivacy target='blank' href='https://foodbusiness.digital/'>
        {'Powered by ©: FoodBusiness.Digital GmbH'}
      </FormTermsAndPrivacy>
    </Form>
  }

  return <LoginFormWrapper>
    <Container>
      <AuthGrid>
        <AuthLeftSide></AuthLeftSide>
        <AuthRightSide>
          <Dimmer inverted active={isBusy}>
            <LoaderMilkCan isLoading={isBusy} />
          </Dimmer>
          {forgotPassword ? renderForgotPasswordForm() : renderLoginForm()}
        </AuthRightSide>
      </AuthGrid>
    </Container>
  </LoginFormWrapper>
}

export default Login
