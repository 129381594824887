import Input from './Input'
import Select from './Select'
import Checkbox from './Checkbox'
import TextArea from './TextArea'
import External from './External'

export const FormFields = {
  Input: Input,
  Select: Select,
  Checkbox: Checkbox,
  TextArea: TextArea,
  External: External
}
