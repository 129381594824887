import React, { useState, useEffect } from 'react'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PageHeaderActionButton } from '~/components/Layout/style'
import notify from '~/components/notify'
import { RouteTypes, emailRegex } from '~/Constants'
import { DairyApi } from '~/services'
import { useFieldArray, useForm } from 'react-hook-form'
import { FormFields } from '~/components/FormFields'
import { Button, Form, Popup } from 'semantic-ui-react'

const CreateEditStoneX: React.FC = () => {
  const { t } = useTranslation()
  const { id, idAccount } = useParams()
  const history = useHistory()
  const [loadingCheckConnection, setLoadingCheckConnection] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [options, setOptions] = useState(null)
  const { handleSubmit, control, watch, formState: { }, reset } = useForm({
    mode: 'onSubmit'
  })

  const notificationEmails = useFieldArray({
    control,
    //@ts-ignore
    name: 'notificationEmails'
  });

  const watchNotificatonStatus = watch('notificationStatus')
  const watchUserName = watch('username')
  const watchPassword = watch('password')
  const watchApiKey = watch('apiKey')

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const responseOptions = await DairyApi.OptionsStoneXAccount(id)
      setOptions(responseOptions.data)
      if (idAccount) {
        const response = await DairyApi.EditStoneXAccount(id, idAccount)
        response.data.notificationEmails = response.data.notificationEmails?.map(notificationEmail => ({ email: notificationEmail }))
        reset(response.data)
      }
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function checkConnection() {
    setLoadingCheckConnection(true)
    try {
      const response = await DairyApi.CheckConnectionStoneXAccount(id, {
        password: watchPassword,
        username: watchUserName,
        apiKey: watchApiKey
      })
      if (response.data) {
        notify(t('Connection to StoneX was successfull'), 'success')
      } else {
        notify(t('Connection to StoneX failed'), 'error')
      }
    } catch (error) {
      notify(t('Connection to StoneX failed'), 'error')
    }
    setLoadingCheckConnection(false)
  }

  function renderActions() {
    return <div>
      <PageHeaderActionButton>{idAccount ? t('Update') : t('Save')}</PageHeaderActionButton>
      <PageHeaderActionButton type='button' onClick={() => history.goBack()}>{t('Cancel')}</PageHeaderActionButton>
      <PageHeaderActionButton
        loading={loadingCheckConnection}
        type='button'
        disabled={(!watchPassword || watchPassword === '') || (!watchApiKey || watchApiKey === '') || (!watchUserName || watchUserName === '')}
        onClick={() => checkConnection()}>{t('Check connection')}
      </PageHeaderActionButton>
    </div>
  }

  async function onSubmit(data) {
    data.notificationEmails?.forEach((notificationEmail, index) => {
      data.notificationEmails[index] = notificationEmail.email
    })
    setIsBusy(true)
    try {
      if (!idAccount) {
        await DairyApi.StoreStoneXAccount(id, data)
        notify(t('Successfully created StoneX account'), 'success')
      } else {
        await DairyApi.UpdateStoneXAccount(id, idAccount, data)
        notify(t('Successfully updated StoneX account'), 'success')
      }
      history.push(RouteTypes.DAIRY_FACTORY_VIEW_STONEX.replace(':id', id))
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderForm() {
    return <>
      <FormFields.Checkbox
        control={control}
        name={'status'}
        label={t('Status')}
        defaultValue={0}
        checkboxValue={1}
      />
      <Form.Group widths={'equal'}>
        <FormFields.Input
          control={control}
          name={'name'}
          label={t('Name')}
          rules={{
            required: {
              value: true,
              message: t('Please enter name')
            }
          }}
          placeholder={t('Enter name')}
        />
        <FormFields.Input
          control={control}
          name={'username'}
          label={t('Username')}
          rules={{
            required: {
              value: true,
              message: t('Please enter username')
            }
          }}
          placeholder={t('Enter username')}
        />
        <FormFields.Input
          control={control}
          name={'password'}
          label={t('Password')}
          rules={{
            required: {
              value: true,
              message: t('Please enter password')
            }
          }}
          placeholder={t('Enter password')}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFields.Input
          control={control}
          name={'apiKey'}
          label={t('API key')}
          rules={{
            required: {
              value: true,
              message: t('Please enter API key')
            }
          }}
          placeholder={t('Enter API key')}
        />
        <FormFields.Input
          control={control}
          name={'interval'}
          label={t('Interval')}
          rules={{
            required: {
              value: true,
              message: t('Please enter interval')
            },
            min: {
              value: 1,
              message: t('Min value is 1')
            },
            max: {
              value: 1440,
              message: t('Max. value is 1440')
            }
          }}
          type='number'
          placeholder={t('Enter interval')}
        />
      </Form.Group>
      <FormFields.Select
        control={control}
        options={options?.days?.map(option => ({ value: option.key.toString(), text: option.label })) || []}
        width={8}
        selection
        placeholder={t('Select days')}
        rules={{
          required: {
            value: true,
            message: t('Please select days')
          }
        }}
        name={'schedulerDays'}
        multiple
        label={t('Scheduler days')}
        selectOnBlur={false}
      />
      <FormFields.Checkbox
        control={control}
        name={'notificationStatus'}
        label={t('Notification status')}
        defaultValue={0}
        checkboxValue={1}
      />
      <Form.Field
        required={Boolean(watchNotificatonStatus)}
        style={{ display: 'flex', alignItems: 'baseline' }}
      >
        <label style={{ marginRight: 10 }}>{t('Notification emails')}</label>
        <Popup
          content={t('Add new notification email')}
          trigger={
            <Button
              onClick={() => notificationEmails.append({ email: '' })}
              primary
              size='mini'
              type={'button'}
              icon='plus'
            />
          }
        />
      </Form.Field>
      <>
        {notificationEmails.fields.map((notificationEmail, notificationEmailIndex) => {
          return <Form.Group key={notificationEmail.id}>
            <FormFields.Input
              control={control}
              width={12}
              placeholder={t('Email')}
              label={t('New email')}
              autoComplete='nofill'
              //@ts-ignore
              name={`notificationEmails[${notificationEmailIndex}].email`}
              rules={{
                required: {
                  value: true,
                  message: t('Please enter email')
                },
                pattern: {
                  value: emailRegex,
                  message: t('Please enter valid email')
                }
              }}
            />
            <Form.Button
              style={{ marginLeft: 10, marginTop: 24 }}
              onClick={() => notificationEmails.remove(notificationEmailIndex)}
              size={'small'}
              basic
              color='red'
              type={'button'}
              icon='trash'
            />
          </Form.Group>
        })}
      </>
    </>
  }

  return <DairyViewTabsLayout
    actions={renderActions()}
    header={!idAccount ? t('Create StoneX account') : t('Edit StoneX account')}
    onSubmit={handleSubmit(onSubmit)}
    isBusy={isBusy}
    segmented
  >
    {renderForm()}
  </DairyViewTabsLayout>
}
export default CreateEditStoneX