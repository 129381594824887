import React from 'react'
import MainMenu from '../MainMenu'
import { SemanticICONS, Menu, Form, Dropdown, Segment } from 'semantic-ui-react'
import dashboardItems from './dashboardItems'
import {
  LayoutStyled,
  Content,
  SidebarContainer,
  Wrapper,
  WrapperBox,
  SidebarIconMenu,
  SidebarTitleMenu,
  DimmerContainer,
  PageHeaderContainer,
  PageHeaderWrapper,
  PageHeaderActions,
  PageHeaderTitle,
  PageHeaderActionButton,
  MainGrid,
  PageHeaderActionBackButton,
  HeaderWrapper
} from './style'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoaderMilkCan from '../Loader'
import CustomIcon from '../CustomIcon'

type DashboardItemType = {
  id: string,
  title: string,
  icon: string,
  children?: DashboardItemType[] | null,
  link?: string
}

interface LayoutProps {
  title?: string,
  isBusy?: boolean,
  disableHeader?: boolean,
  filters?: React.ReactElement,
  children: React.ReactNode,
  /** Either action URL or function to be called on action. */
  action?: string | (() => void),
  /** Either URL to go to on close, or function to be called on close. */
  close?: string | ((e: unknown) => void),
  actionName?: string
  actions?: React.ReactElement | React.ReactElement[],
  onSubmit?: () => void,
  columns?: number,
  search?: React.ReactElement
  segmented?: boolean
}

const Layout = (props: LayoutProps) => {
  const { close, actions, action, actionName, onSubmit } = props
  const { t } = useTranslation()
  const location = useLocation()

  function linkIsActive(link: string, id?: string, children?: Array<DashboardItemType>) {
    if (children) {
      let active = false
      children.map(child => {
        if (location.pathname.includes(child.link)) {
          active = true
        }
      })
      return active
    }
    return location.pathname === '/' && id === 'dashboard' ? true : location.pathname.includes(link)
  }

  function renderItem(item: DashboardItemType) {
    const active = linkIsActive(item.link)
    return <Menu.Item
      as={Link}
      to={item.link}
      key={item.id}
      active={active}>
      <SidebarIconMenu name={item.icon as SemanticICONS} size='large' />
      <SidebarTitleMenu>
        {item.title}
      </SidebarTitleMenu>
    </Menu.Item>
  }

  function itemWithChildren(item: DashboardItemType) {
    const active = linkIsActive(item.link, null, item.children)
    return <Dropdown
      //@ts-ignore use html for text
      text={<>
        <SidebarIconMenu name={item.icon as SemanticICONS} size='large' />
        <SidebarTitleMenu>
          {item.title}
        </SidebarTitleMenu>
      </>}
      labeled
      icon={null}
      as={Menu.Item}
      active={active}
    >
      <Dropdown.Menu>
        {item.children.map(child => {
          return <Dropdown.Item
            key={child.id}
            as={Link}
            to={child.link}
            text={child.title}
          />
        })}
      </Dropdown.Menu>
    </Dropdown>
  }

  function renderDashboard() {
    return <SidebarContainer
      as={Menu}
      animation={'push'}
      direction={'left'}
      horizontal='true'
    >
      {dashboardItems.map((item: DashboardItemType) => {
        return item.children ? itemWithChildren(item) : renderItem(item)
      })}
    </SidebarContainer>
  }

  function renderCloseButton() {
    if (typeof (close) === 'string') {
      return <PageHeaderActionBackButton
        as={Link}
        to={close}
      >
        <CustomIcon name='arrow alternate circle left' size='big' />
      </PageHeaderActionBackButton>
    }
    else if (typeof (close) === 'function') {
      return <PageHeaderActionBackButton
        type={'button'}
        onClick={close}
      >
        <CustomIcon name='arrow alternate circle left' size='big' />
      </PageHeaderActionBackButton>
    }
  }

  function renderAction() {
    if (typeof (action) === 'function') {
      return <PageHeaderActionButton
        positive
        size='large'
        onClick={action}
      >
        {actionName || t('Create')}
      </PageHeaderActionButton>
    }
    if (typeof (action) === 'string') {
      return <PageHeaderActionButton
        size='large'
        type={onSubmit ? 'submit' : 'button'}
        as={onSubmit ? 'button' : Link}
        to={onSubmit ? null : action}
      >
        {t(onSubmit ? action : actionName || 'Create')}
      </PageHeaderActionButton>
    }
  }

  function renderPageHeader() {
    return <PageHeaderContainer>
      <PageHeaderWrapper>
        {close && renderCloseButton()}
        {props.title && <PageHeaderTitle as='h1'>{props.title}</PageHeaderTitle>}
        {props.filters}
      </PageHeaderWrapper>

      <PageHeaderActions>
        {actions}
        {action && renderAction()}
      </PageHeaderActions>
    </PageHeaderContainer>
  }

  return <>
    <LayoutStyled>
      <MainMenu />
      {renderDashboard()}
      <Wrapper>
        <WrapperBox>
          <Content onSubmit={onSubmit} as={onSubmit ? Form : 'div'}>
            <HeaderWrapper as={props.segmented ? Segment : 'div'}>
              {!props.disableHeader && renderPageHeader()}
            </HeaderWrapper>

            <DimmerContainer inverted active={props.isBusy}>
              <LoaderMilkCan isLoading={props.isBusy} />
            </DimmerContainer>

            <MainGrid columns={1} as={props.segmented ? Segment : MainGrid}>
              <MainGrid.Row stretched>
                <MainGrid.Column style={{ padding: 20 }}>
                  {props.children}
                </MainGrid.Column>
              </MainGrid.Row>
            </MainGrid>

          </Content>
        </WrapperBox>
      </Wrapper>
    </LayoutStyled>
  </>
}
export default Layout
