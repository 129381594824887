import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Layout from '~/components/Layout'
import { MILK_MONEY_ACC_SLUG, RouteTypes } from '~/Constants'
import notify from '~/components/notify'
import { DairyViewDataType } from '~/services/dairies'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Menu, Grid, Icon, Transition, SemanticICONS } from 'semantic-ui-react'
import DairyViewRouter from './DairyViewRouter'
import { DairyApi } from '~/services'
import { StyledMenu } from './style'

const DairyFactoryView: React.FC = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [dairyViewData, setDairyViewData] = useState<DairyViewDataType>()
  const [openMenus, setOpenMenus] = useState([])
  const [ifeVisible, setIfeVisible] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const { id } = useParams()


  const panes = [
    {
      menuItem: { key: 'location', icon: 'world', content: t('Location') },
      link: RouteTypes.DAIRY_FACTORY_VIEW_LOCATION.replace(':id', id),
      display: true
    },
    {
      menuItem: { key: 'users', icon: 'users', content: t('Users'), as: Link, to: RouteTypes.DAIRY_FACTORY_VIEW_USERS },
      link: RouteTypes.DAIRY_FACTORY_VIEW_USERS.replace(':id', id),
      display: true
    },
    {
      menuItem: { key: 'farmer-groups', icon: 'address book outline', content: t('Farmer groups') },
      link: RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_GROUP.replace(':id', id),
      display: true
    },
    {
      menuItem: { key: 'farmers', content: t('Farmers'), icon: 'group' },
      link: RouteTypes.DAIRY_FACTORY_VIEW_FARMERS.replace(':id', id),
      display: true
    },
    {
      menuItem: { key: 'forms-of-husbandry', content: t('Forms of husbandry'), icon: 'group' },
      link: RouteTypes.DAIRY_FACTORY_VIEW_FORMS_OF_HUSBANDRY.replace(':id', id),
      display: true
    },
    {
      menuItem: { key: 'milk money', content: t('Milk money'), icon: 'euro sign' },
      display: Boolean(dairyViewData?.modules.find(module => module.slug === MILK_MONEY_ACC_SLUG)),
      children: [
        {
          key: 'milk-types',
          icon: 'box',
          content: t('Milk types'),
          link: RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_TYPES.replace(':id', id),
        },
        {
          key: 'mga-factors',
          icon: 'globe',
          content: t('Mga factors'),
          link: RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTORS.replace(':id', id),
        },
        {
          key: 'milk-classes',
          icon: 'clipboard',
          content: t('Milk classes'),
          link: RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MILK_QUAL_CLASS.replace(':id', id),
        },
        {
          key: 'milk-ftps',
          icon: 'archive',
          content: t('FTPS'),
          link: RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_FTPS.replace(':id', id),
        }
      ]
    },
    {
      menuItem: { key: 'ife', icon: 'settings', content: t('Ife') },
      display: true,
      children: [{
        key: 'ife-settings',
        icon: 'settings',
        content: t('Settings'),
        link: RouteTypes.DAIRY_FACTORY_VIEW_IFE_SETTINGS.replace(':id', id),
      }]
    },
    {
      menuItem: { key: 'stoneaccounts', content: t('StoneX accounts'), icon: 'briefcase' },
      link: RouteTypes.DAIRY_FACTORY_VIEW_STONEX.replace(':id', id),
      display: Boolean(dairyViewData?.stonexEnabled)
    },
    {
      menuItem: { key: 'emails', content: 'Emails', icon: 'envelope' },
      link: RouteTypes.EMAILS.replace(':id', id),
      display: true
    }
  ]

  useEffect(() => {
    fetchViewData()
    panes.map((pane, index) => {
      if (tabIsActive(pane.link, index, pane.children)) {
        openMenus.push(pane.menuItem.key)
      }
    })
  }, [])

  async function fetchViewData() {
    setIsBusy(true)
    try {
      const responseViewDairy = await DairyApi.viewDairy(id)
      setDairyViewData(responseViewDairy.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function tabIsActive(link: string, index: number, children?) {
    if (children) {
      let active = false
      children.map(child => {
        if (location.pathname.includes(child.link)) {
          active = true
        }
      })
      return active
    }
    return index === 0 && location.pathname === RouteTypes.DAIRY_FACTORY_VIEW.replace(':id', id) ? true : location.pathname.includes(link)
  }

  function handleOpenMenu(idMenu) {
    if (openMenus.find(id => id === idMenu)) {
      setOpenMenus(openMenus.filter(id => id !== idMenu))
    } else {
      setOpenMenus([...openMenus, idMenu])
    }
  }


  function renderTab() {

    return <StyledMenu vertical>
      {panes.map((pane, index) => {
        const itemMenuIsOpen = Boolean(pane.children && openMenus.find(key => key === pane.menuItem.key))
        return pane.display && <Menu.Item
          active={tabIsActive(pane.link, index)}
          as={pane.children ? 'div' : Link}
          to={pane.link}
          key={pane.menuItem.key}>
          <div onClick={() => pane.children && handleOpenMenu(pane.menuItem.key)} className='itemWrapper'>
            <div className={'titleWrapper'}>
              <Icon name={pane.menuItem.icon as SemanticICONS} />
              <div style={{ marginLeft: 3 }}>{pane.menuItem.content}</div>
            </div>
            {pane.children && <Icon
              style={{ cursor: 'pointer' }}
              onClick={() => setIfeVisible(!ifeVisible)}
              name={`chevron ${itemMenuIsOpen ? 'down' : 'right'}` as SemanticICONS} />}
          </div>
          {pane.children && <Transition visible={itemMenuIsOpen} animation='slide down' duration={300}>
            <Menu.Menu>
              {pane.children.map((child, index) => {
                return <Menu.Item
                  active={tabIsActive(child.link, index)}
                  as={Link}
                  to={child.link}
                  key={child.key}
                  name={child.content}
                >
                  <div className='itemWrapper'>
                    <div className='titleWrapper'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon className={child.icon} />
                        <span style={{ marginLeft: 3 }}>{child.content}</span>
                      </div>
                    </div>
                  </div>
                </Menu.Item>
              })}
            </Menu.Menu>
          </Transition>
          }
        </Menu.Item>
      })}
    </StyledMenu>
  }

  return <Layout
    title={t('View Dairy: {{companyName}}', { companyName: dairyViewData?.companyName || '' })}
    isBusy={isBusy}
    segmented
    close={RouteTypes.DAIRY_FACTORY}
  >
    <Grid>
      <Grid.Column width={3}>
        {renderTab()}
      </Grid.Column>
      <Grid.Column width={13} style={{ marginLeft: 'inherit' }}>
        {!isBusy && <DairyViewRouter />}
      </Grid.Column>
    </Grid>
  </Layout>
}

export default DairyFactoryView
