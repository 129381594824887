import { Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledSegment = styled(Segment)`
background: white !important;
-webkit-box-shadow: none !important;
background-color: rgb(241 245 249) !important;
border: none !important;
margin-bottom: 2em !important;
border-radius: 1.3rem !important;
min-height: auto !important;
box-shadow: none !important;
padding-bottom: 15px !important;
 .column {
  -webkit-box-shadow: none !important;  
   box-shadow: none !important;
 }
 .header {
  font-size: 18px !important;
 }
 .row {
  flew-wrap: no-wrap !important;
 }
`
export const StyledGrid = styled(Grid)`
 padding: 15px !important;
 .column-value {
  font-weight: bold !important;
 }
`
export const CustomRow = styled(Grid.Row)`
flex-wrap: nowrap !important;
word-break: break-word;
margin-right: 0 !important;
`
export const StyledGridColumns = styled(Grid.Row)`
font-weight: bold !important;
 .disabled{
  opacity: 1 !important;
 }
margin-right: 10px !important;
`
export const StyledGridRow = styled(Grid.Row)`
padding-bottom: 5px !important;
align-items: baseline !important;
flex-wrap: no-wrap !important;
`