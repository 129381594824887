import api from './api'

export const login = (data: UserDataType) =>
  api.post<UserTypeReturn>('/login', data)

export const getUser = () =>
  api.post<UserType>('/profile')

export const forgotPassword = (data: { email: string }) =>
  api.post('/forgot-password', data)

export const getSystemData = () =>
  api.post<SystemDataType>('/get-system-data')

export const refreshToken = () =>
  api.post('/refresh-token')
interface UserTypeReturn {
  expire: string,
  token: string,
  message: string
}

interface UserDataType {
  email: string,
  password: string
}

export type LanguageType = {
  id: number,
  code: string,
  label: string
  order?: number
}

type LoggedType = {
  id: string
  language: LanguageType
  languageId: number
  name: string
}

export interface UserType {
  message: string,
  data: {
    user: {
      first_name: string,
      last_name: string,
      email: string,
      verified: number,
      profile: any
    }
  }
}
export interface SystemDataType {
  dateDelimiter: string
  dateFormat: string
  decimalSeparator: string
  decimals: number
  defaultRowsPerPage: number
  languages: LanguageType[]
  logged: LoggedType
  message: string
  thousandSeparator: string
  timezone: string
}
