import React, {Fragment, useEffect, useState} from 'react'
import {Button, Form, Dimmer, Sidebar, Icon} from 'semantic-ui-react'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {FormFields} from '~/components/FormFields'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {UpdateLogsApi} from '~/services'
import notify from '~/components/notify'
import WYSIWYGEditor from '~/components/WYSWYGEditor'
import {DrawerContainer, DrawerHeader, DrawerTitle, DrawerAction, DrawerActionClose, DrawerFooter, StyledInput} from './style'
import Box from '~/components/Box'
import {UpdateLogType} from '~/services/updateLogs'
import {PageHeaderActionButton} from '~/components/Layout/style'
import LoaderMilkCan from '~/components/Loader'
import {dateRegex} from '~/Constants'
interface CreateEditUpdateLogsPropsType {
  isVisible: boolean,
  id?: string,
  handleHiddenDrawer: () => void,
  dairiesOptions: Array<{companyName: string, id: string}>
}

const CreateUpdateLogs = (props: CreateEditUpdateLogsPropsType) => {
  const {id, isVisible, handleHiddenDrawer} = props
  const [isBusy, setIsBusy] = useState(false)
  const {t} = useTranslation()
  const [updateLogsData, setUpdateLogsData] = useState<UpdateLogType>(null)
  const {handleSubmit, control, setValue, watch, reset} = useForm({
    mode: 'onSubmit'
  })
  const watchDateField = watch('date')

  useEffect(() => {
    if (id && props.isVisible) {
      fetchUpdateLogData()
    }
    else if (!id && props.isVisible) {
      setUpdateLogsData({
        description: '',
        date: '',
        version: ''
      })
      reset({})
    }
  }, [props.id, props.isVisible])

  async function fetchUpdateLogData() {
    setIsBusy(true)
    try {
      const response = await UpdateLogsApi.getUpdateLog(id)
      response.data.date = dayjs(response.data.date).format('DD.MM.YYYY')
      reset(response.data)
      setUpdateLogsData(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function onSubmit(data: UpdateLogType) {
    data.date = dayjs(getDate(data.date)).format('YYYY-MM-DD')
    setIsBusy(true)
    try {
      if (!id) {
        await UpdateLogsApi.createUpdateLog(data)
      } else {
        await UpdateLogsApi.updateLog(id, data)
      }
      handleHiddenDrawer()
      notify(t('Successfully created/updated update-log'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }
   
  function getDate(date) {
    const objDate = date.split('.')
    return new Date(objDate[2], objDate[1] - 1, objDate[0])
  }

  function renderUpdateLogForm() {
    return updateLogsData ? <>
      <FormFields.Input
        fluid
        name={'version'}
        placeholder={t('e.g v5.6.7')}
        control={control}
        label={t('Version')}
        rules={{
          required: {
            value: true,
            message: t('Please enter version')
          }
        }}
      />
      <FormFields.External
        name={'date'}
        label={t('Date')}
        defaultValue={watchDateField}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('Please enter date')
          },
          pattern: {
            value: dateRegex,
            message: t('Please enter valid date')
          }
        }}
      >
        <StyledInput
          action={<DatePicker
            tabIndex={-1}
            locale='en'
            dateFormat='DD.MM.YYYY'
            openToDate={watchDateField ? getDate(watchDateField) : undefined}
            customInput={<Button as='a' tabIndex={-1} icon='calendar' />}
            onChange={date => setValue('date', dayjs(date).format('DD.MM.YYYY'))} />}
          placeholder='DD.MM.YYYY'
          value={watchDateField || ''}
          onChange={e => setValue('date', e.target.value)} />
      </FormFields.External>
      <FormFields.External
        onChange={(value: string) => setValue('description', value)}
        defaultValue={updateLogsData.description}
        name={'description'}
        label={t('Description')}
        control={control}
        tag='textarea'
      >
        <WYSIWYGEditor />
      </FormFields.External>
      <FormFields.Select
        control={control}
        label={t('Dairies')}
        multiple
        placeholder={t('Select dairies')}
        name='dairies'
        options={props.dairiesOptions.map(dairy => ({value: dairy.id, text: dairy.companyName}))}
      />
      <FormFields.Checkbox
        name='visible'
        label={t('Visible')}
        control={control}
      />
      <DrawerFooter>
        <PageHeaderActionButton>{t('Save')}</PageHeaderActionButton>
      </DrawerFooter>
    </> : null
  }

  function renderHeader() {
    return <DrawerHeader>
      <DrawerTitle>
        {id ? t('Edit update-log') : t('Create update log')}
      </DrawerTitle>
      <DrawerAction>
        <DrawerActionClose compact icon size='large' type='button' onClick={() => handleHiddenDrawer()}>
          <Icon size='large' name='times circle outline'></Icon>
        </DrawerActionClose>
      </DrawerAction>
    </DrawerHeader>
  }

  return <DrawerContainer>
    <Sidebar
      className='drawer'
      as={Form}
      animation='push'
      direction='right'
      onHide={() => {
        setUpdateLogsData(null)
        reset()
        handleHiddenDrawer()
      }}
      visible={isVisible}
      onSubmit={handleSubmit(onSubmit)}
      width='very wide'
    >
      <Dimmer active={isBusy} inverted>
        <LoaderMilkCan isLoading={isBusy} />
      </Dimmer>
      <Box header={renderHeader()}
        fluid content={renderUpdateLogForm()}></Box>
    </Sidebar>
  </DrawerContainer>
}
export default CreateUpdateLogs
