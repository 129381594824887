import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Icon } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { SystemDataState } from '~/reducers'
import { LanguageType, SystemDataType } from '~/services/login'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ErrorLabel } from '../style'
import { useParams } from 'react-router-dom'

type GeneralInfoProps = {
  languages: LanguageType[]
  instanceTypes: string[]
}

const GeneralInformation = (props: GeneralInfoProps) => {
  const systemData = useSelector<SystemDataState, SystemDataType>(state => state.systemData)
  const { control, watch, setValue, formState: { isSubmitted }, getValues } = useFormContext()
  const { t } = useTranslation()
  const { id } = useParams()
  const watchlanguageId = useWatch({ control: control, name: 'languageId' })
  const watchSms = useWatch({ control: control, name: 'smsActive' })
  const watchLanguagesField = watch('languages')

  useEffect(() => {
    const isTestEnv = getValues('isTest')
    if (isTestEnv) {
      //check if we removed or added new language and apply it to the texts field
      const languages = systemData.languages.filter(lang => !!watchLanguagesField.find(langCode => langCode === lang.code))
      const textsLangs = getValues('texts[0].languages')
      // in case we removed language
      if (textsLangs.length > watchLanguagesField.length) {
        const textsLangsFiltered = textsLangs.filter(textsLangs => !!languages.find(langCode => langCode.code === textsLangs.id))
        setValue('texts[0].languages', textsLangsFiltered)

      }
      // in case we added language
      else {
        languages.map(language => {
          const index = textsLangs.findIndex(lang => lang.id === language.code)
          if (index < 0) {
            textsLangs.push({
              id: language.code,
              translation: ''
            })
          }
        })
        setValue('texts[0].languages', textsLangs)
      }
    }

  }, [watchLanguagesField])

  function reorder(listArray, startIndex, endIndex) {
    const result = listArray
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }
    const listReordered = reorder(
      [...watchLanguagesField],
      result.source.index,
      result.destination.index
    )
    setValue('languages', listReordered)
  }

  function renderSelectedLanguagesForReorder() {
    return <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {watchLanguagesField.map((languageId, index) => (
              <Draggable key={languageId} draggableId={languageId} index={index}>
                {(provided, snapshot) => {
                  const language = systemData?.languages.find(language => language.code === languageId)
                  if (snapshot.isDragging) {
                    provided.draggableProps.style.top = undefined
                  }
                  return <div>
                    <div style={{ margin: '10px 0px', display: 'flex', alignItems: 'baseline' }}>
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Icon style={{ color: 'lightgrey' }} name='bars' />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span>{`${index + 1}.`}</span>
                        <FormFields.Checkbox
                          label={language.label}
                          control={control}
                          style={{ marginLeft: 5 }}
                          name='languageId'
                          checkboxValue={language.code}
                          key={language.code}
                          id={language.code}
                        />
                      </div>
                    </div>
                  </div>
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  }

  function displayErrorMessage() {
    if (isSubmitted) {
      return !watchlanguageId
    }
    return false
  }

  function renderLanguages(): ReactElement {
    return <Form.Field required>
      <label>{t('Select default language:')}</label>
      <>
        {renderSelectedLanguagesForReorder()}
        {displayErrorMessage() && <ErrorLabel pointing={'above'}>{t('Please select default language')}</ErrorLabel>}
      </>
    </Form.Field>
  }

  return <>
    <FormFields.Input
      control={control}
      placeholder={t('Company Name')}
      rules={{
        required: {
          value: true,
          message: t('Please enter company name')
        },
        maxLength: {
          value: 150,
          message: t('Max char is 150')
        }
      }}
      name={'companyName'}
      label={t('Company Name')}
    />
    <FormFields.Input
      placeholder={t('Subdomain')}
      control={control}
      name={'subdomain'}
      rules={{
        required: {
          value: true,
          message: t('Please enter subdomain')
        },
        maxLength: {
          value: 50,
          message: t('Max char is 50')
        }
      }}
      label={t('Subdomain')}
    />
    <FormFields.Select
      placeholder={t('Language')}
      control={control}
      multiple
      options={props.languages.map(language => ({ value: language.code, text: language.label }))}
      name={'languages'}
      defaultValue={[]}
      rules={{
        required: {
          value: true,
          message: t('Please select language')
        }
      }}
      label={t('Language')}
    />
    {watchLanguagesField && watchLanguagesField.length > 0 && renderLanguages()}
    <FormFields.Input
      placeholder={t('Header title')}
      control={control}
      name={'headerTitle'}
      rules={{
        required: {
          value: true,
          message: t('Please enter header title')
        },
        maxLength: {
          value: 150,
          message: t('Max char is 150')
        }
      }}
      label={t('Header title')}
    />
    <FormFields.Checkbox
      control={control}
      style={{ marginTop: 15 }}
      name='smsActive'
      label={t('SMS 2FA Active?')}
      defaultValue={0}
      checkboxValue={1}
    />
    {Boolean(watchSms) && <FormFields.Input
      control={control}
      placeholder={t('Email Header')}
      name={'smsSenderName'}
      label={t('SMS Sender name')}
      rules={{
        required: {
          value: true,
          message: t('Please enter SMS Sender name')
        },
        maxLength: {
          value: 11,
          message: t('Max character number is 11')
        },
        minLength: {
          value: 3,
          message: t('Min character number is 3')
        }
      }}
    />
    }
    <FormFields.Checkbox
      control={control}
      name='farmersImport'
      label={t('Is farmer import available?')}
      defaultValue={0}
      checkboxValue={1}
    />
    <FormFields.Checkbox
      control={control}
      name='mgaBilling'
      label={t('Is MGA attachment available?')}
      defaultValue={0}
      checkboxValue={1}
    />
    <FormFields.Checkbox
      control={control}
      name='stonexEnabled'
      label={t('Is StoneX enabled?')}
      defaultValue={0}
      checkboxValue={1}
    />
    <FormFields.Checkbox
      control={control}
      name='farmerMobileAppConnectionAvailable'
      label={t('Is farmer mobile app connection available?')}
      defaultValue={0}
      checkboxValue={1}
    />
    {!id && <FormFields.Select
      placeholder={t('Instance types')}
      control={control}
      defaultValue={props.instanceTypes[0]}
      options={props.instanceTypes.map(instanceType => ({ value: instanceType, text: instanceType }))}
      name={'instanceType'}
      selectOnBlur={false}
      clearable
      label={t('Instance types')}
    />
    }
  </>
}
export default GeneralInformation
