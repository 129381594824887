import {Button, Form, Input} from 'semantic-ui-react'
import styled from 'styled-components'

export const DrawerContainer = styled.div`
  .drawer {
    background: #FFFFFF !important;
    border-top-right-radius: 1rem !important;
    width: 30% !important;
    padding: 0 1rem !important;
  }
`
export const DrawerHeader = styled.div`
  align-items: center;
  display: flex;
`
export const DrawerTitle = styled.div`
  margin-right: auto;
  font-size: 1.25rem;
  font-weight: bold;
`
export const DrawerAction = styled.div`
  margin-left: 0.75rem;
  position: relative;
`
export const DrawerActionClose = styled(Button)`
  background: transparent !important;
  padding: 0 !important;
  color: #1e293b !important;
`
export const DrawerFooter = styled(Form.Field)`
  margin-top: 3rem !important;
`

export const StyledInput = styled(Input)`
 .react-datepicker-popper {
   z-index: 100;
 }
`
