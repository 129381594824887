import React, { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Accordion, Button, Form, Icon, Popup, Transition } from 'semantic-ui-react'
import { FormFields } from '~/components/FormFields'
import { emailRegex } from '~/Constants'
import InfoPopup from '~/components/InfoPopup'
import { StyledAccordion, StyledLoader } from './style'
import { CheckConnectionFarmerGroupSettings } from '~/services/dairies'
import { useParams } from 'react-router-dom'
interface IFTPCardProps {
  ftp: any
  index: number
  handleRecipientsHeaderClick(e: any, titleProps: any): void
  active: boolean
  control: any
  options: any
  getValues: any
  setValue: any
  watchScheduledActiveField: boolean
}

const SettingsCard: React.FC<IFTPCardProps> = (props) => {
  const { t } = useTranslation()
  const { idDairy } = useParams()
  const [loadingConnection, setLoadingConnection] = useState(false)
  const [connection, setConnection] = useState(false)
  const {
    ftp,
    index,
    handleRecipientsHeaderClick,
    active,
    control,
    options,
    getValues,
    setValue,
    watchScheduledActiveField
  } = props

  const notificationEmails = useFieldArray({
    control,
    //@ts-ignore
    name: `ftps[${index}].notificationEmails`
  });

  //@ts-ignore
  const watchNotificatonStatus = useWatch({ control, name: `ftps[${index}].notificationStatus` })

  useEffect(() => {
    const testedConnection = getValues(`ftps[${index}].connection`)
    if (ftp && testedConnection === undefined) {
      checkConnection()
    }
  }, [ftp, active])

  async function checkConnection() {
    const {
      protocol,
      ftpIp,
      ftpPort,
      ftpUser,
      ftpPassword
    } = ftp

    setLoadingConnection(true)
    try {
      const response = await CheckConnectionFarmerGroupSettings(idDairy, {
        protocol,
        ftpIp,
        ftpPort,
        ftpUser,
        ftpPassword
      })
      setValue(`ftps[${index}].connection`, Boolean(response.data))
      setConnection(Boolean(response.data))
    } catch (error) {
      /*  if (error.response.data) {
         notify(error.response.data.message, 'error')
       } else {
         notify(t('General error'), 'error')
       } */
    }
    setLoadingConnection(false)
  }

  function renderFtpContent(ftp, index) {
    let ftpsName: string
    ftpsName = `ftps[${index}]`
    return <>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Checkbox
          //@ts-ignore
          name={`${ftpsName}.schedulerActive`}
          control={control}
          defaultValue={0}
          style={{ marginTop: '1em' }}
          checkboxValue={1}
          label={ftp.type === 'export' ? t('Scheduled exporting') : t('Scheduled importing')}
        />
        <FormFields.Select
          control={control}
          options={options?.days?.map(option => ({ value: option.key.toString(), text: option.label })) || []}
          selection
          placeholder={t('Select days')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please select days')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.schedulerDays`}
          multiple
          label={<div style={{ display: 'flex', marginBottom: '0.5rem', color: '#5e6672' }}>
            <label>{ftp?.type === 'export' ? t('Export days') : t('Importing days')}</label>
            <InfoPopup
              content={t('On which days should data be imported/exported? (Multiple selection possible)')}
              styleIcon={{ marginLeft: 3, cursor: 'pointer' }}
            />
          </div>}
          selectOnBlur={false}
        />
        <FormFields.Input
          //@ts-ignore
          name={`${ftpsName}.schedulerTime`}
          placeholder={ftp?.type === 'export' ? t('Export time') : t('Importing time')}
          control={control}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter time')
            }
          } : null}
          label={<div style={{ display: 'flex', marginBottom: '0.5rem', color: '#5e6672' }}>
            <label>{ftp?.type === 'export' ? t('Export time') : t('Importing time')}</label>
            <InfoPopup
              content={t('At what time should the data be imported/exported? (If you have selected multiple days, the selected time applies to all days).')}
              styleIcon={{ marginLeft: 3, cursor: 'pointer' }}
            />
          </div>}
        />
      </Form.Group>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Select
          control={control}
          options={options?.protocols.map((protocol, index) => ({ value: index, text: protocol }))}
          selection
          placeholder={t('Select FTPS')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please select protocol')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.protocol`}
          label={t('FTPS protocol')}
          selectOnBlur={false}
        />
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.ftpIp`}
          label={t('IP address')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter ip adress')
            }
          } : null}
          placeholder={t('Enter ip adress')}
        />
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.ftpPort`}
          label={t('Port')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter port')
            }
          } : null}
          placeholder={t('Enter port')}
        />
      </Form.Group>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.ftpUser`}
          autoComplete='off'
          label={t('User')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter user')
            }
          } : undefined}
          placeholder={t('Enter user')}
        />
        <FormFields.Input
          control={control}
          type={'password'}
          autoComplete='off'
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter password')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.ftpPassword`}
          label={t('Password')}
          placeholder={t('Enter password')}
        />
      </Form.Group>
      <Form.Group style={{ alignItems: 'center' }} widths='equal'>
        <FormFields.Input
          control={control}
          //@ts-ignore
          name={`${ftpsName}.folder`}
          label={ftp?.type === 'export' ? t('Farmers export folder') : t('Farmers import folder')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please enter folder name')
            }
          } : null}
          placeholder={ftp?.type === 'export' ? t('Enter export folder') : t('Enter import folder')}
        />
        {ftp.type === 'export' && <FormFields.Select
          control={control}
          options={options?.formats?.map((format ) => ({ value: format.key, text: format.label }))}
          selection
          placeholder={t('Select format')}
          rules={watchScheduledActiveField ? {
            required: {
              value: true,
              message: t('Please select format')
            }
          } : null}
          //@ts-ignore
          name={`${ftpsName}.exportFormat`}
          label={t('FTPS format')}
          selectOnBlur={false}
        />
        }
      </Form.Group>
      <FormFields.Checkbox
        control={control}
        //@ts-ignore
        name={`${ftpsName}.notificationStatus`}
        label={t('Notification status')}
        defaultValue={0}
        checkboxValue={1}
      />
      <Form.Field
        required={Boolean(watchNotificatonStatus)}
        style={{ display: 'flex', alignItems: 'baseline' }}
      >
        <label style={{ marginRight: 10 }}>{t('Notification emails')}</label>
        <Popup
          content={t('Add new notification email')}
          trigger={
            <Button
              onClick={() => notificationEmails.append({ email: '' })}
              primary
              size='mini'
              type={'button'}
              icon='plus'
            />
          }
        />
      </Form.Field>
      <>
        {notificationEmails.fields.map((notificationEmail, notificationEmailIndex) => {
          return <Form.Group key={notificationEmail.id}>
            <FormFields.Input
              control={control}
              width={12}
              placeholder={t('Email')}
              label={t('New email')}
              autoComplete='off'
              //@ts-ignore
              name={`${ftpsName}.notificationEmails[${notificationEmailIndex}].email`}
              rules={{
                required: {
                  value: true,
                  message: t('Please enter email')
                },
                pattern: {
                  value: emailRegex,
                  message: t('Please enter valid email')
                }
              }}
            />
            <Form.Button
              style={{ marginLeft: 10, marginTop: 24 }}
              onClick={() => notificationEmails.remove(notificationEmailIndex)}
              size={'small'}
              basic
              color='red'
              type={'button'}
              icon='trash'
            />
          </Form.Group>
        })}
      </>
    </>
  }

  return <StyledAccordion styled key={ftp.id}>
    <Accordion.Title
      index={index}
      active={active}
      onClick={handleRecipientsHeaderClick}
    >
      <div style={{ position: 'relative', display: 'flex', alignItems: 'baseline' }}>
        {!loadingConnection ? <Icon
          name={connection ? 'check circle outline' : 'times circle outline'}
          color={connection ? 'green' : 'red'}
        /> : <StyledLoader active={loadingConnection} />}
        {ftp.label}</div>
      <Icon name={active ? 'minus' : 'plus'} />
    </Accordion.Title>
    <Transition visible={active} animation='slide down' duration={200}>
      <Accordion.Content
        active={active}
        content={renderFtpContent(ftp, index)}
      />
    </Transition>
  </StyledAccordion>
}
export default SettingsCard