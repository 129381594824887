import api from './api'
import { UserType } from './login'

export const getProfileData = () =>
  api.post<UserType>('/profile')

export const editProfileData = () =>
  api.post<ProfileDataType>('/profile/edit')

export const updateProfileData = (data: ProfileDataType) =>
  api.post('/profile/update', data)

export const languageUpdate = (languageId: number) =>
  api.post('/profile/language-update', { language_id: languageId })

export const getFileProfile = () =>
  api.post('/profile/get-file')

type Nullable<T> = T | null
export interface ProfileDataType {
  email: string
  firstName: string
  id: string
  lastName: string
  mobileNumber: Nullable<string>
  mobilePrefix: Nullable<string>
  phoneNumber: Nullable<string>
  phonePrefix: Nullable<string>
  photo: Nullable<string>
  roleId: number
}

