import React, { useEffect, useState } from 'react'
import Layout from '~/components/Layout'
import { useTranslation } from 'react-i18next'
import { DairyApi, UpdateLogsApi } from '~/services'
import { RouteTypes, dairyManagingStatuses, optionsLimit, dairyUpdateStatuses } from '~/Constants'
import notify from '~/components/notify'
import { Button, Card, Grid, Icon, Input, Loader, Pagination, Dropdown, Label, Segment, Header } from 'semantic-ui-react'
import {
  DairyInfo,
  CardWithBottomMargin,
  LoaderWrapper,
  ManagingStatusIconWrapper,
  StyledNoResultsMessage,
  UpdateVersionLoaderWrapper
} from './style'
import { Link } from 'react-router-dom'
import Dialog from '~/components/Dialog'
import { useDebounce } from 'use-lodash-debounce'
import { PageHeaderActionButton, PageHeaderActions } from '~/components/Layout/style'
import { TableFooterWrapper } from './View/style'
import { DairyRows, DairiesUpdateVersionType } from '~/services/dairies'
import UpdateVersionDialog from './UpdateVersionDialog'
import dayjs from 'dayjs'
import LazyLoadImage from '~/components/LazyLoad'

const loaderDairyStatuses = [dairyManagingStatuses.creating, dairyManagingStatuses.editing, dairyManagingStatuses.deleting];

const DairyFactory: React.FC = () => {
  const { t } = useTranslation()
  const [dairies, setDairies] = useState<DairyRows[]>([])
  const [version, setVersion] = useState(null)
  const [isBusy, setIsBusy] = useState(false)
  const [blockDairy, setBlockDairy] = useState(null)
  const [deleteDairy, setDeleteDairy] = useState(null)
  const [search, setSearch] = useState('')
  const debouncedValue = useDebounce(search, 300)
  const [updateModal, setUpdateModal] = useState(false)

  const [filtered, setFiltered] = useState(0)
  const [total, setTotal] = useState(0)
  const [dataFiltered, setDataFiltered] = useState({
    page: 0,
    rows: 30,
    search: ''
  })

  useEffect(() => {
    fetchDairies()
    fetchVersion()
  }, [])

  useEffect(() => {
    if (dataFiltered && total !== 0) {
      fetchDairies()
    } else {
      setDairies([])
    }
  }, [dataFiltered])

  useEffect(() => {
    setDataFiltered({ ...dataFiltered, search: debouncedValue, page: 0 })
  }, [debouncedValue])

  async function fetchDairies() {
    setIsBusy(true)
    try {
      const dairiesResponse = await DairyApi.getDairies(dataFiltered)
      setDairies(dairiesResponse.data.rows)
      setFiltered(dairiesResponse.data.filtered)
      setTotal(dairiesResponse.data.total)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function fetchVersion() {
    setIsBusy(true)
    try {
      const versionResponse = await UpdateLogsApi.getNewestUpdateLog()
      setVersion(versionResponse.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderDairyReadyActionButtons(dairy) {
    const statusLabel = dairy.status ? 'Block' : 'Unblock'
    const buttonColor = dairy.status ? 'red' : 'green'
    return <>
      <Button
        as={Link}
        to={RouteTypes.DAIRY_FACTORY_EDIT.replace(':id', dairy.id)}
        primary>
        {t('Edit')}
      </Button>
      <Button
        as={Link}
        to={RouteTypes.DAIRY_FACTORY_VIEW.replace(':id', dairy.id)}
        primary>
        {t('View')}
      </Button>
      <Button color={buttonColor} onClick={() => setBlockDairy(dairy)}>
        {t(statusLabel)}
      </Button>
    </>
  }

  function renderManagingStatusContent(dairy) {
    if (loaderDairyStatuses.includes(dairy.managingStatus)) {
      return <LoaderWrapper>
        <Loader active size='tiny'>
          <p className='loader-text'>{dairy.managingStatusLabel}</p>
        </Loader>
      </LoaderWrapper>
    } else if (dairy.managingStatus == 1) {
      return <ManagingStatusIconWrapper>
        <Icon name='check circle' size='large' color='green'></Icon>{dairy.managingStatusLabel}
      </ManagingStatusIconWrapper>
    } else {
      return <ManagingStatusIconWrapper>
        <Icon name='warning sign' size='large' color='red'></Icon>{dairy.managingStatusLabel}
      </ManagingStatusIconWrapper>
    }
  }

  function renderBlockModal() {
    const action = blockDairy.status ? t('Block') : t('Unblock')
    const name = blockDairy?.companyName
    return <Dialog
      isDialogOpen={Boolean(blockDairy)}
      textClose={t('Close')}
      handleClose={() => setBlockDairy(null)}
      textSubmit={action}
      handleSubmit={() => handleBlockDairy(blockDairy)}
      textContent={t('Do you want to {{action}} dairy {{name}}?', { action, name })}
      textHeader={t('{{action}} {{name}}', { action, name })}
      type={'abort'}
    />
  }

  async function handleBlockDairy(dairy) {
    setIsBusy(true)
    const action = dairy.status ? 'blocked' : 'unblocked'
    try {
      dairy.status ? await DairyApi.BlockDairy(dairy.id) : await DairyApi.UnblockDairy(dairy.id)
      setBlockDairy(null)
      fetchDairies()
      notify(t('Dairy successfully {{ action }}', { action }), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderDeleteModal() {
    const name = deleteDairy?.companyName
    return <Dialog
      isDialogOpen={Boolean(deleteDairy)}
      textClose={t('Close')}
      handleClose={() => setDeleteDairy(null)}
      textSubmit={t('Delete')}
      handleSubmit={() => handleDeleteDairy(deleteDairy)}
      textContent={t('Do you want to delete dairy {{name}}?', { name })}
      textHeader={t('Delete {{name}}', { name })}
      type={'abort'}
    />
  }

  async function handleDeleteDairy(dairy) {
    setIsBusy(true)
    try {
      await DairyApi.DeleteDairy(dairy.id)
      setDeleteDairy(null)
      fetchDairies()
      notify(t('Deleting of dairy started'), 'success')
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  async function handleDairiesUpdateVersion(data: DairiesUpdateVersionType) {
    setIsBusy(true)
    try {
      await DairyApi.updateDairiesVersion(data)
      fetchDairies()
      notify(t('Version update of dairies started'), 'success')
      setUpdateModal(false)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }

    setIsBusy(false)
  }

  function closeUpdateDialog() {
    setUpdateModal(false)
  }

  function renderUpdateModal() {
    return <UpdateVersionDialog
      updateModal={updateModal}
      handleClose={closeUpdateDialog}
      handleSubmit={handleDairiesUpdateVersion}
    />
  }

  function renderSearch() {
    return <Input
      icon={{ name: 'search', circular: true, link: true }}
      placeholder={t('Search...')}
      value={search}
      onChange={(e, data) => {
        setSearch(data.value)
      }}
    />
  }

  function renderPagination() {
    return <div>
      <Pagination
        onPageChange={(_, data) => setDataFiltered({ ...dataFiltered, page: parseInt(data.activePage.toString()) - 1 })}
        activePage={dataFiltered.page === 0 ? 1 : dataFiltered.page + 1}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
        totalPages={Math.ceil(filtered / dataFiltered.rows)} />
    </div>
  }

  function renderTableFooter() {
    return <div>
      {filtered > 0 && <TableFooterWrapper><div><Dropdown
        basic
        button
        upward={true}
        size='small'
        value={dataFiltered.rows}
        onChange={(e, data) => {
          setDataFiltered({
            ...dataFiltered,
            rows: data.value as number
          })
        }}
        options={optionsLimit}
      />
      <Label basic pointing={'left'}>
        {t('Total: ')}
        <Label.Detail>{filtered}</Label.Detail>
      </Label>
      </div>
      {renderPagination()}
      </TableFooterWrapper>
      }
    </div>
  }

  function renderNoResultsMessage() {
    return <StyledNoResultsMessage>
      <Segment placeholder>
        <Header icon>
          <Icon name='search' />
          {t('No results found....')}
        </Header>
        <Segment.Inline>
        </Segment.Inline>
      </Segment>
    </StyledNoResultsMessage>
  }

  function renderDairies() {
    return <Grid columns={2}>
      <Grid.Row stretched>
        {dairies.map(dairy => {
          return <Grid.Column key={dairy.id}>
            <CardWithBottomMargin className='dairy-row' fluid id={dairy?.id}>
              <Card.Content>
                <LazyLoadImage
                  id={dairy.id}
                />
                <Card.Header>{dairy.companyName}</Card.Header>
                <Card.Meta>{dairy.subdomain}</Card.Meta>
                <DairyInfo>
                  <div className='dairy-info'>
                    <div className='dairy-info_data'>
                      <Icon name='linkify'></Icon> <a href={dairy.url} target='_blank'>{dairy.url}</a>
                    </div>
                    <div className='dairy-info_data'>
                      <Icon name='map marker alternate'></Icon> {dairy?.streetName || ''} {dairy?.streetNumber || ''}, {dairy.city} {dairy.zip}, {dairy.countryLabel}
                    </div>
                    <div className='dairy-info_data'>
                      <Icon name='mail'></Icon> {dairy.officeEmail ? <a href={`mailto:${dairy.officeEmail}`}>{dairy.officeEmail}</a> : t('Not defined')}
                    </div>
                    <div className='dairy-info_data'>
                      <Icon name='phone square'></Icon> <a href={`tel:${dairy.phonePrefixCountryPhoneCode} ${dairy?.phoneNumber || ''}`}>{dairy.phonePrefixCountryPhoneCode} {dairy?.phoneNumber || ''}</a>
                    </div>
                    <UpdateVersionLoaderWrapper>
                      <div className={`dairy-info_data ${version?.id === dairy?.updateLogId ? 'success-color' : 'error-color'}`}>{version?.id === dairy?.updateLogId ? <Icon name='calendar check'></Icon> : <Icon name='delete calendar'></Icon>} {dairy?.updateLogVersion || t('Not defined')} {dairy.updateLogDate ? dayjs(dairy?.updateLogDate).format('DD.MM.YYYY') : ''}</div>
                      {dairy.updateStatus === dairyUpdateStatuses.updating ?
                        <div className='update-info-position'><Loader active size='mini' /></div> : ''}
                      <div className='update-info-position'>
                        {dairy.updateStatus === dairyUpdateStatuses.error ? <div><Icon name='warning sign' size='small' color='red'></Icon>{t(dairy.updateStatusLabel)}</div> : ''}
                      </div>
                    </UpdateVersionLoaderWrapper>
                  </div>
                </DairyInfo>
              </Card.Content>
              <Card.Content extra>
                <div className='ui two' style={{ minHeight: 42 }}>
                  {dairy.managingStatus === dairyManagingStatuses.ready && renderDairyReadyActionButtons(dairy)}
                  {renderManagingStatusContent(dairy)}
                </div>
              </Card.Content>
            </CardWithBottomMargin >
          </Grid.Column >
        })}
      </Grid.Row>
    </Grid>
  }

  function renderActions() {
    return <>
      <PageHeaderActions>
        <PageHeaderActionButton
          size='large'
          type={'button'}
          onClick={() => setUpdateModal(true)}
        >
          {t('Update')}
        </PageHeaderActionButton>
        <PageHeaderActionButton
          size='large'
          type={'button'}
          as={Link}
          to={RouteTypes.DAIRY_FACTORY_CREATE}
        >
          {t('Create')}
        </PageHeaderActionButton>
      </PageHeaderActions>
    </>
  }

  return <Layout
    isBusy={isBusy}
    title={t('Dairy Factories')}
    filters={renderSearch()}
    segmented
    actions={renderActions()}
  >
    {dairies.length > 0 ? renderDairies() : renderNoResultsMessage()}
    {renderTableFooter()}
    {blockDairy && renderBlockModal()}
    {deleteDairy && renderDeleteModal()}
    {updateModal && renderUpdateModal()}
  </Layout >
}

export default DairyFactory
