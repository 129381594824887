import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import DairyViewTabsLayout from '../../../DairyViewTabsLayout'
import i18n from '~/components/i18n'
import { Popup, Table } from 'semantic-ui-react'
import { ReactSortable } from 'react-sortablejs'
import { MilkMoneyApi } from '~/services'
import notify from '~/components/notify'
import { formatNumber } from '~/util'
import CustomIcon from '~/components/CustomIcon'
import { RouteTypes } from '~/Constants'

const tableColumns = [
  {
    name: i18n.t('Name'),
    key: 'label'
  },
  {
    name: i18n.t('Amount'),
    key: 'amount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Min amount'),
    key: 'minAmount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Max amount'),
    key: 'maxAmount',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Min price'),
    key: 'minPrice',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Max price'),
    key: 'maxPrice',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Standard price'),
    key: 'standardPrice',
    type: 'no-unit-int-number'
  },
  {
    name: i18n.t('Actions'),
    type: 'action'
  }
];

const MgaFactors: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [mgaFactors, setMgaFactors] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsBusy(true)
    try {
      const response = await MilkMoneyApi.GetMgaFactors(id)
      setMgaFactors(response.data)
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  function renderTableCell(tableColumn, mgaFactor) {
    return <Table.Cell key={tableColumn.key + mgaFactor.id}>{mgaFactor[tableColumn.key]}</Table.Cell>
  }

  function renderCellBasedOnType(tableColumn, mgaFactor) {
    const value = mgaFactor[tableColumn?.key]
    switch (tableColumn.type) {
      case 'no-unit-int-number':
        return value ? `${formatNumber(parseFloat(value), 0)}` : '0'
      case 'action':
        return <Popup
          content={t('Edit')}
          trigger={<Link
            to={RouteTypes.DAIRY_FACTORY_VIEW_MILK_MONEY_MGA_FACTOR_EDIT.replace(':id', id).replace(':idMga', mgaFactor?.id)}
          >
            <CustomIcon
              size='large'
              name='pencil' />
          </Link>}
        />
      default:
        return value
    }
  }

  function renderSortItems() {
    return mgaFactors.map(mgaFactor => {
      return <Table.Row style={{ cursor: 'grab' }} key={mgaFactor.id}>
        {tableColumns.map(tableColumn => {
          return tableColumn.type ? <Table.Cell key={tableColumn.key + mgaFactor.id}>{renderCellBasedOnType(tableColumn, mgaFactor)}
          </Table.Cell> : renderTableCell(tableColumn, mgaFactor)
        })}
      </Table.Row>
    })
  }

  async function handleReorder() {
    try {
      await MilkMoneyApi.ReorderMgaFactors(id,
        { mgaFactors: mgaFactors.map(mgaFactor => mgaFactor.id) })
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
  }

  function renderTable() {
    return <Table celled fixed singleLine>
      <Table.Header>
        <Table.Row>
          {tableColumns.map(tableColumn => {
            return <Table.HeaderCell key={tableColumn.key}>{tableColumn.name}</Table.HeaderCell>
          })}
        </Table.Row>
      </Table.Header>
      {/*@ts-ignore*/}
      <ReactSortable
        list={mgaFactors}
        tag={'tbody'}
        animation={150}
        onSort={() => handleReorder()}
        delayOnTouchOnly={true}
        delay={2}
        setList={setMgaFactors}
      >
        {renderSortItems()}
      </ReactSortable>
    </Table>
  }

  return <DairyViewTabsLayout
    segmented
    header={t('Mga factors')}
    isBusy={isBusy}
  >
    {renderTable()}
  </DairyViewTabsLayout>
}
export default MgaFactors