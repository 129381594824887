import React, { ChangeEvent, useEffect, useState } from 'react'
import { Container } from './style'

interface ColorPickerProps {
  value?: string,
  label?: string | JSX.Element,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  children?: React.ReactNode
}

const ColorPicker = (props: ColorPickerProps) => {
  const { value, onChange } = props
  const [valueColor, setValueColor] = useState(value)

  useEffect(() => {
    setValueColor(value)
  }, [value])

  return <div>
    <label>{props.label}</label>
    <Container style={{ borderColor: valueColor }}>
      <input type='color' value={valueColor} onChange={e => {
        setValueColor(e.target.value)
        onChange(e)
      }} />
      {props.children}
    </Container>
  </div>

}
export default ColorPicker
