import axios from 'axios'
import ENV from '../environment'
import { ADMIN_TOKEN } from '~/Constants'

axios.defaults.baseURL = ENV.API_URL
axios.defaults.headers = new Headers({
  'content-type': 'application/json'
})
axios.interceptors.request.use(request => {
  const token = localStorage.getItem(ADMIN_TOKEN)
  if (token) {
    request.headers.Authorization = `${token}`
  }
  return request
})

axios.interceptors.response.use(null, error => {
  // handle errors from api with no response(body)
  if (error && (!error.status || !error.response)) {
    error = {
      response: {
        data: {
          message: error.toString()
        }
      }
    }
  }
  return Promise.reject(error)
})

const api = axios
export default api
