import styled from 'styled-components'

export const Container = styled.span<{ borderColor?: string }>`
  display: inline-flex;
  align-items: center;
  width: 250px;
  max-width: 320px;
  padding: 2px 8px;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 4px;
  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    outline: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 30px;
      height: 30px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 50px;
      padding: 0;
    }
  }
  input[type="text"] {
    background: transparent !important;
    outline: none !important;
    border: none !important;
  }
`
