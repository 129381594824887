import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardSettingsWrapper, StyledButton } from '../../../style';
import { Form, Icon } from 'semantic-ui-react';
import { FormFields } from '~/components/FormFields';
import { emailRegex } from '~/Constants';
import i18n from '~/components/i18n';

interface SettingsUsersProps {
  control: any
}

const titleOptions = [
  {
    value: 'ms',
    text: i18n.t('Ms')
  },
  {
    value: 'mr',
    text: i18n.t('Mr')
  }
]

const SettingsUsers: React.FC<SettingsUsersProps> = (props) => {
  const { control } = props
  const { t } = useTranslation()
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'users',
    keyName: 'key'
  })
  console.log(fields)
  function renderSettingsUsers() {
    return fields.map((field, index) => {
      return <CardSettingsWrapper key={field.key}>
        <div className='cardSettings'>
          <>
            <Icon
              onClick={() => remove(index)}
              style={{ cursor: 'pointer', position: 'absolute', right: 3, top: 10 }}
              inverted
              color='red'
              circular
              name='trash'
            />
            <FormFields.Select
              label={t('Title')}
              placeholder={t('Select title')}
              rules={{
                required: {
                  value: true,
                  message: t('Please select title')
                }
              }}
              control={control}
              //@ts-ignore
              name={`users[${index}].title`}
              selectOnBlur={false}
              options={titleOptions}
            />
            <FormFields.Input
              //@ts-ignore
              name={`users[${index}].firstName`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('Please enter first name')
                }
              }}
              label={t('First name')}
              placeholder={t('first name')}
            />
            <FormFields.Input
              //@ts-ignore
              name={`users[${index}].lastName`}
              rules={{
                required: {
                  value: true,
                  message: t('Please enter last name')
                }
              }}
              control={control}
              label={t('Last Name')}
              placeholder={t('last name')}
            />
            <FormFields.Input
              //@ts-ignore
              name={`users[${index}].email`}
              rules={{
                required: {
                  value: true,
                  message: t('Please enter email')
                },
                pattern: {
                  value: emailRegex,
                  message: t('Please enter valid email')
                }
              }}
              label={t('Email')}
              control={control}
              placeholder={t('email')}
            />
          </>
        </div>
      </CardSettingsWrapper>
    })
  }

  function addNewSettingsUser() {
    append({
      title: '',
      firstName: '',
      lastName: '',
      email: ''
    })
  }

  return <div>
    <StyledButton
      type='button'
      onClick={() => addNewSettingsUser()}
    >{t('Add new ife user')}</StyledButton>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {renderSettingsUsers()}
    </div>
  </div>

}
export default SettingsUsers