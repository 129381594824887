import React, { useState } from 'react'
import { Form, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { Control, useWatch } from 'react-hook-form'
import { ModuleDairyType } from '~/services/dairies'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { t } from 'i18next'

const ModulesWrapper = styled.div`
display: flex;
align-items: baseline;
.slug-wrapper {
  margin-right: 150px;
}
`
interface ModulesPropsType {
  modules: ModuleDairyType[],
  control: Control<any>,
  setValue: any,
  usePropertyName?: string
  notRequired?: boolean
}

const Modules = (props: ModulesPropsType) => {
  const { control, setValue, modules, usePropertyName } = props
  const watchModules = useWatch({ control: control, name: 'modules' })
  const moduleSelectProperty = usePropertyName ? usePropertyName : 'slug'
  const [dragging, setDragging] = useState(false)

  function handleModuleCheck(_, data, value) {
    const modules = watchModules ? [...watchModules] : []
    const indexRole = modules.findIndex(module => module[moduleSelectProperty] === value[moduleSelectProperty])
    if (indexRole >= 0 && !data.checked) {
      modules.splice(indexRole, 1)
      setValue('modules', modules)
      return
    }
    setValue('modules', [...modules, value])
  }

  function reorder(listArray, startIndex, endIndex) {
    const result = listArray
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result) {
    setDragging(false)
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }
    const listReordered = reorder(
      [...watchModules],
      result.source.index,
      result.destination.index
    )
    setValue('modules', listReordered)
  }

  function onChildrenDragEnd(result, module) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const newWatchModules = [...watchModules];

    const watchModule = newWatchModules.find(newWatchModule => newWatchModule.slug === module.slug);
    const childrenList = watchModule.children;

    [childrenList[result.source.index], childrenList[result.destination.index]] = [childrenList[result.destination.index], childrenList[result.source.index]]

    watchModule.children = childrenList

    setValue('modules', newWatchModules)
  }

  function onDragStart() {
    if (!dragging) {
      setDragging(true)
    }
  }

  function renderSelectedModulesForReorder() {
    return <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {watchModules.map((module, index) => {
              const moduleObject = modules.find(moduleObject => moduleObject[moduleSelectProperty] === module[moduleSelectProperty])
              return <>
                <Draggable key={moduleObject.id} draggableId={moduleObject.id} index={index}>
                  {(provided, snapshot) => {
                    return <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style, ...(snapshot.isDragging ? {
                          top: undefined
                        } : {})
                      }}
                    >
                      <div style={{ margin: '10px 0px' }}>
                        <Icon style={{ color: 'lightgrey' }} name='bars' />
                        <span>{`${index + 1}. ${moduleObject?.label}`}</span>
                      </div>
                      {dragging && moduleObject.children?.length > 0 && renderSelectedChildrenModules(moduleObject)}
                    </div>
                  }}
                </Draggable>
                {!dragging && moduleObject.children?.length > 0 && renderSelectedChildrenModulesForReorder(moduleObject)}
              </>
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  }

  function renderSelectedChildrenModules(mainModule) {
    return <div>
      {
        mainModule.children.map((children, index) => (
          <div>
            < div style={{ margin: '10px 0px 0px 15px' }
            }>
              <Icon style={{ color: 'lightgrey' }} name='bars' />
              <span>{`${index + 1}. ${children?.label}`}</span>
            </div >
          </div >
        ))
      }
    </div>
  }

  function renderSelectedChildrenModulesForReorder(mainModule) {
    return <DragDropContext onDragEnd={(event) => onChildrenDragEnd(event, mainModule)}>
      <Droppable droppableId={`droppable${mainModule.slug}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {mainModule.children.map((children, index) => (
              <Draggable key={children.id} draggableId={children.id} index={index}>
                {(provided, snapshot) => {
                  return <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style, ...(snapshot.isDragging ? {
                        top: undefined
                      } : {})
                    }}
                  >
                    <div style={{ margin: '10px 0px 0px 15px' }}>
                      <Icon style={{ color: 'lightgrey' }} name='bars' />
                      <span>{`${index + 1}. ${children?.label}`}</span>
                    </div>
                  </div>
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext >
  }

  function renderChildren(module) {
    return module.children?.map(children => {
      return <Form.Checkbox
        label={children.label}
        checked={Boolean(watchModules?.find(watchModule => watchModule[moduleSelectProperty] === module[moduleSelectProperty]))}
        key={children.id}
        style={{ marginLeft: 15 }}
        disabled
      />
    })
  }

  return <ModulesWrapper>
    <Form.Field required={!props.notRequired}>
      <label style={{ marginBottom: '1rem' }}>{t('Modules')}</label>
      <div className='slug-wrapper'>
        {modules.map(module => {
          return <>
            <Form.Checkbox
              label={module.label}
              checked={Boolean(watchModules?.find(watchModule => watchModule[moduleSelectProperty] === module[moduleSelectProperty]))}
              onChange={(e, data) => handleModuleCheck(e, data, module)}
              key={module.id}
            />
            {module.children?.length > 0 && renderChildren(module)}
          </>
        })}
      </div>
    </Form.Field>
    {watchModules && watchModules.length > 0 && <div>
      <label style={{ fontWeight: 'bold' }}>{t('Reorder selected modules: ')}</label>
      {renderSelectedModulesForReorder()}
    </div>}
  </ModulesWrapper>
}
export default Modules
