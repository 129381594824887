import {ActionTypes} from '~/Constants'
import {UserType} from '~/services/login'

const initialState = null

type UserAction = { type: 'USER_DATA_RECEIVED', payload?: UserType }

type Action = UserAction

export const userReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.USER_DATA_RECEIVED: {
      return action.payload
    }
    case ActionTypes.USER_DATA_REMOVED: {
      return initialState
    }
    default: {
      return state
    }
  }
}
