import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dropdown, Input } from 'semantic-ui-react'
import DairyViewTabsLayout from '../../DairyViewTabsLayout'
import { useParams, useHistory } from 'react-router-dom'
import { RouteTypes, farmerFilters } from '~/Constants'
import { PageHeaderActionButton } from '~/components/Layout/style'
import TableComponent from '~/components/TableComponent'
import { useDebounce } from 'use-lodash-debounce'
import { FarmerQueries } from '~/QueryHooks/FarmerQueries'

const defaultFilterValues = {
  page: 0,
  rows: 20,
  search: '',
  farmerGroupId: '',
  yearlyAmountFrom: '',
  yearlyAmountTo: '',
  confirmedAmountFrom: '',
  confirmedAmountTo: '',
  terminationDate: 0,
  lastDeliveryDate: 0,
  hasCancelledAmount: 0
}

const Farmers = () => {
  const { id } = useParams()
  //@to-do replace with dynamic amountUnit
  const amountUnit = 'l'
  const [dataFiltered, setDataFiltered] = useState(defaultFilterValues)
  const [inputValues, setInputValues] = useState({
    yearlyAmountFrom: '',
    yearlyAmountTo: '',
    confirmedAmountFrom: '',
    confirmedAmountTo: '',
    search: ''
  })
  const debouncedInputValue = useDebounce(inputValues, 300)
  const { t } = useTranslation()
  const history = useHistory()

  const actions = [
    {
      popupMessage: t('Edit'),
      type: 'link',
      name: 'pencil',
      size: 'large',
      link: RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_EDIT.replace(':id', id),
      replaceKey: ':idFarmer'
    }
  ]

  const [tableColumnHeaders, setTableColumnHeaders] = useState([
    {
      name: t('Supplier No.'),
      key: 'supplierNo',
      type: 'calendar',
      sorted: 'desc'
    },
    {
      name: t('Company name'),
      key: 'companyName',
      sorted: 'asc'
    },
    {
      name: t('Farmers group'),
      key: 'farmerGroupName'
    },
    {
      name: t('Yearly amount (in {{amountUnit}})', { amountUnit }),
      key: 'amount',
      sorted: 'desc',
      type: 'number'
    },
    {
      name: t('Max of trading amount (in {{amountUnit}})', { amountUnit }),
      key: 'yearlyAmount',
      sorted: 'desc',
      type: 'number'
    },
    {
      name: t('Fixed price amount (in {{amountUnit}}) / [in %]', { amountUnit }),
      key: 'confirmedAmount',
      sorted: 'desc',
      type: 'confirmWithPercent'
    },
    {
      name: t('Remaining amount (in {{amountUnit}}) / [in %]', { amountUnit }),
      key: 'confirmedAmount',
      type: 'remaining-farmer'
    },
    {
      name: t('Actions')
    }
  ])

  const {
    data: farmersData,
    isLoading: isLoadingData,
    isFetching: isFetching,
    refetch: fetchData
  } = FarmerQueries.getFarmers(id, { ...dataFiltered, page: dataFiltered.page === 0 ? dataFiltered.page : dataFiltered.page - 1 })

  const {
    data: farmersOptions,
    isLoading: isLoadingOptions
  } = FarmerQueries.getFarmersOptions(id)

  useEffect(() => {
    fetchData()
  }, [dataFiltered])

  useEffect(() => {
    setDataFiltered({ ...dataFiltered, page: 0, ...debouncedInputValue })
  }, [debouncedInputValue])

  function renderActions() {
    return <>
      <PageHeaderActionButton onClick={() => history.push(RouteTypes.DAIRY_FACTORY_VIEW_FARMERS_CREATE.replace(':id', id))}>{t('Create')}</PageHeaderActionButton>
    </>
  }

  function renderTable() {
    return <TableComponent
      tableColumnHeaders={tableColumnHeaders}
      dataFiltered={dataFiltered}
      isFetchingData={isFetching}
      animated
      handleChangeDataFiltered={(data, tableColumnHeaders) => {
        setDataFiltered(data)
        setTableColumnHeaders(tableColumnHeaders)
      }}
      actions={actions}
      rows={rows}
      total={filtered}
    />
  }

  function renderSelectFilter(farmerFilter) {
    //@ts-ignore
    const options = farmersOptions ? farmersOptions[farmerFilter.optionsKey]?.map(farmerGroup => ({ value: farmerGroup.id, text: farmerGroup.name })) : []
    return <Dropdown
      selectOnBlur={false}
      selection
      placeholder={farmerFilter.placeholder}
      options={options}
      clearable
      value={dataFiltered[farmerFilter.key]}
      onChange={(e, data) => setDataFiltered({ ...dataFiltered, [farmerFilter.key]: data.value })}
    />
  }

  function renderInputFilter(farmerFilter) {
    return <Input
      placeholder={farmerFilter.placeholder}
      value={inputValues[farmerFilter.key]}
      type={'number'}
      onChange={(e, { value }) => {
        console.log(farmerFilter.key, value)
        setInputValues({ ...inputValues, [farmerFilter.key]: value })
      }}
    />
  }

  function renderCheckBoxFilter(farmerFilter) {
    return <Checkbox
      label={farmerFilter.label}
      checked={Boolean(dataFiltered[farmerFilter.key])}
      onChange={(e, data) => setDataFiltered({ ...dataFiltered, [farmerFilter.key]: data.checked ? 1 : 0 })}
    />
  }

  function renderFarmersFilters() {
    return farmerFilters.map(farmerFilter => {
      switch (farmerFilter.type) {
        case 'select':
          return renderSelectFilter(farmerFilter)
        case 'input':
          return renderInputFilter(farmerFilter)
        case 'checkbox':
        default: renderCheckBoxFilter(farmerFilter)
          return
      }
    })
  }

  function renderFilters() {
    return <div style={{ display: 'flex', alignItems: 'baseline', gap: 10, flexWrap: 'wrap' }}>
      <Input
        icon={{ name: 'search', circular: true, link: true }}
        placeholder={t('Search...')}
        value={inputValues.search}
        onChange={(e, data) => setInputValues({ ...inputValues, search: data.value })}
      />
      {renderFarmersFilters()}
    </div>
  }

  const { filtered, rows } = farmersData ? farmersData : { filtered: 0, rows: [] };

  return <DairyViewTabsLayout
    header={t('Farmers')}
    isBusy={isLoadingData || isLoadingOptions}
    segmented
    search={renderFilters()}
    actions={renderActions()}
  >
    {renderTable()}
  </DairyViewTabsLayout>
}
export default Farmers
