import i18n from "./components/i18n";
import notify from "./components/notify";

export function formatNumber(number, decimalsNumber = 2) {
  return Number(number).toLocaleString('de', { minimumFractionDigits: decimalsNumber, maximumFractionDigits: decimalsNumber })
}

export function formatNumberWithoutFractions(number) {
  return Number(number).toLocaleString('de', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export function formatNumberWithThreeDigitPrecision(number) {
  const numberFormated = Number(number).toLocaleString('de', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
  const lastChar = numberFormated.slice(-1)
  if (lastChar === '5') {
    const string = numberFormated.slice(0, -1);
    return string
  }
  return formatNumber(number)
}

export function onError(data): void {
  if (data.response.data) {
    notify(data.response.data.message, 'error');
  } else {
    notify(i18n.t('General error'), 'error');
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function handleErrors(error) {
  console.log(error)
  if (error.response?.data?.errors) {
    error.response?.data?.errors.map((error, index) => {
      if (index < 5) {
        return notify(error, 'error')
      } else {
        return false
      }
    })
  } else {
    notify(i18n.t('General error'), 'error');
  }
}