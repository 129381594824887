import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledGroup = styled(Form.Group)`
 .field {
  margin-bottom: 1em !important;
 }
 .eight {
  padding-left: 0 !important;
 }
 gap: 15px;
 .phone {
  align-items: end;
  margin: 0 -0.5em 0em !important;
 }
`
export const StyledImageContainer = styled.div`
max-width: 150px;
width: auto;
position: absolute;
width: 140px;
z-index: 100;

top: 0;
-webkit-transition: all 0.2s;
left: 0;
right: 0;
margin-right: auto;
height: 140px;
object-fit: cover;
border-radius: 50%;
`

export const InputWrapper = styled.div`
display: flex; 
align-items: center;
justify-content: center;
width: 100%;
 .loaderWrapper {
  position: relative; 
  margin: auto;
  margin-left: 10px; 
  margin-top: 23px; 
  margin-right: 5px;
 }
 .field {
  width: inherit !important;
 }
`
export const StyledField = styled(Form.Field)`
 .field{ 
margin-bottom: 0px !important;
}
`