import {ActionTypes} from '~/Constants'
import {SystemDataType} from '~/services/login'

export const systemDataReducer = (state: SystemDataType = null, action) => {
  switch (action.type) {
    case ActionTypes.SYSTEM_DATA_RECEIVED: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
