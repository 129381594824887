import styled from 'styled-components'
//@ts-ignore
import upload from './upload.png'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export const StyledDropzone = styled.section`
width: 100%;
max-width: 250px;
min-height: 140px;
border-width: 1px;
cursor: pointer;
border-color: rgb(102, 102, 102);
border-style: dashed;
border-radius: 5px;
padding: 10px;
background-color: #fafafa;
position: relative;
&.empty {
  background-image: url(${upload});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80px;
}
&.group-excluded {
  color: rgba(122, 122, 30, .9);
  background-color: rgba(255, 255, 122, .1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80px;
}
> p {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
}
`
