import React, {useEffect} from 'react'
import {useController, UseControllerProps} from 'react-hook-form'
import {Form, TextAreaProps} from 'semantic-ui-react'
import {getLabel, getError} from './util'

interface TextAreaFormFiled extends UseControllerProps {
  label?: string | JSX.Element,
  value?: string,
  control: any
}

type TextareaFormFiledProps = TextAreaFormFiled & TextAreaProps

const TextArea: React.FC<TextareaFormFiledProps> = props => {
  const {field, fieldState} = useController(props)
  const fieldTemp = {...field, ref: null}
  const {control, label, rules, defaultValue, ...textAreaProps} = props

  function notInViewport(element, offset = 0) {
    if (!element) return false
    const top = element.getBoundingClientRect().top
    return (top + offset) >= 0 && (top - offset) >= window.innerHeight
  }

  useEffect(() => {
    if (fieldState.error) {
      const element = document.getElementById(field.name)
      if (notInViewport(element)) {
        element.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [fieldState])

  return <Form.TextArea
    id={fieldTemp.name}
    {...textAreaProps}
    {...fieldTemp}
    value={fieldTemp.value || ''}
    error={fieldState.error ? getError(fieldState) : false}
    label={props.label ? getLabel(label, rules) : null}
  />
}
export default TextArea
