import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFields } from '~/components/FormFields'
import { InputWrapper } from '../../style'
import { useParams } from 'react-router-dom'
import InfinitiveScrollDropdown from '~/components/InfinitiveScrollDropown'

interface GeneralInfoProps {
  farmersList: any[]
  farmersOptions: any[]
}

const GeneralInfo: React.FC<GeneralInfoProps> = (props) => {
  const { farmersList, farmersOptions } = props
  const {
    control,
    setValue,
    clearErrors,
    formState: { dirtyFields },
    getValues
  } = useFormContext()
  const { t } = useTranslation()
  const { id } = useParams()

  /*   const [loadingSupplierCheckNo, setLoadingSupplierCheckNo] = useState(false)
    const [correctSupplierNo, setCorrectSupplierNo] = useState(true)
  
    const uniqueValidationMessage = t('Please enter unique supplier number')
    const watchSupplierNo = useWatch({ control, name: 'supplierNo' }) */
  const watchParentId = useWatch({ control, name: 'parentId' })
  const watchFarmerType = useWatch({ control, name: 'farmerType' })

  /*   useEffect(() => {
      if (correctSupplierNo) {
        clearErrors('supplierNo')
      }
    }, [correctSupplierNo]) */

  useEffect(() => {
    if (watchFarmerType && watchFarmerType === 'main') {
      setValue('parentId', null)
    }
  }, [watchFarmerType])

  /*   async function checkSupplierNo(value) {
      setValue('supplierNo', value)
      setLoadingSupplierCheckNo(true)
      try {
        const payload = id ? { supplierNo: value, id } : { supplierNo: value }
        const response = await FarmersService.checkSupplierNo({ ...payload })
        setCorrectSupplierNo(Boolean(response.data))
      } catch (error) {
        if (error.response.data) {
          notify(error.response.data.message, 'error')
        } else {
          notify(t('General error'), 'error')
        }
      }
      setLoadingSupplierCheckNo(false)
    } */

  return <>
    <FormFields.Input
      control={control}
      name={'companyName'}
      rules={{
        required: {
          value: true,
          message: t('Plese enter company name')
        }
      }}
      placeholder={t('Company name')}
      label={t('Company name')}
    />
    <InputWrapper>
      <FormFields.Input
        control={control}
        name={'supplierNo'}
        placeholder={t('Suplier no.')}
        /*  onChange={(e, data) => checkSupplierNo(data.value)} */
        rules={{
          required: {
            value: true,
            message: t('Please enter supplier number')
          }
        }}
        label={t('Suplier no.')}
      />
      {/*    <div className='loaderWrapper'>
        {loadingSupplierCheckNo && <Dimmer inverted active={loadingSupplierCheckNo}>
          <Loader size='small' active={loadingSupplierCheckNo} />
        </Dimmer>}
      </div>
      {!loadingSupplierCheckNo && watchSupplierNo !== '' && dirtyFields?.supplierNo && <Icon
        style={{ marginLeft: 10 }}
        name={correctSupplierNo ? 'checkmark' : 'close'}
        color={correctSupplierNo ? 'green' : 'red'}
      />} */}
    </InputWrapper>
    <FormFields.Select
      control={control}
      name={'farmerType'}
      label={t('Farmer type')}
      selectOnBlur={false}
      defaultValue={watchParentId ? 'sub' : id ? 'main' : watchFarmerType}
      rules={{
        required: {
          value: true,
          message: t('Please select farmer type')
        }
      }}
      placeholder={t('Select farmer type')}
      //@ts-ignore fix when types are in
      options={farmersOptions?.farmerTypes?.map(farmerType => ({ value: farmerType.key, text: farmerType.label })) || []}
    />
    {(watchFarmerType === 'sub' || !!watchParentId) && <InfinitiveScrollDropdown
      control={control}
      selectOnBlur={false}
      name={'parentId'}
      label={t('Main farmer')}
      value={getValues('parentId')}
      rules={{
        required: {
          value: true,
          message: t('Please select main farmer')
        }
      }}
      setValue={setValue}
      getValues={getValues}
      placeholder={t('Select main farmer')}
      options={farmersList.map(farmer => ({ value: farmer.id, text: farmer.companyName })) || []}
    />}
    <FormFields.Select
      selectOnBlur={false}
      control={control}
      label={t('Milk types')}
      name={'milkTypeId'}
      rules={{
        required: {
          //@ts-ignore
          value: Boolean(farmersOptions?.milkTypes),
          message: t('Please select milk type')
        }
      }}
      clearable
      placeholder={t('Milk types')}
      //@ts-ignore
      options={farmersOptions?.milkTypes?.map(milkType => (
        {
          value: milkType?.id || milkType?.key,
          text: milkType?.name || milkType?.label
        }
      )) || []}
    />
  </>
}
export default GeneralInfo